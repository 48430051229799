import { PagesJson } from '.';
import { MeroPermissionsWrapper } from '../../access/mero-permissions-wrapper';
import { BusinessHours } from '../../business/businessHours';
import { AppointmentId } from '../../calendar';
import { PageGiftCardSettings } from '../../pro/onlinePayments/onlinePaymentsSettings/pageGiftCardSettings';
import {
  BusinessCategory,
  BusinessCategoryId,
  GroupedServices,
  ServiceGroup,
  ServiceGroupId,
  ServiceId,
  SavedService,
  Service,
  GroupedWorkerServices,
  SavedServiceArray,
} from '../../services';
import { WorkerId, SavedWorker, WorkerSearchItem } from '../../workers';
import { Area } from '../area';
import { City } from '../city';
import { MeroPermissions } from '../mero-permissions';
import { BusinessMeta, BusinessMetaSitemapEntry } from '../meta';
import { OnboardingLaunchEta } from '../onboardingLaunchEta';
import { PageAutocompleteResults } from '../page-autocomplete';
import { PageBoostSettings } from '../page-boost-settings';
import { PageClient, PageDetails } from '../page-details';
import { PageId } from '../page-id';
import { PageMemberPreview } from '../page-member-preview';
import { PageNotificationSettings } from '../page-notification-settings';
import { PageProfile } from '../page-profile';
import { PageRoleOverview } from '../page-role-overview';
import { PageRolePreview } from '../page-role-preview';
import { PageSearchWithHasPromoPrice } from '../page-search';
import { PageOnlinePaymentsSettings } from '../pageOnlinePaymentsSettings';
import { PageRoleOverview2 } from '../pageRoleOverview2';
import { PagesApi } from '../pages-api';
import { PublicFeedbackDetails, PublicFeedbackDetailsWithAppointment } from '../publicFeedbackDetails';
import {
  HttpClient,
  Email,
  PhoneNumber,
  Location,
  unsafeRight,
  UnknownApiError,
  UserId,
  ResponsiveImage,
  PageFeedbackScoreCounts,
  HasId,
  FeedbackDetails,
  Lastname,
  Firstname,
} from '@mero/shared-sdk';
import * as t from 'io-ts';

/**
 * PagesApi HTTP client implementation
 */
export const pagesHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): PagesApi => {
  const { apiBaseUrl, http } = env;

  const createPageDecoder = http.decode.response(
    UnknownApiError,
    t.type({
      _id: PageId,
    }),
  );
  const updatePageSimpleDecoder = http.decode.response(UnknownApiError, t.unknown);
  const updatePageAddressDecoder = http.decode.response(UnknownApiError, t.unknown);
  const pageProfileDecoder = http.decode.response(UnknownApiError, PageProfile);
  const pageDetailsDecoder = http.decode.response(UnknownApiError, PageDetails);
  const pagePermissionsDecoder = http.decode.response(UnknownApiError, MeroPermissions);
  const pageOwnAccessDecoder = http.decode.response(
    UnknownApiError,
    t.type({
      permissions: MeroPermissions,
      roles: t.array(PageRolePreview.JSON),
      // subscriptionTier: SubscriptionTierDetails.JSON,
    }),
  );
  const autocompleteDecoder = http.decode.response(UnknownApiError, PageAutocompleteResults);
  const pagesSearchDecoder = http.decode.response(
    UnknownApiError,
    t.type({
      hits: t.array(PageSearchWithHasPromoPrice),
      totalHitCount: t.number,
    }),
  );
  const boostPagesSearchDecoder = http.decode.response(
    UnknownApiError,
    t.type({
      hits: t.array(PageSearchWithHasPromoPrice),
      totalHitCount: t.number,
    }),
  );
  const searchPages2Decoder = http.decode.response(UnknownApiError, PagesJson.PagesSearch2ResponseC);
  const getSearchPages2DefaultConfigDecoder = http.decode.response(UnknownApiError, PagesJson.PagesSearch2ConfigC);
  const managedPagesDecoder = http.decode.response(UnknownApiError, t.array(PageDetails));
  const deletePageDecoder = http.decode.response(UnknownApiError, t.unknown);
  const businessCategoriesDecoder = http.decode.response(UnknownApiError, t.array(BusinessCategory.JSON));
  const createServiceGroupDecoder = http.decode.response(
    UnknownApiError,
    t.type({
      _id: ServiceGroupId,
    }),
  );
  const updateServiceGroupDecoder = http.decode.response(UnknownApiError, t.unknown);
  const deleteServiceGroupDecoder = http.decode.response(UnknownApiError, t.unknown);
  const getServiceGroupsDecoder = http.decode.response(UnknownApiError, t.array(ServiceGroup));
  const getGroupedServicesDecoder = http.decode.response(UnknownApiError, GroupedServices);
  const getWorkerGroupedServicesDecoder = http.decode.response(UnknownApiError, GroupedWorkerServices);
  const updateGroupedServicesOrderDecoder = http.decode.response(UnknownApiError, t.unknown);
  const getPageServicesDecoder = http.decode.response(UnknownApiError, t.array(SavedService));
  const createPageServiceDecoder = http.decode.response(
    UnknownApiError,
    t.type({
      _id: ServiceId,
    }),
  );
  const updatePageServiceDecoder = http.decode.response(UnknownApiError, t.unknown);
  const deletePageServiceDecoder = http.decode.response(UnknownApiError, t.unknown);
  const bulkUpdateServicesDecoder = http.decode.response(
    UnknownApiError,
    t.type({
      totalCount: t.number,
      updatedCount: t.number,
    }),
  );
  const bulkDeleteServicesDecoder = http.decode.response(
    UnknownApiError,
    t.type({
      totalCount: t.number,
      deletedCount: t.number,
    }),
  );
  const bulkUpdatePageServicesGroupDecoder = http.decode.response(
    UnknownApiError,
    t.type({
      totalCount: t.number,
      updatedCount: t.number,
    }),
  );
  const bulkUpdatePageServicesCategoryDecoder = http.decode.response(
    UnknownApiError,
    t.type({
      totalCount: t.number,
      updatedCount: t.number,
    }),
  );
  const bulkResetWorkerServicesDecoder = http.decode.response(
    UnknownApiError,
    t.type({
      totalCount: t.number,
      updatedCount: t.number,
    }),
  );
  const updatePageBusinessHoursDecoder = http.decode.response(UnknownApiError, t.unknown);
  const feedbackDecoder = http.decode.response(UnknownApiError, t.array(PublicFeedbackDetails));
  const feedbackWithAppointmentDecoder = http.decode.response(
    UnknownApiError,
    t.array(PublicFeedbackDetailsWithAppointment),
  );
  const addFeedbackDecoder = http.decode.response(UnknownApiError, FeedbackDetails);
  const addReportDecoder = http.decode.response(UnknownApiError, t.unknown);
  const updateFeedbackDecoder = http.decode.response(UnknownApiError, t.unknown);
  const updatePrivateFeedbackReplyDecoder = http.decode.response(UnknownApiError, t.unknown);
  const deletePrivateFeedbackReplyDecoder = http.decode.response(UnknownApiError, t.unknown);
  const deleteFeedbackDecoder = http.decode.response(UnknownApiError, t.unknown);
  const getPageWorkersDecoder = http.decode.response(UnknownApiError, t.array(SavedWorker.JSON));
  const searchWorkersToInviteDecoder = http.decode.response(UnknownApiError, t.array(WorkerSearchItem.JSON));
  const getPageWorkerDecoder = http.decode.response(UnknownApiError, SavedWorker.JSON);
  const pageClient = http.decode.response(UnknownApiError, PageClient);
  const updatePageWorkerServicesDecoder = http.decode.response(UnknownApiError, t.unknown);
  const giftCardSettingsDecoder = http.decode.response(UnknownApiError, PageGiftCardSettings.JSON);
  const updatePageGiftCardSettingsDecoder = http.decode.response(UnknownApiError, t.unknown);

  const orderWorkerDecoder = http.decode.response(UnknownApiError, t.unknown);
  const orderWorkersDecoder = http.decode.response(UnknownApiError, t.unknown);

  const getPageNotificationSettingsDecoder = http.decode.response(UnknownApiError, PageNotificationSettings);
  const updatePageNotificationSettingsDecoder = http.decode.response(UnknownApiError, t.unknown);

  const getPageBoostSettingsDecoder = http.decode.response(UnknownApiError, PageBoostSettings);

  const getPageRolesDecoder = http.decode.response(UnknownApiError, t.type({ roles: t.array(PageRoleOverview2.JSON) }));
  const replaceUserRolesDecoder = http.decode.response(UnknownApiError, t.unknown);
  const getPageMembersDecoder = http.decode.response(
    UnknownApiError,
    t.type({ members: t.array(PageMemberPreview.JSON) }),
  );
  const getCurrentUserMemberDecoder = http.decode.response(UnknownApiError, PageMemberPreview.JSON);
  const addPageMemberDecoder = http.decode.response(UnknownApiError, t.type({ userId: UserId }));
  const deletePageMemberDecoder = http.decode.response(UnknownApiError, t.unknown);
  const getPageMemberWorkersDecoder = http.decode.optionalResponse(UnknownApiError, t.array(SavedWorker.JSON));
  const deletePageMemberWorkerDecoder = http.decode.response(UnknownApiError, t.unknown);
  const createPageMemberWorkerDecoder = http.decode.response(UnknownApiError, HasId.json(WorkerId.JSON));
  const updatePageMemberProfileInfoDecoder = http.decode.response(UnknownApiError, t.type({ _id: UserId }));
  const getUsersForGlobalManualSmsDecoder = http.decode.response(UnknownApiError, t.array(PageMemberPreview.JSON));

  const listCitiesDecoder = http.decode.response(UnknownApiError, t.array(City));
  const listPublishedLocationsDecoder = http.decode.response(
    UnknownApiError,
    t.type({ cities: t.array(City), areas: t.array(Area) }),
  );
  const getMetaDecoder = http.decode.response(UnknownApiError, BusinessMeta);
  const getCategoriesAndCitiesMetaDecoder = http.decode.response(UnknownApiError, t.array(BusinessMetaSitemapEntry));

  const updatePageMemberProfilePhotoDecoder = http.decode.response(UnknownApiError, t.unknown);
  const deletePageMemberProfilePhotoDecoder = http.decode.response(UnknownApiError, t.unknown);
  const updateWorkerPhotoDecoder = http.decode.response(UnknownApiError, t.unknown);
  const updatePageProfilePhotoDecoder = http.decode.response(UnknownApiError, t.unknown);
  const deleteWorkerPhotoDecoder = http.decode.response(UnknownApiError, t.unknown);
  const updateOnlinePaymentStatusDecoder = http.decode.response(UnknownApiError, t.unknown);
  const updatePageOnlinePaymentSettingsDecoder = http.decode.response(UnknownApiError, t.unknown);
  const getPageOnlinePaymentsSettingsDecoder = http.decode.response(UnknownApiError, PageOnlinePaymentsSettings.JSON);

  const uploadGalleryImageDecoder = http.decode.response(UnknownApiError, ResponsiveImage);
  const reorderPageImagesDecoder = http.decode.response(UnknownApiError, t.unknown);
  const updateImageServicesDecoder = http.decode.response(UnknownApiError, t.unknown);
  const deletePageImageDecoder = http.decode.response(UnknownApiError, t.unknown);
  const getPageImagesDecoder = http.decode.response(UnknownApiError, t.array(ResponsiveImage));

  const publishPageDecoder = http.decode.response(UnknownApiError, t.unknown);
  const unpublishPageDecoder = http.decode.response(UnknownApiError, t.unknown);
  const requestSearchableDecoder = http.decode.response(UnknownApiError, t.unknown);
  const deleteSearchableDecoder = http.decode.response(UnknownApiError, t.unknown);
  const requestPageDeletionDecoder = http.decode.response(UnknownApiError, t.unknown);
  const createUserReferralDecoder = http.decode.response(UnknownApiError, t.unknown);
  const pageFeedbackScoreCountsDecoder = http.decode.response(UnknownApiError, PageFeedbackScoreCounts.CODEC);
  const updateHideWorkerReviewsDecoder = http.decode.response(UnknownApiError, t.unknown);
  const generateMarketplaceShareQrDecoder = http.decode.response(UnknownApiError, t.string);

  return {
    getMeta: async () => {
      const meta = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/meta`,
          },
          getMetaDecoder,
        ),
      );

      return meta;
    },

    getCategoriesAndCitiesMeta: async () => {
      const meta = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/meta/categories-and-cities`,
          },
          getCategoriesAndCitiesMetaDecoder,
        ),
      );

      return meta;
    },

    createPage: async (params) => {
      const requestBody: {
        categoryId: BusinessCategoryId;
        name: string;
        phoneNo: PhoneNumber;
        email?: Email;
        firstname: Firstname;
        lastname: Lastname;
        location?: Location;
        tags: string[];
        rawCity?: string;
        utmSource?: string;
        onboardingBookings?: string;
        onboardingGoal?: string;
        onboardingLaunchEta?: OnboardingLaunchEta.Any;
      } = {
        ...params,
        tags: params.tags || [],
      };

      const page = unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/multi`,
            data: requestBody,
            withCredentials: true,
          },
          createPageDecoder,
        ),
      );

      return page._id;
    },

    requestPageDeletion: async ({ pageId, deletionReason }) => {
      const requestBody: {
        deletionReason: string;
      } = {
        deletionReason: deletionReason,
      };

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${pageId}/request-page-deletion`,
            data: requestBody,
          },
          requestPageDeletionDecoder,
        ),
      );
    },

    updatePageInfoSimple: async ({ pageId, phoneNo, ...params }) => {
      const requestBody: {
        name: string;
        phone: PhoneNumber;
        email?: Email;
        description: string;
      } = {
        ...params,
        phone: phoneNo,
      };

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${pageId}/info-simple`,
            data: requestBody,
          },
          updatePageSimpleDecoder,
        ),
      );
    },

    updatePageAddress: async ({ pageId, ...params }) => {
      const requestBody: {
        location: Location;
      } = {
        ...params,
      };

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${pageId}/address`,
            data: requestBody,
          },
          updatePageAddressDecoder,
        ),
      );
    },

    getPageProfile: async (slug) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(slug)}/profile`,
          },
          pageProfileDecoder,
        ),
      ),
    getPageDetails: async (id) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(id)}`,
          },
          pageDetailsDecoder,
        ),
      ),
    getPagePermissions: async (id) => {
      const perms = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(id)}/permissions`,
          },
          pagePermissionsDecoder,
        ),
      );

      return new MeroPermissionsWrapper(perms);
    },
    getPageAccess: async (id) => {
      const access = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(id)}/own-access`,
          },
          pageOwnAccessDecoder,
        ),
      );

      return {
        permissions: new MeroPermissionsWrapper(access.permissions),
        roles: access.roles,
        // subscriptionTier: access.subscriptionTier,
      };
    },
    getManagedPages: async () =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/management/page`,
          },
          managedPagesDecoder,
        ),
      ),
    deletePage: async (id) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(id)}`,
          },
          deletePageDecoder,
        ),
      );
    },
    getBusinessCategories: async () =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/category`,
          },
          businessCategoriesDecoder,
        ),
      ),
    createServiceGroup: async ({ pageId, name }) => {
      const requestBody: { group: { name: string } } = {
        group: {
          name: name,
        },
      };

      const group = unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${pageId}/service-groups`,
            data: requestBody,
          },
          createServiceGroupDecoder,
        ),
      );

      return group._id;
    },
    updateServiceGroup: async ({ pageId, groupId, name }) => {
      const requestBody: { group: { name: string } } = {
        group: {
          name: name,
        },
      };

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${pageId}/service-groups/${groupId}`,
            data: requestBody,
          },
          updateServiceGroupDecoder,
        ),
      );
    },
    deleteServiceGroup: async ({ pageId, groupId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/page/${pageId}/service-groups/${groupId}`,
          },
          deleteServiceGroupDecoder,
        ),
      );
    },
    getServiceGroups: async (pageId) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${pageId}/service-groups`,
          },
          getServiceGroupsDecoder,
        ),
      ),
    getGroupedServices: async (pageId, searchTerm) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${pageId}/services-grouped`,
            params: {
              searchTerm,
            },
          },
          getGroupedServicesDecoder,
        ),
      ),
    getWorkerGroupedServices: async ({ pageId, workerId, searchTerm }) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/worker/${encodeURIComponent(
              workerId,
            )}/services-grouped`,
            params: {
              searchTerm,
            },
          },
          getWorkerGroupedServicesDecoder,
        ),
      ),
    updateGroupedServicesOrder: async (pageId, updatedOrder) => {
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${pageId}/services-grouped`,
            data: {
              updatedOrder,
            },
          },
          updateGroupedServicesOrderDecoder,
        ),
      );
    },
    getPageServices: async ({ pageId, hasGroup }) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${pageId}/services`,
            params: { hasGroup },
          },
          getPageServicesDecoder,
        ),
      ),
    createPageService: async ({ pageId, service, workerIds }) => {
      const requestBody: { service: Service; workerIds: WorkerId[] } = {
        service: service,
        workerIds: workerIds,
      };

      const response = unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${pageId}/services`,
            data: requestBody,
          },
          createPageServiceDecoder,
        ),
      );

      return response._id;
    },
    updatePageService: async ({ pageId, serviceId, service, workerIds }) => {
      const requestBody: {
        service: Service;
        workerIds: WorkerId[];
      } = {
        service: service,
        workerIds: workerIds,
      };

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${pageId}/services/${serviceId}`,
            data: requestBody,
          },
          updatePageServiceDecoder,
        ),
      );
    },
    deletePageService: async ({ pageId, serviceId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/page/${pageId}/services/${serviceId}`,
          },
          deletePageServiceDecoder,
        ),
      );
    },
    bulkUpdatePageServicesVisibility: async ({ pageId, workerId, serviceIds, isPrivate }) => {
      const requestBody: {
        serviceIds: ServiceId[];
        isPrivate: boolean;
        workerId?: WorkerId;
      } = {
        serviceIds: serviceIds,
        isPrivate: isPrivate,
        workerId: workerId,
      };

      return unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${pageId}/services/bulk/visibility`,
            data: requestBody,
          },
          bulkUpdateServicesDecoder,
        ),
      );
    },
    bulkUpdatePageServicesAutomaticApproval: async ({ pageId, workerId, serviceIds, automaticApproval }) => {
      const requestBody: {
        serviceIds: ServiceId[];
        automaticApproval: boolean;
        workerId?: WorkerId;
      } = {
        serviceIds: serviceIds,
        automaticApproval: automaticApproval,
        workerId: workerId,
      };

      return unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${pageId}/services/bulk/automatic-approval`,
            data: requestBody,
          },
          bulkUpdateServicesDecoder,
        ),
      );
    },
    bulkUpdatePageServicesGroup: async ({ pageId, serviceIds, groupId }) => {
      const requestBody: {
        serviceIds: ServiceId[];
        groupId?: ServiceGroupId;
      } = {
        serviceIds: serviceIds,
        groupId: groupId,
      };

      return unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${pageId}/services/bulk/group`,
            data: requestBody,
          },
          bulkUpdatePageServicesGroupDecoder,
        ),
      );
    },
    bulkUpdatePageServicesCategory: async ({ pageId, serviceIds, categoryId }) => {
      const requestBody: {
        serviceIds: ServiceId[];
        categoryId: BusinessCategoryId;
      } = {
        serviceIds: serviceIds,
        categoryId: categoryId,
      };

      return unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${pageId}/services/bulk/category`,
            data: requestBody,
          },
          bulkUpdatePageServicesCategoryDecoder,
        ),
      );
    },
    bulkResetWorkerServices: async ({ pageId, workerId, serviceIds }) => {
      const requestBody: {
        serviceIds: ServiceId[];
        workerId: WorkerId;
      } = {
        serviceIds: serviceIds,
        workerId: workerId,
      };

      return unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${pageId}/services/bulk/reset`,
            data: requestBody,
          },
          bulkResetWorkerServicesDecoder,
        ),
      );
    },
    bulkDeletePageServices: async ({ pageId, serviceIds }) => {
      const requestBody: {
        serviceIds: ServiceId[];
      } = {
        serviceIds: serviceIds,
      };

      return unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/page/${pageId}/services/bulk/delete`,
            data: requestBody,
          },
          bulkDeleteServicesDecoder,
        ),
      );
    },
    updatePageBusinessHours: async ({ pageId, businessHours }) => {
      const requestBody: {
        businessHours: BusinessHours;
      } = {
        businessHours,
      };

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${pageId}/business-hours`,
            data: requestBody,
          },
          updatePageBusinessHoursDecoder,
        ),
      );
    },
    getPageWorkers: async (pageId) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${pageId}/worker`,
          },
          getPageWorkersDecoder,
        ),
      ),
    searchWorkersToInvite: async ({ search, limit }) => {
      const params: {
        search?: string;
        limit?: number;
      } = { search, limit };
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/worker`,
            params,
          },
          searchWorkersToInviteDecoder,
        ),
      );
    },
    getPageWorker: async ({ pageId, workerId }) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${pageId}/worker/${workerId}`,
          },
          getPageWorkerDecoder,
        ),
      ),
    updatePageWorkerServices: async ({ pageId, workerId, services }) => {
      const requestBody: { services: SavedService[] } = { services };

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${pageId}/worker/${workerId}/service`,
            data: requestBody,
          },
          updatePageWorkerServicesDecoder,
        ),
      );
    },
    listCities: async () => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/city`,
          },
          listCitiesDecoder,
        ),
      );
    },
    listPublishedLocations: async () => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/published-locations`,
          },
          listPublishedLocationsDecoder,
        ),
      );
    },
    addFeedback: async ({ pageId, score, review, inReferenceToId, occurrenceIndex, isAnonymous }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${pageId}/feedback`,
            data: {
              review,
              score,
              inReferenceToId,
              occurrenceIndex,
              isAnonymous,
            },
          },
          addFeedbackDecoder,
        ),
      );
    },
    addReport: async ({ pageId, appointmentId, occurrenceIndex, message }) => {
      const requestBody: {
        readonly appointmentId: AppointmentId;
        readonly occurrenceIndex?: number;
        readonly message: string;
      } = {
        appointmentId: appointmentId,
        occurrenceIndex: occurrenceIndex,
        message: message,
      };

      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${pageId}/report`,
            data: requestBody,
          },
          addReportDecoder,
        ),
      );
    },
    updateFeedback: async ({ pageId, feedbackId, score, review, isAnonymous }) => {
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${pageId}/feedback/${feedbackId}`,
            data: {
              review,
              score,
              isAnonymous,
            },
          },
          updateFeedbackDecoder,
        ),
      );
    },
    updatePrivateFeedbackReply: async ({ pageId, feedbackId, reply }) => {
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${pageId}/feedback-private/${feedbackId}/reply`,
            data: {
              reply,
            },
          },
          updatePrivateFeedbackReplyDecoder,
        ),
      );
    },
    deletePrivateFeedbackReply: async ({ pageId, feedbackId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/page/${pageId}/feedback-private/${feedbackId}/reply`,
          },
          deletePrivateFeedbackReplyDecoder,
        ),
      );
    },
    getPageFeedback: async ({ pageId, limit, sortBy, sort }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${pageId}/feedback`,
            params: {
              limit,
              sortBy,
              sort,
            },
          },
          feedbackDecoder,
        ),
      );
    },
    getPagePrivateFeedback: async ({ pageId, limit, sortBy, sort, score, workerId, byUserId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${pageId}/feedback-private`,
            params: {
              limit,
              sortBy,
              sort,
              score,
              workerId,
              byUserId,
            },
          },
          feedbackWithAppointmentDecoder,
        ),
      );
    },
    deleteFeedback: async ({ pageId, feedbackId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/page/${pageId}/feedback/${feedbackId}`,
          },
          deleteFeedbackDecoder,
        ),
      );
    },
    isPageClient: async ({ pageId, userId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/client/page/${pageId}/user/${userId}`,
          },
          pageClient,
        ),
      );
    },
    searchPages: async (params) => {
      const getFilters = () => ({
        categories: params.categories?.join(','),
        name: params.name?.length ?? 0 > 0 ? params.name : undefined,
        city: params.city,
        lat: params.coords?.lat.toString(),
        long: params.coords?.long.toString(),
        limit: params.limit,
        offset: params.offset,
        deviceId: params.deviceId,
        showBoosted: params.showBoosted,
      });

      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/pages-search`,
            params: getFilters(),
          },
          pagesSearchDecoder,
        ),
      );
    },
    searchBoostPages: async (params) => {
      const getFilters = () => ({
        categories: params.categories?.join(','),
        city: params.city,
        lat: params.coords?.lat.toString(),
        long: params.coords?.long.toString(),
        randomize: params.randomize,
      });

      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/pages-search-boost`,
            params: getFilters(),
          },
          boostPagesSearchDecoder,
        ),
      );
    },
    searchPages2: async (params) => {
      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/pages-search2`,
            data: PagesJson.PagesSearch2RequestC.encode(params),
          },
          searchPages2Decoder,
        ),
      );
    },
    getSearchPages2DefaultConfig: async () => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/pages-search2-default-config`,
          },
          getSearchPages2DefaultConfigDecoder,
        ),
      );
    },
    autocomplete: async (params) => {
      const getFilters = () => ({
        categories: params.categories?.join(','),
        name: params.name?.length ?? 0 > 0 ? params.name : undefined,
        city: params.city,
        lat: params.coords?.lat.toString(),
        long: params.coords?.long.toString(),
        limit: params.limit,
        deviceId: params.deviceId,
      });

      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/pages-search-autocomplete`,
            params: getFilters(),
          },
          autocompleteDecoder,
        ),
      );
    },

    getPageGiftCardSettings: async ({ pageId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${pageId}/gift-card-settings`,
          },
          giftCardSettingsDecoder,
        ),
      );
    },
    updatePageGiftCardSettings: async ({ pageId, settings }) => {
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${pageId}/gift-card-settings`,
            data: settings,
          },
          updatePageGiftCardSettingsDecoder,
        ),
      );
    },

    getPageNotificationSettings: async ({ pageId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${pageId}/notification-settings`,
          },
          getPageNotificationSettingsDecoder,
        ),
      );
    },

    updatePageNotificationSettings: async ({ pageId, settings }) => {
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${pageId}/notification-settings`,
            data: settings,
          },
          updatePageNotificationSettingsDecoder,
        ),
      );
    },

    getPageBoostSettings: async ({ pageId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${pageId}/boost-settings`,
          },
          getPageBoostSettingsDecoder,
        ),
      );
    },

    getPageRoles: async (pageId) => {
      const { roles } = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${pageId}/roles/v2`,
          },
          getPageRolesDecoder,
        ),
      );

      return roles;
    },

    replaceUserRoles: async ({ pageId, userId, roles }) => {
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/members/${encodeURIComponent(
              userId,
            )}/roles`,
            data: {
              roles: roles,
            },
          },
          replaceUserRolesDecoder,
        ),
      );
    },

    getPageMembers: async (pageId) => {
      const { members } = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/members`,
          },
          getPageMembersDecoder,
        ),
      );

      return members;
    },

    getCurrentUserMember: async (pageId) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/members/me`,
          },
          getCurrentUserMemberDecoder,
        ),
      );
    },

    addPageMember: async ({ pageId, phone, firstname, lastname, roles }) => {
      const { userId } = unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/members`,
            data: {
              phone: phone,
              firstname: firstname,
              lastname: lastname,
              roles: roles,
            },
          },
          addPageMemberDecoder,
        ),
      );

      return userId;
    },

    deletePageMember: async ({ pageId, userId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/members/${encodeURIComponent(userId)}`,
          },
          deletePageMemberDecoder,
        ),
      );
    },

    getPageMemberWorkers: async ({ pageId, userId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/members/${encodeURIComponent(
              userId,
            )}/workers`,
          },
          getPageMemberWorkersDecoder,
        ),
      );
    },

    deletePageMemberWorker: async ({ pageId, userId, workerId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/members/${encodeURIComponent(
              userId,
            )}/workers/${encodeURIComponent(workerId)}`,
          },
          deletePageMemberWorkerDecoder,
        ),
      );
    },

    createPageMemberWorker: async ({ pageId, userId, services, addNewSeat, departmentIds }) => {
      const { _id } = unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${pageId}/members/${userId}/worker`,
            data: {
              services: SavedServiceArray.encode(services),
              addNewSeat: addNewSeat,
              departmentIds: departmentIds,
            },
          },
          createPageMemberWorkerDecoder,
        ),
      );

      return _id;
    },

    updatePageMemberProfileInfo: async ({ pageId, userId, firstname, lastname, phone }) =>
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/members/${encodeURIComponent(
              userId,
            )}/profile-info`,
            data: {
              firstname: firstname,
              lastname: lastname,
              phone: phone,
            },
          },
          updatePageMemberProfileInfoDecoder,
        ),
      ),

    updatePageMemberProfilePhoto: async ({ profileImage, pageId, userId }) => {
      const formData = new FormData();
      const ext = profileImage.blob.type.split('/')[1];

      if (profileImage.platform === 'web') {
        formData.append(
          'profileImage',
          new File([profileImage.blob], `profileImage.${ext}`, { type: profileImage.blob.type }),
        );
      } else {
        // https://www.reactnativeschool.com/how-to-upload-images-from-react-native
        const uri = profileImage.platform === 'ios' ? profileImage.uri.replace('file://', '') : profileImage.uri;

        formData.append('profileImage', {
          name: `profileImage.${ext}`,
          type: profileImage.blob.type,
          uri: uri,
        } as any);
      }

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${pageId}/members/${userId}/profile-photo`,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
          updatePageMemberProfilePhotoDecoder,
        ),
      );
    },

    deletePageMemberProfilePhoto: async ({ pageId, userId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/page/${pageId}/members/${userId}/profile-photo`,
          },
          deletePageMemberProfilePhotoDecoder,
        ),
      );
    },

    orderWorker: async ({ pageId, workerId, order }) => {
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${pageId}/worker/${workerId}/order`,
            data: { order },
          },
          orderWorkerDecoder,
        ),
      );
    },

    orderWorkers: async ({ pageId, workerIds }) => {
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/workers-order`,
            data: { workerIds },
          },
          orderWorkersDecoder,
        ),
      );
    },

    updateWorkerPhoto: async ({ profileImage, pageId, workerId }) => {
      const formData = new FormData();
      const ext = profileImage.blob.type.split('/')[1];

      if (profileImage.platform === 'web') {
        formData.append(
          'profileImage',
          new File([profileImage.blob], `profileImage.${ext}`, { type: profileImage.blob.type }),
        );
      } else {
        // https://www.reactnativeschool.com/how-to-upload-images-from-react-native
        const uri = profileImage.platform === 'ios' ? profileImage.uri.replace('file://', '') : profileImage.uri;

        formData.append('profileImage', {
          name: `profileImage.${ext}`,
          type: profileImage.blob.type,
          uri: uri,
        } as any);
      }

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${pageId}/worker/${workerId}/profile-photo`,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
          updateWorkerPhotoDecoder,
        ),
      );
    },
    updatePageProfilePhoto: async ({ profileImage, pageId }) => {
      const formData = new FormData();
      const ext = profileImage.blob.type.split('/')[1];

      if (profileImage.platform === 'web') {
        formData.append(
          'profileImage',
          new File([profileImage.blob], `profileImage.${ext}`, { type: profileImage.blob.type }),
        );
      } else {
        // https://www.reactnativeschool.com/how-to-upload-images-from-react-native
        const uri = profileImage.platform === 'ios' ? profileImage.uri.replace('file://', '') : profileImage.uri;

        formData.append('profileImage', {
          name: `profileImage.${ext}`,
          type: profileImage.blob.type,
          uri: uri,
        } as any);
      }

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${pageId}/profile-photo`,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
          updatePageProfilePhotoDecoder,
        ),
      );
    },

    deleteWorkerPhoto: async ({ pageId, workerId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/page/${pageId}/worker/${workerId}/profile-photo`,
          },
          deleteWorkerPhotoDecoder,
        ),
      );
    },
    updateOnlinePaymentsStatus: async ({ pageId, enabled }) => {
      const requestBody: {
        enabled: boolean;
      } = {
        enabled,
      };

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${pageId}/online-payments-status`,
            data: requestBody,
          },
          updateOnlinePaymentStatusDecoder,
        ),
      );
    },
    getPageOnlinePaymentsSettings: async ({ pageId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${pageId}/online-payments-settings`,
          },
          getPageOnlinePaymentsSettingsDecoder,
        ),
      );
    },
    updatePageOnlinePaymentsSettings: async ({ pageId, settings }) => {
      const requestBody: t.OutputOf<typeof PageOnlinePaymentsSettings.JSON> =
        PageOnlinePaymentsSettings.JSON.encode(settings);

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${pageId}/online-payments-settings`,
            data: requestBody,
          },
          updatePageOnlinePaymentSettingsDecoder,
        ),
      );
    },
    uploadGalleryImage: async ({ pageId, image }) => {
      const formData = new FormData();
      const ext = image.blob.type.split('/')[1];

      if (image.platform === 'web') {
        formData.append('image', new File([image.blob], `image.${ext}`, { type: image.blob.type }));
      } else {
        // https://www.reactnativeschool.com/how-to-upload-images-from-react-native
        const uri = image.platform === 'ios' ? image.uri.replace('file://', '') : image.uri;

        formData.append('image', {
          name: `image.${ext}`,
          type: image.blob.type,
          uri: uri,
        } as any);
      }

      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${pageId}/image`,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
          uploadGalleryImageDecoder,
        ),
      );
    },

    reorderImages: async ({ pageId, imageIds }) => {
      const requestBody = { imageIds };
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${pageId}/images/order`,
            data: requestBody,
          },
          reorderPageImagesDecoder,
        ),
      );
    },

    updateImageServices: async ({ pageId, imageId, services }) => {
      const requestBody = { services };
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/image/${encodeURIComponent(
              imageId,
            )}/services`,
            data: requestBody,
          },
          updateImageServicesDecoder,
        ),
      );
    },

    deleteImage: async ({ pageId, imageId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/page/${pageId}/image/${imageId}`,
          },
          deletePageImageDecoder,
        ),
      );
    },

    getPageImages: async ({ pageId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${pageId}/image`,
          },
          getPageImagesDecoder,
        ),
      );
    },

    publishPage: async ({ pageId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${pageId}/published`,
          },
          publishPageDecoder,
        ),
      );
    },

    unpublishPage: async ({ pageId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/page/${pageId}/publish-request`,
          },
          unpublishPageDecoder,
        ),
      );
    },

    requestPageSearchable: async ({ pageId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/searchable-request`,
          },
          requestSearchableDecoder,
        ),
      );
    },

    unsetPageSearchable: async ({ pageId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/searchable`,
          },
          deleteSearchableDecoder,
        ),
      );
    },

    createUserReferral: async ({
      userPhoneNumber,
      userFullName,
      pagePhoneNumber,
      pageName,
      recaptchaToken,
      recaptchaSiteKey,
      recaptchaAction,
    }) => {
      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/referrals/user`,
            data: {
              userPhoneNumber,
              userFullName,
              pagePhoneNumber,
              pageName,
              recaptchaToken,
              recaptchaSiteKey,
              recaptchaAction,
            },
          },
          createUserReferralDecoder,
        ),
      );
    },
    getPageFeedbackScoreCounts: async ({ pageId, score, workerId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/feedback-count-score`,
            params: {
              score,
              workerId,
            },
          },
          pageFeedbackScoreCountsDecoder,
        ),
      );
    },
    getUsersForGlobalManualSms: async ({ pageId: pageId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/notification/page/${pageId}/settings/manual-sms/users`,
          },
          getUsersForGlobalManualSmsDecoder,
        ),
      );
    },

    updateHideWorkerReviews: async ({ pageId, hideWorkerReviews }) => {
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${pageId}/settings/reviews`,
            data: {
              hideWorkerReviews: hideWorkerReviews,
            },
          },
          updateHideWorkerReviewsDecoder,
        ),
      );
    },

    generateMarketplaceShareQr: async ({ applyLogo, pageId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/profile/marketplace-share-qr`,
            params: {
              applyLogo: applyLogo,
            },
          },
          generateMarketplaceShareQrDecoder,
        ),
      );
    },
  };
};
