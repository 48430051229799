import { checkIfIsAllowedInterval, preZero } from '@/utils/time';
import { LocalDate, WorkScheduleInterval } from '@mero/api-sdk';
import * as React from 'react';
import { Platform, View } from 'react-native';

import { HoverHourCell } from '@/components/Calendar/BigCalendar/CalendarBodySplitView';
import TapHourCell from '@/components/Calendar/BigCalendar/CalendarBodySplitView/TapHourCell';
import FloatMenu from '@/components/Calendar/BigCalendar/FloatMenu';
import { styles as commonStyles } from '@/components/Calendar/BigCalendar/styles';
import { CalendarDateTime } from '@/components/Calendar/calendarDateTime';

const DIVIDE_HOUR = 4;

type Props = {
  readonly cellHeight: number;
  readonly onPress: (d: CalendarDateTime) => void;
  readonly date: LocalDate;
  readonly selectedTimezone: string;
  readonly hour: number;
  readonly divider?: number;
  readonly activeHours: WorkScheduleInterval[];
  readonly isActive: boolean;
  readonly selectedTime: string;
  readonly onAddBooking?: () => void;
  readonly onAddBlockedTime?: () => void;
  readonly onAddCheckout?: () => void;
};

const HourCell: React.FC<Props> = (props: Props) => {
  const {
    activeHours,
    cellHeight,
    onPress,
    date,
    selectedTimezone,
    hour,
    divider = DIVIDE_HOUR,
    isActive,
    selectedTime,
    onAddBooking,
    onAddBlockedTime,
    onAddCheckout,
  } = props;

  const onPressCallback = React.useCallback(
    (hour: number, minute: number) => {
      onPress(
        CalendarDateTime.fromObject(
          {
            year: date.year,
            month: date.month,
            day: date.day,
            hour: hour,
            minute: minute,
            second: 0,
            millisecond: 0,
          },
          { zone: selectedTimezone },
        ),
      );
    },
    [date, onPress],
  );

  return (
    <>
      {Array(divider)
        .fill(1)
        .map((_, index) => {
          const minute = (60 / divider) * index;
          const isAllowedInterval = checkIfIsAllowedInterval(activeHours, hour, minute);
          const cellId = `${date.day}:${date.month}:${date.year}:${preZero(hour)}:${preZero(minute)}`;
          return (
            <FloatMenu
              key={cellId}
              id={cellId}
              onAddBooking={onAddBooking}
              onAddBlockedTime={onAddBlockedTime}
              onAddCheckout={onAddCheckout}
              onPress={() => onPressCallback(hour, minute)}
            >
              <View
                style={[
                  index < divider - 1 ? commonStyles.dateCellSecondary : commonStyles.dateCellPrimary,
                  { height: cellHeight / divider },
                  { backgroundColor: isAllowedInterval ? '#FFFFFF' : '#EFEFEF' },
                ]}
              >
                {Platform.OS === 'web' ? (
                  <HoverHourCell id={cellId} hour={hour} minute={minute} />
                ) : isActive && selectedTime === `${hour}:${minute}` ? (
                  <TapHourCell hour={hour} minute={minute} />
                ) : null}
              </View>
            </FloatMenu>
          );
        })}
    </>
  );
};

export default React.memo(HourCell);
