import { ClientHistoryRecordId } from '@mero/api-sdk/dist/clients';
import { Body, ConfirmBox, H1, Spacer } from '@mero/components';
import * as React from 'react';

import ModalOverlay from '@mero/components/lib/components/ModalOverlay';

import { useMediaQueries } from '../../../../hooks/useMediaQueries';

export type Props = {
  readonly noteId: ClientHistoryRecordId;
  readonly onDismiss: () => void;
  readonly onConfirm: (noteId: ClientHistoryRecordId) => void;
};

/**
 * Client note removal confirmation modal
 */
const ClientConfirmNoteDeleteModal: React.FC<Props> = ({
  noteId,
  onDismiss,
  onConfirm,
}: Props): React.ReactElement | null => {
  const { isDesktop } = useMediaQueries();

  const confirmNoteDeleteCallback = React.useCallback(() => {
    onConfirm(noteId);
  }, [noteId, onConfirm]);

  const leftAction = {
    text: 'Anulează',
    onPress: onDismiss,
  };

  const rightAction = {
    text: 'Șterge notița',
    onPress: confirmNoteDeleteCallback,
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <ConfirmBox
        style={{ maxWidth: isDesktop ? 468 : undefined }}
        type="error"
        icon="info"
        headerTitle="Actiune ireversibilă"
        canClose={true}
        onClose={onDismiss}
        leftAction={leftAction}
        rightAction={rightAction}
      >
        <H1>Confirmă ștergere notiță</H1>
        <Spacer size="8" />
        <Body>Ești sigur ca vrei să ștergi notița?</Body>
      </ConfirmBox>
    </ModalOverlay>
  );
};

export default ClientConfirmNoteDeleteModal;
