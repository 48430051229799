import { CheckoutTransactionId } from '@mero/api-sdk/dist/checkout/checkoutTransactionId';
import { ProductSale } from '@mero/api-sdk/dist/pro/productSales/productSale';
import { colors } from '@mero/components';
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, Pressable, Text } from 'react-native';

import { Column, Row, Spacer, Title } from '../../../../../../components/shared';
import ClientEmptyListView from '../../../components/ClientEmptyListView';

import ClientProductCard from '../ClientProductCard';
import styles from './styles';

interface ClientProductsListProps {
  readonly productSales: ProductSale[];
  readonly handleProductPress: (transactionId: CheckoutTransactionId) => void;
  readonly onEndReached: () => void;
}

const ClientProductsList: React.FC<ClientProductsListProps> = ({ productSales, handleProductPress, onEndReached }) => {
  const { t } = useTranslation('clients');

  const renderItem = ({ item }: { item: ProductSale }) => {
    return <ClientProductCard product={item} handleProductPress={handleProductPress} />;
  };

  const listHeaderComponent = useCallback(() => {
    if (!productSales.length) {
      return null;
    }

    return (
      <Column>
        <Text style={styles.contentTitle}>{t('products')}</Text>
        <Spacer size={16} />
      </Column>
    );
  }, [productSales.length]);

  const keyExtractor = (record: ProductSale, index: number): string => `${record.transactionId}-${index}`;

  return (
    <FlatList
      style={styles.container}
      contentContainerStyle={styles.contentContainer}
      ListHeaderComponent={listHeaderComponent}
      data={productSales}
      keyboardShouldPersistTaps="handled"
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      windowSize={200}
      onEndReachedThreshold={0.5}
      onEndReached={onEndReached}
      ListEmptyComponent={
        <ClientEmptyListView
          title="Nu există produse"
          subtitle="Produsele achiziționate de client vor fi afișate aici"
        />
      }
    />
  );
};

export default ClientProductsList;
