export type CardWithColumn<Card> = {
  readonly column: number;
  readonly card: Card;
};

export type CardProps = {
  readonly start: number;
  readonly end: number;
};

export type SoftOverlapOptions = {
  /**
   * Will try to soft overlap when there are {@link startColumn} columns already, and there no empty
   * slot found
   */
  readonly startColumn: number;

  /**
   * Overlapping card should go more than minOpenSize for the card below (absolute value)
   * Use minOpenSize=0 to allow  complete overlap
   */
  readonly minOpenSize: number;
};

export type DistributeCardsOptions = {
  /**
   * Whe provided - each card will take a slot with minimum size equal to this value, even if
   * card size is smaller (so no overlap for minCardSize)
   */
  readonly minCardSize?: number;

  /**
   * When set - will try to "soft overlap" - allow overlaps cards just a little, to reduce number of columns.
   * Column number may still grow when soft overlap rules doesn't match either:
   *  - minOpenSize from the card below should remain uncovered by overlapping card
   */
  readonly softOverlap?: SoftOverlapOptions;
};

export type CardDistribution<Card extends CardProps> = {
  totalColumns: number;
  cards: CardWithColumn<Card>[];
};

export const EmptyCardDistribution: CardDistribution<never> = Object.freeze({
  totalColumns: 0,
  cards: [],
});

/**
 * Compare 2 card by {@link CardProps.start}
 */
const cardCompare = (a: CardProps, b: CardProps): -1 | 0 | 1 => {
  if (a.start < b.start) {
    return -1;
  } else if (a.start === b.start) {
    return 0;
  } else {
    return 1;
  }
};

/**
 * Compare cards by column or by {@link CarProps.start} if columns is the same
 */
const cardColumnCompare = <Card extends CardProps>(a: CardWithColumn<Card>, b: CardWithColumn<Card>): -1 | 0 | 1 => {
  if (a.column < b.column) {
    return -1;
  } else if (a.column === b.column) {
    return cardCompare(a.card, b.card);
  } else {
    return 1;
  }
};

/**
 * Check if 2 cards overlap
 * function assumes that any card has start <= end, no check is done
 * @param a
 * @param b
 * @returns true if given 2 cards overlaps
 */
export const cardOverlaps = (a: CardProps, b: CardProps): boolean => {
  if (a.start < b.start) {
    return a.end > b.start;
  } else {
    return b.end > a.start;
  }
};

/**
 * Check if 2 cards overlap, with softer rules.
 * If no {@link options} provided - will behave as {@link cardOverlaps} function
 * @param a
 * @param b
 * @param options soft overlap options
 * @returns true if 2 given cards overlap more than allowed (by options.minOpenSize)
 */
export const cardOverlapsSoft = (
  a: CardProps,
  b: CardProps,
  options?: Pick<SoftOverlapOptions, 'minOpenSize'>,
): boolean => {
  if (options !== undefined) {
    if (a.start < b.start) {
      return Math.min(a.start + options.minOpenSize, a.end) > b.start;
    } else {
      return Math.min(b.start + options.minOpenSize, b.end) > a.start;
    }
  } else {
    return cardOverlaps(a, b);
  }
};

/**
 * Distribute cards by columns
 *
 * - All cards will be sorted ascending by start time
 * - Board starts with single column with index 0
 * - There is an index of last event per column (with start and end time)
 * - For every card - start searching a slot from column 0, if no column is available - add new column
 * - A slot is empty (available) if it's last card end value is lower than current card start value
 * - Exact same value for next event start and prev event end is not considered overlap
 *
 * @param cards input list of cards
 * @returns
 */
export const distributeCards = <Card extends CardProps>(
  cards: Card[],
  options: DistributeCardsOptions = {},
): Readonly<CardDistribution<Card>> => {
  if (cards.length === 0) {
    return EmptyCardDistribution;
  } else if (cards.length === 1) {
    return {
      totalColumns: 1,
      cards: [
        {
          column: 0,
          card: cards[0],
        },
      ],
    };
  } else {
    const sortedCards = cards.sort(cardCompare);
    const minCardSize = Math.max(options.minCardSize ?? 0, 0);

    type Acc = {
      readonly lastCards: { [k in number]: CardProps };
      readonly distribution: CardDistribution<Card>;
    };

    const { distribution } = sortedCards.reduce(
      /**
       * @param acc will be mutated, tfor the sake of higher performance
       * @param card current card in the list
       */
      (acc: Acc, card): Acc => {
        // Check for some room in existing columns
        for (let colIndex = 0; colIndex < acc.distribution.totalColumns; colIndex++) {
          if (!cardOverlaps(acc.lastCards[colIndex], card)) {
            // Found a slot, set last card and move next card
            acc.distribution.cards.push({
              column: colIndex,
              card: card,
            });

            if (minCardSize > 0) {
              acc.lastCards[colIndex] = {
                start: card.start,
                end: Math.max(card.end, card.start + minCardSize),
              };
            } else {
              acc.lastCards[colIndex] = card;
            }

            return acc;
          }
        }

        /**
         * Try soft overlap before adding new column (totalColumns will increment)
         */
        if (options.softOverlap !== undefined && acc.distribution.totalColumns > options.softOverlap.startColumn) {
          for (let colIndex = 0; colIndex < acc.distribution.totalColumns; colIndex++) {
            if (!cardOverlapsSoft(acc.lastCards[colIndex], card, options.softOverlap)) {
              // Found a slot (with soft overlap), set last card and move next card
              acc.distribution.cards.push({
                column: colIndex,
                card: card,
              });

              if (minCardSize > 0) {
                acc.lastCards[colIndex] = {
                  start: card.start,
                  end: Math.max(card.end, card.start + minCardSize),
                };
              } else {
                acc.lastCards[colIndex] = card;
              }

              return acc;
            }
          }
        }

        // No matching column found, adding new one
        const newColumnIndex = acc.distribution.totalColumns;
        acc.distribution.totalColumns = acc.distribution.totalColumns + 1;
        acc.distribution.cards.push({
          column: newColumnIndex,
          card: card,
        });

        if (minCardSize > 0) {
          acc.lastCards[newColumnIndex] = {
            start: card.start,
            end: Math.max(card.end, card.start + minCardSize),
          };
        } else {
          acc.lastCards[newColumnIndex] = card;
        }

        return acc;
      },
      // Not using EmptyCardDistribution object here as it's frozen which will lead to undefine behavior on reduce function mutations
      { lastCards: {}, distribution: { totalColumns: 0, cards: [] } },
    );

    return distribution;
  }
};

export type BoardParams = {
  /**
   * Board starting point Y value (in card start/end units, ex: unix timestamp)
   */
  readonly start: number;
  /**
   * Board end point Y value (in card start/end unitss, ex: unix timestamp)
   */
  readonly end: number;
  /**
   * Number of columns that fits horizontally on the board without overlap
   */
  readonly columns: number;
  /**
   * When provided - card height will not be smaller than this value
   */
  readonly minCardSize?: number;
  /**
   * When set to true - cards y start position and height will be shrinked to fit the board
   */
  readonly shrinkCards?: boolean;
};

/**
 * Card positioning on a board (Viewport)
 * Display-like cordinates: y=0,x=0 - top left board corner;
 * Relative values: height=0.5, width=0.5 - middle of the screen;
 */
export type PositionedCard<Card> = {
  readonly card: Card;
  /**
   * Card vertical start position in units of height, ex: 0.5 - middle of the board
   * May be negative or higher than board height (> 1) - means cards are outside of board start/end limits
   */
  readonly y: number;
  /**
   * Card height in units of board height, ex: 0.5 - half of the board height
   */
  readonly height: number;
  /**
   * Card horizontal start position, in units of width, ex: 0 - left marin of the board
   */
  readonly x: number;
  /**
   * Carrd width in units of board width, ex: 0.5 - half of the board width
   */
  readonly width: number;
};

/**
 * Position cards on a board with given params
 * @param cards distributed by columns
 * @param board params ()
 * @returns list of cards with their positions on board
 */
export const positionCards = <Card extends CardProps>(
  cards: Readonly<CardDistribution<Card>>,
  board: BoardParams,
): PositionedCard<Card>[] => {
  const boardHeight = board.end - board.start;
  /**
   * Normalized board columns size (min 1)
   */
  const boardColumns = Math.max(1, Math.floor(board.columns));
  const minCardSize = Math.max(board.minCardSize ?? 0, 0);
  /**
   * Number of columns cards are distributed by
   */
  const cardsColumns = cards.totalColumns;

  /**
   * Board grid column width, single card shouldn't be wider than this value
   */
  const boardColumnWidth = 1 / boardColumns;
  /**
   * Single card width
   */
  const cardWidth =
    cardsColumns <= boardColumns
      ? // Width can't be bigger than board column size
        boardColumnWidth
      : // When there are more card columns than board columns - use an average as column size
        (boardColumnWidth + 1 / cardsColumns) / 2;
  /**
   * Horizontal offset unit value (for single column)
   */
  const xOffset =
    cards.totalColumns <= boardColumns
      ? // less cards columns than board columns:
        //  - offset exactly by 1 board column
        boardColumnWidth
      : // more cards columns than board columns:
        // - offset equally to fit all card columns
        // - last card should fit completely
        (1 - cardWidth) / (cardsColumns - 1);

  return (
    cards.cards
      // Sort cards by column first, so cards in columns from the right will cover cards from the left
      .sort(cardColumnCompare)

      .reduce((acc: PositionedCard<Card>[], cardWithCol) => {
        const card = cardWithCol.card;

        // Filter out cards that are outside of board start/end limits
        if (board.shrinkCards && (card.end < board.start || card.start > board.end)) {
          return acc;
        }

        const column = cardWithCol.column;

        const yStart = Math.max(card.start, board.shrinkCards ? board.start : card.start);
        const yEnd = Math.min(card.end, board.shrinkCards ? board.end : card.end);

        const posCard: PositionedCard<Card> = {
          card: cardWithCol.card,
          y: (yStart - board.start) / boardHeight,
          height: Math.max(yEnd - yStart, minCardSize) / boardHeight,
          x: xOffset * column,
          width: cardWidth,
        };
        acc.push(posCard);
        return acc;
      }, [])
  );
};
