import { StyleSheet } from 'react-native';

import { colors, sizes } from '../../../../../../styles';

const styles = StyleSheet.create({
  card: {
    marginBottom: sizes[16],
    padding: sizes[16],
  },
  button: {
    flexDirection: 'row',
  },
  image: { width: 32, height: 32, borderRadius: 6 },
  container: { flex: 1, paddingLeft: 16 },
  paymentLabel: {
    paddingHorizontal: 8,
    paddingVertical: 4,
    backgroundColor: '#E9ECEF',
    alignSelf: 'flex-start',
    borderRadius: 6,
  },
  name: { fontSize: 17 },
  paymentNumber: { color: colors.COMET, fontSize: 12, fontFamily: 'open-sans-semibold' },
});

export default styles;
