import { AppStorage } from '@/app-storage';
import { promiseDebounce } from '@/utils/functions';
import { DEFAULT_TIMEZONE } from '@/utils/time';
import { DateString, LocalDate } from '@mero/api-sdk';
import { CalendarId, CalendarSettings } from '@mero/api-sdk/dist/calendar';
import { BulkCalendarData } from '@mero/api-sdk/dist/calendar/bulkCalendarData';
import { BulkCalendarEntry } from '@mero/api-sdk/dist/calendar/bulkCalendarEntry';
import { BulkCalendarsResponse } from '@mero/api-sdk/dist/calendar/bulkCalendarsResponse';
import { PageDetails, PageId, RootPageRole, WorkerPageRole } from '@mero/api-sdk/dist/pages';
import { WorkScheduleInterval } from '@mero/api-sdk/dist/pro/workingSchedule/workScheduleInterval';
import { UserId } from '@mero/api-sdk/dist/users';
import { SavedWorker, WorkerId } from '@mero/api-sdk/dist/workers';
import { createModelContext } from '@mero/components';
import * as A from 'fp-ts/Array';
import * as Eq from 'fp-ts/Eq';
import * as Ord from 'fp-ts/Ord';
import * as Set from 'fp-ts/Set';
import { pipe } from 'fp-ts/function';
import * as S from 'fp-ts/string';
import { omit, union } from 'lodash';
import * as React from 'react';

import { CalendarPeriod } from '@/components/Calendar/BigCalendar/types';
import { CalendarDateTime } from '@/components/Calendar/calendarDateTime';

import log from '../../utils/log';
import { AppContext } from '../AppContext';
import { meroApi } from '../AuthContext';

export type LocalDateObject = {
  readonly year: number;
  readonly month: number;
  readonly day: number;
};

const isValidPartialLocalDateObject = (obj: Partial<LocalDateObject>): obj is LocalDateObject =>
  obj.year !== undefined && obj.month !== undefined && obj.day !== undefined;

export const LocalDateObject = {
  ...Ord.fromCompare<LocalDateObject>((a, b) => {
    if (a.year > b.year) {
      return 1;
    } else if (a.year < b.year) {
      return -1;
    } else if (a.month > b.month) {
      return 1;
    } else if (a.month < b.month) {
      return -1;
    } else if (a.day > b.day) {
      return 1;
    } else if (a.day < b.day) {
      return -1;
    } else {
      return 0;
    }
  }),
  unsafeFromPartial: (obj: Partial<LocalDateObject>): LocalDateObject => {
    if (!isValidPartialLocalDateObject(obj)) {
      throw new Error(`Invalid LocalDateObject: ${JSON.stringify(obj)}`);
    }

    return obj;
  },
  format: (obj: LocalDateObject): string => {
    return `${`000${obj.year}`.slice(-4)}-${`0${obj.month}`.slice(-2)}-${`0${obj.day}`.slice(-2)}`;
  },
  // TODO: benchmark format vs formatDay, formatMonth, formatYear (string slice vs if(s))
  formatDay: (object: LocalDateObject, padded = false): string => {
    if (padded && object.day < 10) {
      return `0${object.day}`;
    } else {
      return `${object.day}`;
    }
  },
  formatMonth: (object: LocalDateObject, padded = false): string => {
    if (padded && object.month < 10) {
      return `0${object.month}`;
    } else {
      return `${object.month}`;
    }
  },
  formatYear: (object: LocalDateObject): string => {
    if (object.year < 10) {
      return `000${object.year}`;
    } else if (object.year < 100) {
      return `00${object.year}`;
    } else if (object.year < 1000) {
      return `0${object.year}`;
    } else {
      return `${object.year}`;
    }
  },
};

const CalendarIdArray: Eq.Eq<CalendarId[]> = A.getEq(S.Eq);
const CalendarIdSet: Eq.Eq<Set<CalendarId>> = Set.getEq(S.Eq);

type CalendarQuery = (
  | {
      readonly period: 'day';
      readonly isAll: true;
    }
  | {
      readonly period: 'day';
      readonly isAll: false;
      readonly calendarIds: CalendarId[];
    }
  | {
      readonly period: 'week';
      readonly calendarId: CalendarId;
    }
) & {
  readonly includeDeleted: boolean;
  readonly hasFinishedCheckoutTransactions: boolean;
  readonly activeOnly: boolean;
  readonly showOnlyWorkingHours: boolean;
  readonly calendarsOrder: CalendarId[];
};

type Sockets = Record<CalendarId, SocketIOClient.Socket>;

type CalendarEntriesSlice = Readonly<
  {
    from: LocalDate;
    to: LocalDate;
    entries: BulkCalendarEntry[];
    loadedAt: Date;
    workingHours: BulkCalendarsResponse['activeDailyBounds'];
  } & BulkCalendarData
>;

type WorkingSchedule = Record<WorkerId, Record<DateString, WorkScheduleInterval[]>>;

type MessagingOptionsDetails = Readonly<{
  visible: boolean;
  message: string | null;
  phoneNumber: string | null;
  type: 'CREATE' | 'EDIT' | 'CANCEL' | null;
}>;

type CalendarState = {
  readonly type: 'Loaded';
  readonly debounceValue: number;
  readonly getAppointmentsCounter: number;
  readonly userId?: UserId;
  readonly pageId?: PageId;
  readonly period: CalendarPeriod;
  readonly selectedDate: LocalDate;
  readonly selectedTimezone: string;
  readonly selectedCalendars: CalendarId[];
  readonly calendarsOrder: CalendarId[];
  readonly activeOnly: boolean;
  readonly showOnlyWorkingHours: boolean;
  readonly includeDeleted: boolean;
  readonly hasFinishedCheckoutTransactions: boolean;
  readonly slices: Record<CalendarId, CalendarEntriesSlice | undefined>;
  readonly workingSchedule?: WorkingSchedule;
  readonly sockets: Sockets;
  readonly floatingMenuOpenId?: string;
  readonly messagingOptionsDetails: MessagingOptionsDetails;
};

type StateQuery = Pick<CalendarState, 'selectedDate' | 'period' | 'selectedCalendars'>;

/**
 * @returns true if 2 states have same query
 */
const isSameQuery = (a: StateQuery, b: StateQuery): boolean => {
  return (
    a.period === b.period &&
    a.selectedDate.equals(b.selectedDate) &&
    a.selectedCalendars.every((c1) => b.selectedCalendars.indexOf(c1) !== -1)
  );
};

const defaultState = (): CalendarState => {
  const now = LocalDate.local();

  const period: CalendarPeriod = {
    type: 'day',
    from: now,
    to: now,
  };

  return {
    type: 'Loaded',
    selectedDate: now,
    selectedCalendars: [],
    calendarsOrder: [],
    selectedTimezone: DEFAULT_TIMEZONE,
    period: period,
    includeDeleted: false,
    hasFinishedCheckoutTransactions: true,
    activeOnly: false,
    showOnlyWorkingHours: false,
    slices: {},
    sockets: {},
    debounceValue: 0,
    getAppointmentsCounter: 0,
    messagingOptionsDetails: {
      visible: false,
      message: null,
      phoneNumber: null,
      type: null,
    },
  };
};

const enableListeners = (() => {
  let sockets: Sockets = {};

  return async (
    calendarIds: CalendarId[],
    onEntryAddOrRemove: () => void,
  ): Promise<Record<CalendarId, SocketIOClient.Socket>> => {
    log.debug('Going to connect socket for calendarId', calendarIds.join());
    const socketsList = await Promise.all(
      calendarIds.map((calendarId) =>
        meroApi.calendar.socket({
          calendarId,
          handlers: {
            'calendar-entry-added': () => {
              log.info(`calendar-entry-added: ${calendarId}`);
              onEntryAddOrRemove();
            },
            'calendar-entry-updated': () => {
              log.info(`calendar-entry-updated: ${calendarId}`);
              onEntryAddOrRemove();
            },
            'calendar-entry-removed': () => {
              log.info(`calendar-entry-removed: ${calendarId}`);
              onEntryAddOrRemove();
            },
          },
        }),
      ),
    );
    log.debug('New sockets connected for calendarIds', calendarIds.join());

    const newSockets = socketsList.reduce(
      (acc: Sockets, socket, index) => ({
        ...acc,
        [calendarIds[index]]: socket,
      }),
      {},
    );

    sockets = {
      ...sockets,
      ...newSockets,
    };

    return sockets;
  };
})();

const shouldLoadAppointments = (currentSlice: CalendarEntriesSlice, period: CalendarPeriod): boolean => {
  const sliceContainsPeriod = currentSlice.from <= period.from && currentSlice.to >= period.to;
  // Slice is loaded less tha 1 minute ago
  const isRecentlyLoaded = new Date().getTime() - currentSlice.loadedAt.getTime() < 60000;

  log.debug('sliceContainsPeriod', currentSlice.from, currentSlice.to, 'period', period.from, period.to);

  return !(sliceContainsPeriod && isRecentlyLoaded);
};

const getCalendarPeriod = (selectedDate: LocalDate, period: CalendarPeriod['type']): CalendarPeriod => {
  const date = selectedDate;

  switch (period) {
    case 'day': {
      return {
        type: period,
        from: date,
        to: date,
      };
    }
    case 'week': {
      return {
        type: period,
        from: date.startOf('week'),
        to: date.endOf('week'),
      };
    }
  }
};

type GetAppointmentArguments = {
  pageId: PageId;
  calendarIds: CalendarId[];
  period: CalendarPeriod;
  timezone: string;
  includeDeleted: boolean;
  hasFinishedCheckoutTransactions: boolean;
  debounceValue: number;
};

const getAppointments = async ({
  pageId,
  calendarIds,
  period,
  timezone,
  includeDeleted,
  hasFinishedCheckoutTransactions,
  debounceValue,
}: GetAppointmentArguments): Promise<[Record<CalendarId, CalendarEntriesSlice>, WorkingSchedule]> => {
  log.debug(`Load appointments for calendarIds: ${JSON.stringify(calendarIds)} from: ${period.from}, to: ${period.to}`);

  const from = CalendarDateTime.fromObject(period.from, { zone: timezone }).startOf('day').toJSDate();
  const to = CalendarDateTime.fromObject(period.to, { zone: timezone }).endOf('day').toJSDate();

  const start = new Date();

  const [entries] = await promiseDebounce(
    'getBulkCalendarData',
    () => {
      return Promise.all([
        meroApi.calendar.getBulkCalendarData({
          pageId,
          calendarIds,
          from: from,
          to: to,
          includeDeleted,
          hasFinishedCheckoutTransactions: hasFinishedCheckoutTransactions
            ? undefined
            : hasFinishedCheckoutTransactions,
        }),
      ]);
    },
    debounceValue,
    true,
  );

  const end = new Date();

  log.debug(
    `Loaded ${JSON.stringify(
      entries.calendars.map((arr) => arr.entries.length),
    )} entries for calendarId ${JSON.stringify(calendarIds)} from: ${period.from}, to: ${period.to} in ${
      end.getTime() - start.getTime()
    }ms`,
  );

  const newEntries = entries.calendars.reduce((acc: Record<CalendarId, CalendarEntriesSlice>, calendar, index) => {
    const slice: CalendarEntriesSlice = {
      from: period.from,
      to: period.to,
      loadedAt: new Date(),
      ...calendar,
      workingHours: entries.activeDailyBounds,
    };

    return {
      ...acc,
      [calendar.worker.calendar._id]: slice,
    };
  }, {});

  const workingSchedule = entries.calendars.reduce((acc: WorkingSchedule, calendarData) => {
    return {
      ...acc,
      [calendarData.worker._id]: calendarData.dailySchedule.reduce((acc, day) => {
        return {
          ...acc,
          [day.date]: day.schedule.active ? day.schedule.intervals : [],
        };
      }, {}),
    };
  }, {});

  log.debug(
    `Computed newEntries and workingSchedule for calendarId ${JSON.stringify(calendarIds)} from: ${period.from}, to: ${
      period.to
    }`,
  );

  return [newEntries, workingSchedule];
};

export const CalendarContext = createModelContext(
  defaultState(),
  {
    update(state, payload: Partial<CalendarState>) {
      return {
        ...state,
        ...payload,
      };
    },
    setdebounceValue: (state, debounceValue: number) => {
      return {
        ...state,
        debounceValue,
      };
    },
    setFloatingMenuOpenId: (state, payload: { id?: string }) => {
      return {
        ...state,
        floatingMenuOpenId: payload.id,
      };
    },
    mutate: (s, fn: (s: CalendarState) => CalendarState): CalendarState => fn(s),
  },
  (dispatch) => {
    const reloadAsync = async (params: CalendarState): Promise<void> => {
      try {
        const { pageId, selectedCalendars, period, includeDeleted, hasFinishedCheckoutTransactions } = params;
        if (selectedCalendars.length > 0 && pageId) {
          const [newSlices, newWorkingSchedule] = await getAppointments({
            pageId,
            calendarIds: selectedCalendars,
            period,
            timezone: params.selectedTimezone,
            includeDeleted,
            hasFinishedCheckoutTransactions,
            debounceValue: params.debounceValue,
          });

          dispatch.mutate((state) => {
            if (isSameQuery(params, state)) {
              return {
                ...state,
                slices: newSlices,
                workingSchedule: newWorkingSchedule,
                selectedCalendars,
                getAppointmentsCounter: state.getAppointmentsCounter + 1,
              };
            }

            return state;
          });
        }
      } catch (e) {
        log.exception(e);
      }
    };

    const reload = (): void => {
      dispatch.mutate((state) => {
        log.debug('Reload CalendarContext');
        reloadAsync(state).catch(log.exception);

        return state;
      });
    };

    const setSelectedDate = (newDate: LocalDate): void => {
      log.debug(`Set selected date`, newDate);
      dispatch.mutate((prevState) => {
        log.debug(`Set selected date2`, newDate);
        if (prevState.selectedDate.equals(newDate)) {
          return prevState;
        }

        const state: CalendarState = {
          ...prevState,
          selectedDate: newDate,
          period: getCalendarPeriod(newDate, prevState.period.type),
        };

        const reloadIfOutdatedAsync = async (): Promise<void> => {
          if (state.selectedCalendars.length > 0) {
            log.debug(`Reload appointments if outdated ...`, state.selectedDate);
            const selectedSlice = state.slices[state.selectedCalendars[0]];

            if (selectedSlice === undefined || shouldLoadAppointments(selectedSlice, state.period)) {
              log.debug(
                `Going to reload appointments ... current slice range: ${selectedSlice?.from} - ${
                  selectedSlice?.to
                } (loaded at ${selectedSlice?.loadedAt.toISOString()})`,
                state.selectedDate,
              );
              await reloadAsync(state);
            } else {
              log.debug(
                `Won\'t load appointments ... current slice range: ${selectedSlice.from} - ${
                  selectedSlice.to
                } (loaded at ${selectedSlice.loadedAt.toISOString()})`,
                state.selectedDate,
              );
            }
          } else {
            log.info(`Won't load appointments ... no selectedCalendar available`, state.selectedDate);
          }
        };

        reloadIfOutdatedAsync().catch(log.exception);

        return state;
      });
    };

    const trySaveCalendarQuery = (): void => {
      dispatch.mutate((state) => {
        const trySaveCalendarQueryAsync = async (): Promise<void> => {
          if (state.pageId && state.userId) {
            if (state.period.type === 'day') {
              const query: CalendarQuery = {
                period: 'day',
                isAll: false, // TODO: handle isAll=true case (need to add info to CalendarState)
                calendarIds: state.selectedCalendars,
                includeDeleted: state.includeDeleted,
                hasFinishedCheckoutTransactions: state.hasFinishedCheckoutTransactions,
                activeOnly: state.activeOnly,
                showOnlyWorkingHours: state.showOnlyWorkingHours,
                calendarsOrder: state.calendarsOrder,
              };

              await AppStorage.setCalendarQuery(state.userId, state.pageId, query);
            } else if (state.selectedCalendars.length > 0) {
              const query: CalendarQuery = {
                period: 'week',
                calendarId: state.selectedCalendars[0],
                includeDeleted: state.includeDeleted,
                hasFinishedCheckoutTransactions: state.hasFinishedCheckoutTransactions,
                activeOnly: state.activeOnly,
                showOnlyWorkingHours: state.showOnlyWorkingHours,
                calendarsOrder: state.calendarsOrder,
              };

              await AppStorage.setCalendarQuery(state.userId, state.pageId, query);
            }
          }
        };

        trySaveCalendarQueryAsync().catch(log.exception);

        return state;
      });
    };

    const setMultipleCalendarsAsync = async (payload: {
      pageId: PageId;
      calendarIds: CalendarId[];
      calendarSettings: Pick<CalendarSettings, 'timezone'>;
      selectedDate: LocalDate;
      selectedTimezone: string;
      period: CalendarPeriod;
      sockets: Sockets;
      includeDeleted: boolean;
      hasFinishedCheckoutTransactions: boolean;
      activeOnly: boolean;
      showOnlyWorkingHours: boolean;
      calendarsOrder: CalendarId[];
      debounceValue: number;
    }): Promise<void> => {
      log.debug(`setMultipleCalendarsAsync for calendarIds: ${JSON.stringify(payload.calendarIds)}`);

      // disconnect current sockets
      Object.values(payload.sockets).forEach((socket) => socket.disconnect());

      // Load appointments, connect new sockets
      const [[newSlices, newSchedule], newSockets] = await Promise.all([
        getAppointments({
          pageId: payload.pageId,
          calendarIds: payload.calendarIds,
          period: payload.period,
          timezone: payload.selectedTimezone,
          includeDeleted: payload.includeDeleted,
          hasFinishedCheckoutTransactions: payload.hasFinishedCheckoutTransactions,
          debounceValue: payload.debounceValue,
        }),
        enableListeners(payload.calendarIds, reload).catch(log.exception),
      ]);

      dispatch.mutate((state) => {
        /**
         * Only update selectedCalendars if array really changed, otherwise it will trigger multiple updates
         *
         * FIXME: new order actually should matter (and trigger an update) - but there is somebody
         *  there calling setMultipleCalendarsAsync with new order without user interaction and it
         *  resets the order, find that and then revert back to comparing arrays
         */
        const isSameCalendarsSelection = CalendarIdSet.equals(
          Set.fromArray<CalendarId>(S.Eq)(state.selectedCalendars),
          Set.fromArray<CalendarId>(S.Eq)(payload.calendarIds),
        );

        const selectedCalendars = isSameCalendarsSelection ? state.selectedCalendars : payload.calendarIds;

        return {
          ...state,
          slices: newSlices,
          workingSchedule: newSchedule,
          selectedCalendars: selectedCalendars,
          sockets: newSockets ?? {},
          selectedTimezone: payload.calendarSettings.timezone,
          period: payload.period,
          includeDeleted: payload.includeDeleted,
          hasFinishedCheckoutTransactions: payload.hasFinishedCheckoutTransactions,
          showOnlyWorkingHours: payload.showOnlyWorkingHours,
          activeOnly: payload.activeOnly,
          calendarsOrder: union(payload.calendarsOrder, selectedCalendars),
          getAppointmentsCounter: state.getAppointmentsCounter + 1,
        };
      });

      trySaveCalendarQuery();
    };

    const showMessagingModal = async (
      message: string,
      phoneNumber: string,
      type: 'EDIT' | 'CREATE' | 'CANCEL' | null,
    ) => {
      dispatch.mutate((state) => ({
        ...state,
        messagingOptionsDetails: {
          type,
          visible: true,
          message,
          phoneNumber,
        },
      }));
    };
    const hideMessagingModal = () => {
      dispatch.mutate((state) => ({
        ...state,
        messagingOptionsDetails: {
          type: null,
          visible: false,
          message: null,
          phoneNumber: null,
        },
      }));
    };

    return {
      hideMessagingModal,
      showMessagingModal,
      reload: reload,
      reset: (payload: { userId: UserId; page: Pick<PageDetails, '_id' | 'people'>; team: SavedWorker[] }): void => {
        dispatch.mutate((state) => {
          const { userId, page, team } = payload;

          const sortedTeam = pipe(
            team,
            A.filter((w) => !w.ownPageExpired),
            A.sort({
              compare: (a: SavedWorker) => (a.user._id === userId ? -1 : 1),
              equals: (a: SavedWorker, b: SavedWorker) => a._id === b._id,
            }),
          );

          if (sortedTeam.length > 0) {
            log.debug(`Reset CalendarContext for user ${userId}, page: ${page._id}`);

            const resetAsync = async (): Promise<void> => {
              // 1. Load saved settings for current page
              const lastQuery: CalendarQuery | undefined = await AppStorage.getCalendarQuery(userId, page._id).catch(
                (e) => {
                  log.exception(e);

                  return undefined;
                },
              );

              if (lastQuery) {
                const lastTeam =
                  lastQuery.period === 'week'
                    ? sortedTeam.filter((w) => w.calendar._id === lastQuery.calendarId && !w.ownPageExpired)
                    : lastQuery.isAll
                    ? sortedTeam
                    : lastQuery.calendarIds
                        .map((c) => sortedTeam.find((w) => w.calendar._id === c))
                        .filter((w): w is SavedWorker => Boolean(w));

                const selectedTeam = lastTeam.length > 0 ? lastTeam : sortedTeam;

                await setMultipleCalendarsAsync({
                  pageId: page._id,
                  calendarIds: selectedTeam.map((w) => w.calendar._id),
                  calendarSettings: selectedTeam[0].calendar.settings,
                  selectedDate: state.selectedDate,
                  selectedTimezone: state.selectedTimezone,
                  period: getCalendarPeriod(state.selectedDate, lastQuery.period),
                  sockets: state.sockets,
                  includeDeleted: lastQuery.includeDeleted,
                  hasFinishedCheckoutTransactions: lastQuery.hasFinishedCheckoutTransactions,
                  activeOnly: lastQuery.activeOnly,
                  showOnlyWorkingHours: lastQuery.showOnlyWorkingHours,
                  calendarsOrder: lastQuery.calendarsOrder,
                  debounceValue: state.debounceValue,
                });

                return;
              } else {
                log.debug('Reset CalendarContext: lastQuery not available');
              }

              // 2. If no settings available - fallback to default
              const hasWorkerRole = page.people.find((p) => p.userId === userId && p.role === WorkerPageRole.value);
              const hasRootRole = page.people.find((p) => p.userId === userId && p.role === RootPageRole.value);

              // if professional - display the pro calendar
              if (hasWorkerRole && !hasRootRole) {
                const userWorker = sortedTeam.find((w) => w.user._id === userId);

                if (userWorker) {
                  log.debug('Reset CalendarContext: with worker default');

                  await setMultipleCalendarsAsync({
                    pageId: page._id,
                    calendarIds: [userWorker.calendar._id],
                    calendarSettings: userWorker.calendar.settings,
                    selectedDate: state.selectedDate,
                    selectedTimezone: state.selectedTimezone,
                    period: state.period,
                    sockets: state.sockets,
                    includeDeleted: state.includeDeleted,
                    hasFinishedCheckoutTransactions: state.hasFinishedCheckoutTransactions,
                    showOnlyWorkingHours: state.showOnlyWorkingHours,
                    activeOnly: state.activeOnly,
                    calendarsOrder: state.calendarsOrder,
                    debounceValue: state.debounceValue,
                  });

                  return;
                } else {
                  log.debug('Reset CalendarContext: user is worker but userWorker not found in team');
                }
              } else {
                log.debug('Reset CalendarContext: userRole not found or user not a WorkerPageRole');
              }

              log.debug('Reset CalendarContext: with other roles default (all team)');

              await setMultipleCalendarsAsync({
                pageId: page._id,
                calendarIds: sortedTeam.map((w) => w.calendar._id),
                calendarSettings: sortedTeam[0].calendar.settings,
                selectedDate: state.selectedDate,
                selectedTimezone: state.selectedTimezone,
                period: state.period,
                sockets: state.sockets,
                includeDeleted: state.includeDeleted,
                hasFinishedCheckoutTransactions: state.hasFinishedCheckoutTransactions,
                showOnlyWorkingHours: state.showOnlyWorkingHours,
                activeOnly: state.activeOnly,
                calendarsOrder: state.calendarsOrder,
                debounceValue: state.debounceValue,
              });
            };

            resetAsync().catch(log.exception);

            return {
              ...state,
              userId: userId,
              pageId: page._id,
            };
          } else {
            log.warn('Cannot reset CalendarContext: team is empty');
            return {
              ...state,
              userId: userId,
              pageId: page._id,
            };
          }
        });
      },
      setSelectedDate: (newDate: LocalDate): void => {
        setSelectedDate(newDate);
      },
      setMultipleCalendars: ({
        calendarIds,
        calendarSettings,
        period: newPeriod,
        activeOnly,
      }: {
        calendarIds: CalendarId[];
        calendarSettings: Pick<CalendarSettings, 'timezone'>;
        period?: CalendarPeriod['type'];
        activeOnly?: boolean;
      }): void => {
        dispatch.mutate((state) => {
          if (state.pageId) {
            setMultipleCalendarsAsync({
              pageId: state.pageId,
              calendarIds: calendarIds,
              calendarSettings: calendarSettings,
              selectedDate: state.selectedDate,
              selectedTimezone: state.selectedTimezone,
              period: newPeriod ? getCalendarPeriod(state.selectedDate, newPeriod) : state.period,
              sockets: state.sockets,
              includeDeleted: state.includeDeleted,
              hasFinishedCheckoutTransactions: state.hasFinishedCheckoutTransactions,
              activeOnly: activeOnly ?? state.activeOnly,
              showOnlyWorkingHours: state.showOnlyWorkingHours,
              calendarsOrder: state.calendarsOrder,
              debounceValue: state.debounceValue,
            }).catch(log.exception);
          }

          return state;
        });
      },
      addSelectedCalendar: ({
        calendarId,
        calendarSettings,
      }: {
        calendarId: CalendarId;
        calendarSettings: Pick<CalendarSettings, 'timezone'>;
      }): void => {
        dispatch.mutate((state) => {
          const { selectedCalendars, period, slices, sockets, workingSchedule } = state;

          const addSelectedCalendarAsync = async (): Promise<void> => {
            if (!selectedCalendars.includes(calendarId) && state.pageId) {
              const newSelectedCalendars = [...selectedCalendars, calendarId];

              log.debug(`addSelectedCalendar ${calendarId} -> `, newSelectedCalendars);

              const [[newSlice, newSchedule], newSocket] = await Promise.all([
                getAppointments({
                  pageId: state.pageId,
                  calendarIds: [calendarId],
                  period: state.period,
                  timezone: state.selectedTimezone,
                  includeDeleted: state.includeDeleted,
                  hasFinishedCheckoutTransactions: state.hasFinishedCheckoutTransactions,
                  debounceValue: state.debounceValue,
                }),
                enableListeners([calendarId], reload).catch(log.exception),
              ]);

              dispatch.update({
                slices: {
                  ...slices,
                  ...newSlice,
                },
                workingSchedule: {
                  ...workingSchedule,
                  ...newSchedule,
                },
                selectedCalendars: newSelectedCalendars,
                selectedTimezone: calendarSettings.timezone,
                calendarsOrder: [...state.calendarsOrder.filter((s) => s !== calendarId), calendarId],
                sockets: {
                  ...sockets,
                  ...newSocket,
                },
                period:
                  newSelectedCalendars.length > 1 && state.period.type !== 'day'
                    ? getCalendarPeriod(state.selectedDate, 'day')
                    : period, // Reset mode to 'day' if more than 1 calendar selected
                getAppointmentsCounter: state.getAppointmentsCounter + 1,
              });

              trySaveCalendarQuery();
            }
          };

          addSelectedCalendarAsync().catch(log.exception);

          return state;
        });
      },
      removeSelectedCalendar: (calendarId: CalendarId): void => {
        dispatch.mutate((state) => {
          if (state.selectedCalendars.length > 1) {
            state.sockets[calendarId]?.disconnect();

            dispatch.update({
              selectedCalendars: state.selectedCalendars.filter((s) => s !== calendarId),
              calendarsOrder: state.calendarsOrder,
              slices: omit(state.slices, calendarId),
            });

            trySaveCalendarQuery();
          }

          return state;
        });
      },

      toggleIncludeDeleted: (): void => {
        dispatch.mutate((state) => {
          const { pageId } = state;
          if (pageId) {
            const updateAsync = async (): Promise<void> => {
              const [slices, workingSchedule] = await getAppointments({
                pageId: pageId,
                calendarIds: state.selectedCalendars,
                period: state.period,
                timezone: state.selectedTimezone,
                includeDeleted: !state.includeDeleted,
                hasFinishedCheckoutTransactions: state.hasFinishedCheckoutTransactions,
                debounceValue: state.debounceValue,
              });

              dispatch.update({
                includeDeleted: !state.includeDeleted,
                slices,
                workingSchedule,
                getAppointmentsCounter: state.getAppointmentsCounter + 1,
              });

              trySaveCalendarQuery();
            };

            updateAsync().catch(log.exception);
          }

          return state;
        });
      },
      toggleHasFinishedCheckoutTransactions: (): void => {
        dispatch.mutate((state) => {
          const { pageId } = state;
          if (pageId) {
            const updateAsync = async (): Promise<void> => {
              const [slices, workingSchedule] = await getAppointments({
                pageId: pageId,
                calendarIds: state.selectedCalendars,
                period: state.period,
                timezone: state.selectedTimezone,
                includeDeleted: state.includeDeleted,
                hasFinishedCheckoutTransactions: !state.hasFinishedCheckoutTransactions,
                debounceValue: state.debounceValue,
              });

              dispatch.update({
                hasFinishedCheckoutTransactions: !state.hasFinishedCheckoutTransactions,
                slices,
                workingSchedule,
                getAppointmentsCounter: state.getAppointmentsCounter + 1,
              });

              trySaveCalendarQuery();
            };

            updateAsync().catch(log.exception);
          }

          return state;
        });
      },
      toggleActiveOnly: (): void => {
        dispatch.mutate((state) => {
          dispatch.update({
            activeOnly: !state.activeOnly,
          });

          trySaveCalendarQuery();

          return state;
        });
      },
      setActiveOnly: (activeOnly: boolean): void => {
        dispatch.update({
          activeOnly,
        });

        trySaveCalendarQuery();
      },
      toggleOnlyWorkingHours: (): void => {
        dispatch.mutate((state) => {
          dispatch.update({
            showOnlyWorkingHours: !state.showOnlyWorkingHours,
          });

          trySaveCalendarQuery();

          return state;
        });
      },
      setdebounceValue: dispatch.setdebounceValue,
      setFloatingMenuOpenId: (id?: string) => dispatch.setFloatingMenuOpenId({ id }),
    };
  },
);

const ContextInit: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [appState] = AppContext.useContext();
  const [, { setdebounceValue }] = CalendarContext.useContext();

  React.useEffect(() => {
    setdebounceValue(
      appState.type === 'Loaded' && appState.featureFlags.calendarDebounce?.enabled
        ? appState.featureFlags.calendarDebounce.value
        : 0,
    );
  }, [appState.type === 'Loaded' && appState.featureFlags.calendarDebounce?.enabled]);

  return <>{children}</>;
};

export const withCalendarContextProvider = <P extends object>(
  Component: React.ComponentType<P>,
): React.FunctionComponent<P> => {
  return function WithCalendarContextProvider(props) {
    return (
      <CalendarContext.Provider>
        <ContextInit>
          <Component {...props} />
        </ContextInit>
      </CalendarContext.Provider>
    );
  };
};
