import { colors, Icon, Row, Spacer } from '@mero/components';
import * as React from 'react';
import { Pressable, View } from 'react-native';

import FloatMenu, { Ref as FloatMenuRefu } from '../../../../../../components/FloatMenu';
import { FormCard, Label, Line, SimpleListItem, SmallBody, Title } from '../../../../../../components/shared';

import styles from './styles';

interface ClientRemarkCardProps {
  remark: string;
  onRemarkEdit: () => void;
  onRemarkDelete: () => void;
  floatMenuRef: React.RefObject<FloatMenuRefu>;
}

const ClientRemarkCard: React.FC<ClientRemarkCardProps> = ({ onRemarkEdit, onRemarkDelete, remark, floatMenuRef }) => {
  return (
    <>
      <FormCard rounded borderRadius={6} dropShaddow paddings="none" style={styles.card}>
        <Row alignItems="center" justifyContent="space-between">
          <Label style={styles.itemTitle}>Observații</Label>
          <View>
            <FloatMenu
              touchableActiveOpacity={1}
              ref={floatMenuRef}
              button={
                <View>
                  <Icon type="options-dots" color={colors.DARK_BLUE} />
                </View>
              }
            >
              <View style={[styles.optionsListContainer, styles.modalBorderBottom]}>
                <Pressable style={styles.optionsMenuItem} onPress={onRemarkEdit}>
                  <SimpleListItem>
                    <Title>Modifica observație</Title>
                  </SimpleListItem>
                </Pressable>
                <Line />
                <Pressable style={styles.optionsMenuItem} onPress={onRemarkDelete}>
                  <SimpleListItem>
                    <Title style={{ color: colors.RADICAL_RED }}>Sterge observație</Title>
                  </SimpleListItem>
                </Pressable>
              </View>
            </FloatMenu>
          </View>
        </Row>
        <SmallBody>{remark}</SmallBody>
      </FormCard>

      <Spacer size={16} />
    </>
  );
};

export default ClientRemarkCard;
