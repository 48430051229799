import { DEFAULT_TIMEZONE } from '@/utils/time';
import { AppointmentId, AppointmentStatus } from '@mero/api-sdk/dist/calendar';
import { AppointmentHistoryRecord } from '@mero/api-sdk/dist/calendar/appointment-history-record';
import { CheckoutTransactionPreview } from '@mero/api-sdk/dist/checkout/checkoutTransactionPreview';
import { Price } from '@mero/api-sdk/dist/services/price';
import {
  Body,
  colors,
  Column,
  H3s,
  Label,
  Line,
  Row,
  SmallBody,
  Spacer,
  Title,
  styles as meroStyles,
} from '@mero/components';
import { capitalize } from '@mero/shared-components';
import MuiTooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Instance } from '@popperjs/core';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Dimensions } from 'react-native';

import { CalendarDateTime } from '@/components/Calendar/calendarDateTime';

import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import log from '../../../../../utils/log';
import { national } from '../../../../../utils/phone';
import { scaledToString } from '../../../../../utils/scaled';
import { NormalizedAppointmentEvent } from '../../../NormalizedEvent';

const TOOLTIP_WIDTH = 300;
const SPACER_SIZE = 8;

const ClientAppointmentStatusTextView: React.FC<{ status: AppointmentStatus; isPast: boolean }> = ({
  status,
  isPast,
}) => {
  const { t } = useTranslation('calendar');

  switch (status) {
    case 'accepted': {
      if (isPast) {
        return <Label style={[meroStyles.text.bold, { color: colors.COMET }]}>{t('done')}</Label>;
      } else {
        return <Label style={[meroStyles.text.bold, { color: colors.SHAMROCK }]}>{t('confirmed')}</Label>;
      }
    }
    case 'cancelled': {
      return <Label style={[meroStyles.text.bold, { color: colors.RADICAL_RED }]}>{t('canceled')}</Label>;
    }
    case 'noShow': {
      return <Label style={[meroStyles.text.bold, { color: colors.OUTRAGEOUS_ORANGE }]}>{t('noShow')}</Label>;
    }
    case 'pending': {
      return <Label style={[meroStyles.text.bold, { color: colors.COMET }]}>{t('pending')}</Label>;
    }
    case 'rejected': {
      return <Label style={[meroStyles.text.bold, { color: colors.RADICAL_RED }]}>{t('declined')}</Label>;
    }
  }
};

const SmallBodyTitle: React.FC<React.PropsWithChildren> = ({ children }) => (
  <SmallBody style={{ color: colors.COMET, fontSize: 12, fontFamily: 'open-sans-semibold' }}>{children}</SmallBody>
);

const TooltipContent: React.FC<Props> = ({ event, now }) => {
  const { t } = useTranslation('booking');

  const [pageState] = CurrentBusinessContext.useContext();

  const now2EndDiff = React.useMemo(() => now.diff(event.end, 'minute'), [now, event.end]);
  const isPastEvent = now2EndDiff > 0;

  const [history, setHistory] = React.useState<AppointmentHistoryRecord[]>([]);
  const [checkout, setCheckout] = React.useState<CheckoutTransactionPreview.Any>();

  const total = React.useMemo(
    () =>
      event.extra.raw?.bookedServices.reduce((acc, service) => {
        if (service.price.type === 'hidden') {
          return acc + (service.price.fixed ?? 0);
        }

        if (service.price.type === 'fixed' && service.price.promo) {
          return acc + service.price.promo;
        }

        if (service.price.type === 'fixed') {
          return acc + (service.price.fixed ?? 0);
        }

        if (service.price.type === 'range') {
          return acc + ((service.price.range.from ?? 0) + (service.price.range.to ?? 0)) / 2;
        }

        return acc;
      }, 0) || 0,
    [],
  );

  const interval = React.useMemo(() => {
    const start = event.start.toFormat('ccc dd MMM HH:mm', { locale: 'ro' }).toLowerCase();
    const end = event.end.toFormat('HH:mm');
    const diffMinutesTotal = event.end.diff(event.start, 'minutes');
    const diffHours = Math.floor(diffMinutesTotal / 60);
    const diffMinutes = diffMinutesTotal - diffHours * 60;
    const formattedDiff = [diffHours ? `${diffHours}h` : undefined, diffMinutes ? `${diffMinutes}m` : undefined]
      .filter(Boolean)
      .join(' ');
    return `${capitalize(start)} - ${end} (${formattedDiff})`;
  }, []);

  const formatDuration = React.useCallback((duration: number) => {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    return [hours ? `${hours}h` : undefined, minutes ? `${minutes}m` : undefined].filter(Boolean).join(' ');
  }, []);

  const formatPrice = React.useCallback((price: Price) => {
    if (price.type === 'hidden') {
      return price.fixed ? `${price.fixed} lei` : 'n/a';
    }

    if (price.type === 'fixed' && price.promo) {
      return `${price.promo} lei`;
    }

    if (price.type === 'fixed') {
      return `${price.fixed} lei`;
    }

    if (price.type === 'range') {
      return `${price.range.from} - ${price.range.to} lei`;
    }

    return 'n/a';
  }, []);

  const generateHistoryText = React.useCallback((history: AppointmentHistoryRecord) => {
    switch (history.type) {
      case 'appointmentCreatedByClient': {
        const start = CalendarDateTime.fromJSDate(history.payload.start, { zone: DEFAULT_TIMEZONE });

        return (
          <Trans
            ns="booking"
            t={t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.client.firstname} ${history.payload.client.lastname}`,
              date: start.toFormat('dd MMMM yyyy', { locale: 'ro' }),
              hour: start.toFormat('HH:mm', { locale: 'ro' }),
              worker: `${history.payload.worker.firstname} ${history.payload.worker.lastname}`,
            }}
          >
            0<SmallBodyTitle>1</SmallBodyTitle>2<SmallBodyTitle>3</SmallBodyTitle>4<SmallBodyTitle>5</SmallBodyTitle>
          </Trans>
        );
      }
      case 'appointmentCreatedByPro': {
        const start = CalendarDateTime.fromJSDate(history.payload.start);

        return (
          <Trans
            ns="booking"
            t={t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.byUser.firstname} ${history.payload.byUser.lastname}`,
              phone: national(history.payload.byUser.phone),
              date: start.toFormat('dd MMMM yyyy', { locale: 'ro' }),
              hour: start.toFormat('HH:mm', { locale: 'ro' }),
              worker: `${history.payload.worker.firstname} ${history.payload.worker.lastname}`,
            }}
          >
            <SmallBodyTitle>0</SmallBodyTitle>1<SmallBodyTitle>2</SmallBodyTitle>3<SmallBodyTitle>4</SmallBodyTitle>5
            <SmallBodyTitle>6</SmallBodyTitle>
          </Trans>
        );
      }
      case 'appointmentCancelledByClient':
        return (
          <Trans
            ns="booking"
            t={t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.client.firstname} ${history.payload.client.lastname}`,
              reason: history.payload.reason ? t('withReason', { reason: history.payload.reason }) : t('noReason'),
            }}
          >
            0<SmallBodyTitle>1</SmallBodyTitle>
          </Trans>
        );
      case 'appointmentCancelledByPro':
        return (
          <Trans
            ns="booking"
            t={t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.byUser.firstname} ${history.payload.byUser.lastname}`,
              phone: national(history.payload.byUser.phone),
              reason: history.payload.reason ? t('withReason', { reason: history.payload.reason }) : t('noReason'),
            }}
          >
            <SmallBodyTitle>0</SmallBodyTitle>1<SmallBodyTitle>2</SmallBodyTitle>
          </Trans>
        );
      case 'appointmentModifiedByPro': {
        const from = CalendarDateTime.fromJSDate(history.payload.start.from, { zone: DEFAULT_TIMEZONE });
        const to = CalendarDateTime.fromJSDate(history.payload.start.to, { zone: DEFAULT_TIMEZONE });
        return (
          <Trans
            ns="booking"
            t={t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.byUser.firstname} ${history.payload.byUser.lastname}`,
              phone: national(history.payload.byUser.phone),
              startDate: from.toFormat('ccc dd MMMM yyyy', { locale: 'ro' }),
              endDate: to.toFormat('ccc dd MMMM yyyy', { locale: 'ro' }),
              startHour: from.toFormat('HH:mm', { locale: 'ro' }),
              endHour: to.toFormat('HH:mm', { locale: 'ro' }),
            }}
          >
            <SmallBodyTitle>0</SmallBodyTitle>1<SmallBodyTitle>2</SmallBodyTitle>3<SmallBodyTitle>4</SmallBodyTitle>5
            <SmallBodyTitle>6</SmallBodyTitle>7<SmallBodyTitle>8</SmallBodyTitle>
          </Trans>
        );
      }
      case 'appointmentConfirmed':
        return (
          <Trans
            ns="booking"
            t={t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.byUser.firstname} ${history.payload.byUser.lastname}`,
              phone: national(history.payload.byUser.phone),
            }}
          >
            <SmallBodyTitle>0</SmallBodyTitle>1
          </Trans>
        );
      case 'appointmentRefused':
        return (
          <Trans
            ns="booking"
            t={t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.byUser.firstname} ${history.payload.byUser.lastname}`,
              phone: national(history.payload.byUser.phone),
            }}
          >
            <SmallBodyTitle>0</SmallBodyTitle>1
          </Trans>
        );
      case 'appointmentMarkedAsNoShow':
        return (
          <Trans
            ns="booking"
            t={t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.byUser.firstname} ${history.payload.byUser.lastname}`,
              phone: national(history.payload.byUser.phone),
            }}
          >
            <SmallBodyTitle>0</SmallBodyTitle>1<SmallBodyTitle>2</SmallBodyTitle>
          </Trans>
        );
    }
  }, []);

  React.useEffect(() => {
    if (AppointmentId.is(event.extra.id)) {
      meroApi.calendar
        .fetchAppointmentHistory({ appointmentId: event.extra.id })
        .then((history) => {
          setHistory(history);
        })
        .catch((error) => {
          log.error('Failed to fetch history', error);
        });

      if (event.extra.raw?.hasFinishedCheckoutTransactions) {
        meroApi.checkout
          .listAppointmentTransactions({
            pageId: event.extra.raw.page._id,
            appointmentId: event.extra.id,
            occurrenceIndex: event.extra.occurrenceIndex,
          })
          .then((transactions) => {
            const finished = transactions.data.find((transaction) => transaction.status === 'Finished');
            setCheckout(finished);
          })
          .catch((error) => {
            log.error('Failed to fetch checkout transaction', error);
          });
      }
    }
  }, [event]);

  return (
    <Column style={{ backgroundColor: colors.WHITE, width: TOOLTIP_WIDTH }}>
      {event.extra.raw?.hasFinishedCheckoutTransactions && (
        <Row style={{ paddingHorizontal: 16, paddingVertical: 12, backgroundColor: colors.SHAMROCK }}>
          <Label style={{ color: colors.WHITE, fontFamily: 'open-sans-semibold', flex: 1 }}>
            {checkout?.paymentTypes && checkout.paymentTypes.length === 0
              ? t('appointmentProtocol')
              : t('appointmentBilled')}
          </Label>
          {checkout ? (
            <Label style={{ color: colors.WHITE, fontFamily: 'open-sans-semibold' }}>
              {scaledToString(checkout.total.total.amount)} {t(checkout.total.total.unit)}
            </Label>
          ) : null}
        </Row>
      )}
      <Column style={{ paddingHorizontal: 16 }}>
        <Spacer size={12} />
        {event.extra.raw && !event.extra.raw.hasFinishedCheckoutTransactions ? (
          <>
            <ClientAppointmentStatusTextView status={event.extra.raw.status} isPast={isPastEvent} />
            <Spacer size={4} />
          </>
        ) : null}
        <Row style={{ alignItems: 'center' }}>
          <H3s style={{ flex: 1 }}>{event.extra.client}</H3s>
          <Body>{total} lei</Body>
        </Row>
        <Spacer size={2} />
        {event.extra.raw?.clientBoostStatus?.isBoostClient && event.extra?.raw?.hideBoostClientDetails ? (
          <Column style={{ paddingBottom: 6 }}>
            <Label style={{ color: colors.PORTAGE, fontFamily: 'open-sans-semibold' }}>{t('boostClient')}</Label>
          </Column>
        ) : event.extra.raw?.user?.phone &&
          pageState.type === 'Loaded' &&
          pageState.page.permissions.clients.canViewClientDetails() ? (
          <>
            <Body>{event.extra.raw?.user?.phone}</Body>
            <Spacer size={6} />
          </>
        ) : null}
        <SmallBody style={{ color: colors.COMET }}>{interval}</SmallBody>
        <Spacer size={12} />
      </Column>
      <Line />
      <Column style={{ paddingHorizontal: 16 }}>
        <Spacer size={12} />
        {event.extra.raw?.bookedServices.map((service, index) => (
          <Column key={service._id}>
            {index !== 0 && <Spacer size={8} />}
            <Row style={{ alignItems: 'center' }}>
              <Title style={{ flex: 1 }}>{service.name}</Title>
              <Body>{formatPrice(service.price)}</Body>
            </Row>
            <Spacer size={2} />
            <SmallBody style={{ color: colors.COMET }}>{formatDuration(service.durationInMinutes)}</SmallBody>
            {index < (event.extra.raw?.bookedServices.length ?? 1) - 1 && (
              <>
                <Spacer size={8} />
                <Line />
              </>
            )}
          </Column>
        ))}
        <Spacer size={12} />
        {event.extra.note ? (
          <>
            <Line />
            <Spacer size={12} />
            <SmallBody style={{ fontSize: 12 }}>* {event.extra.note}</SmallBody>
            <Spacer size={12} />
          </>
        ) : null}
      </Column>
      {history.length === 0 ? null : (
        <>
          <Line />
          <Column style={{ backgroundColor: '#FAFAFA', paddingHorizontal: 16 }}>
            <Spacer size={16} />
            <SmallBody style={{ color: colors.COMET, fontSize: 12 }}>{generateHistoryText(history[0])}</SmallBody>
            <Spacer size={16} />
          </Column>
        </>
      )}
    </Column>
  );
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 3px 32px rgba(0, 0, 0, 0.16)',
    borderRadius: 12,
    padding: 0,
    fontSize: 11,
    overflow: 'hidden',
    marginLeft: -200,
  },
}));

export type Props = {
  event: NormalizedAppointmentEvent;
  now: CalendarDateTime;
};

const Tooltip: React.FC<React.PropsWithChildren<Props>> = ({ event, now, children }) => {
  const windowWidth = Dimensions.get('window').width;

  const { isPhone } = useMediaQueries();
  const positionRef = React.useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const popperRef = React.useRef<Instance>(null);
  const areaRef = React.useRef<HTMLDivElement>(null);

  const updatePosition = (event: React.MouseEvent) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    popperRef.current?.update();
  };

  return isPhone ? (
    <>{children}</>
  ) : (
    <LightTooltip
      title={
        <>
          <TooltipContent event={event} now={now} />
        </>
      }
      placement="right"
      followCursor
      style={{ backgroundColor: colors.WHITE, height: '100%' }}
      enterDelay={0}
      PopperProps={{
        popperRef,
        anchorEl: {
          getBoundingClientRect: () => {
            const clientHeight = areaRef.current?.clientHeight ?? 0;

            if (positionRef.current.x + TOOLTIP_WIDTH + SPACER_SIZE > windowWidth) {
              return new DOMRect(
                positionRef.current.x - TOOLTIP_WIDTH - 2 * SPACER_SIZE,
                (areaRef.current?.getBoundingClientRect().y ?? 0) + clientHeight / 2,
                0,
                0,
              );
            }

            return new DOMRect(
              positionRef.current.x + SPACER_SIZE,
              (areaRef.current?.getBoundingClientRect().y ?? 0) + clientHeight / 2,
              0,
              0,
            );
          },
        },
      }}
    >
      <div ref={areaRef} onMouseEnter={updatePosition} style={{ height: '100%' }}>
        {children}
      </div>
    </LightTooltip>
  );
};

export default Tooltip;
