export const promiseDebounce = (() => {
  const pending: Record<string, number> = {};

  /**
   * @param name promise identifier, will debounce based on this ID
   * @param debounce delay in milliseconds
   * @param immediate will run function immediately if no other promise is pending
   */
  return <R>(name: string, fn: (...args: any) => Promise<R>, delay: number, immediate = false) =>
    new Promise<R>((resolve, reject) => {
      if (immediate && !pending[name]) {
        // no other promise is pending, call now
        pending[name] = window.setTimeout(() => {
          // noop, function executed immediately
          delete pending[name];
        }, delay);

        fn().then(resolve).catch(reject);
        return;
      }

      if (pending[name]) {
        clearTimeout(pending[name]);
      }

      pending[name] = window.setTimeout(() => {
        fn().then(resolve).catch(reject);
        delete pending[name];
      }, delay);
    });
})();

export const nullish = () => null;
