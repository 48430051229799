import { Authorized, AuthorizedProps } from '@/contexts/AuthContext';
import { CalendarContext } from '@/contexts/CalendarContext';
import { CurrentBusiness, CurrentBusinessProps } from '@/contexts/CurrentBusiness';
import { NavigationContext } from '@/contexts/NavigationContext';
import { HomeDrawerParamsList, AuthorizedStackParamList, RootStackParamList } from '@/types';
import { isSome } from '@mero/api-sdk';
import { SavedWorker } from '@mero/api-sdk/dist/workers';
import {
  Column,
  Icon,
  Row,
  Spacer,
  Text,
  Line,
  SimpleListItem,
  colors,
  SafeAreaView,
  H3s,
  Checkbox,
  Body,
  Switch,
  sizes,
  styles as meroStyles,
  Title,
} from '@mero/components';
import { Option } from '@mero/shared-sdk/dist/common';
import * as A from 'fp-ts/lib/Array';
import * as O from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, ScrollView, ScrollViewProps, TouchableOpacity } from 'react-native';

import { MODES, ViewMode } from '@/components/Calendar';

import { DrawerNavigationProp, useDrawerStatus } from '@react-navigation/drawer';
import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { CompositeNavigationProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { styles } from './styles';

type WorkerItemProps = {
  worker: SavedWorker;
  onPress: (worker: SavedWorker) => void;
  checked: boolean;
  disabled?: boolean;
  disabledText?: string;
};

const WorkerItem: React.FC<WorkerItemProps> = React.memo(function WorkerItemComponent({
  worker,
  onPress,
  checked,
  disabled,
}: WorkerItemProps) {
  return (
    <TouchableOpacity onPress={() => onPress(worker)} disabled={disabled}>
      <SimpleListItem
        title={`${worker.user.firstname ?? ''} ${worker.user.lastname ?? ''}`}
        // subtitle={disabled ? (disabledText ? disabledText : t('expired')) : activeHours}
        icon={checked && !disabled ? 'checked' : 'unchecked'}
        iconColor={disabled ? colors.ATHENS_GRAY : colors.DARK_BLUE}
        iconPosition="left"
        alignContent="middle"
        image={{
          firstname: worker.user.firstname,
          lastname: worker.user.lastname,
          src: worker.profilePhoto?.medium,
        }}
      />
    </TouchableOpacity>
  );
});

type Props = CurrentBusinessProps &
  AuthorizedProps & {
    drawerNavigation: DrawerNavigationHelpers;
    navigation: CompositeNavigationProp<
      DrawerNavigationProp<HomeDrawerParamsList, 'HomeTabs'>,
      CompositeNavigationProp<
        StackNavigationProp<AuthorizedStackParamList, 'Home'>,
        StackNavigationProp<RootStackParamList, 'Authorized'>
      >
    >;
  };

const CalendarFilterScreenComponent: React.FC<Props> = ({ drawerNavigation, page, authorization }: Props) => {
  const isDrawerOpen = useDrawerStatus() === 'open';

  const userId = authorization.user._id;
  const team = React.useMemo(() => {
    return pipe(
      page.workers,
      A.sort({
        compare: (a: SavedWorker) => (a.user._id === userId ? -1 : 1),
        equals: (a: SavedWorker, b: SavedWorker) => a._id === b._id,
      }),
    );
  }, [page.workers, userId]);

  const [scrollPosition, setScrollPosition] = React.useState(0);

  const handleScroll: ScrollViewProps['onScroll'] = (event) => {
    setScrollPosition(event.nativeEvent.contentOffset.y);
  };

  const [
    {
      slices,
      selectedCalendars,
      calendarsOrder,
      activeOnly,
      showOnlyWorkingHours,
      includeDeleted,
      hasFinishedCheckoutTransactions,
      period,
    },
    {
      setMultipleCalendars,
      addSelectedCalendar,
      removeSelectedCalendar,
      toggleIncludeDeleted,
      toggleHasFinishedCheckoutTransactions,
      toggleOnlyWorkingHours,
      setActiveOnly,
    },
  ] = CalendarContext.useContext();

  const [{ previousPath }] = NavigationContext.useContext();
  const isAllSelected = team.length === selectedCalendars.length;

  const { t } = useTranslation('calendar');

  const onClose = React.useCallback(() => {
    if (Platform.OS === 'web') {
      history.pushState({}, '', previousPath);
    }

    drawerNavigation.closeDrawer();
  }, [drawerNavigation]);

  /**
   * This is set to true when users deselects all workers, event default worker will be selected -
   * won't show any selection in UI
   * Next time when a worker is selected, this will be set to false
   */
  const [isNoneSelected, setIsNoneSelected] = React.useState(false);

  const workerSelectedCallback = React.useCallback(
    (worker: SavedWorker) => {
      if (!selectedCalendars.includes(worker.calendar._id) || activeOnly) {
        if (isNoneSelected || activeOnly) {
          setMultipleCalendars({
            calendarIds: [worker.calendar._id],
            calendarSettings: worker.calendar.settings,
            activeOnly: false,
          });
        } else {
          addSelectedCalendar({
            calendarId: worker.calendar._id,
            calendarSettings: worker.calendar.settings,
          });
        }
      } else {
        removeSelectedCalendar(worker.calendar._id);
      }

      setIsNoneSelected(false);
    },
    [
      selectedCalendars,
      isNoneSelected,
      setMultipleCalendars,
      addSelectedCalendar,
      removeSelectedCalendar,
      setIsNoneSelected,
    ],
  );

  const toggleSelectAllCallback = React.useCallback(() => {
    const authWorker = pipe(
      team,
      A.findFirst((w) => w.user._id === authorization.user._id && !w.ownPageExpired),
      O.getOrElseW(() => undefined),
    );
    const firstWorker = pipe(
      team,
      A.head,
      O.getOrElseW(() => undefined),
    );
    const worker = authWorker ?? firstWorker;

    if (worker) {
      if (isAllSelected && !activeOnly) {
        setIsNoneSelected(true);
        setMultipleCalendars({
          calendarIds: [worker.calendar._id],
          calendarSettings: worker.calendar.settings,
        });
      } else {
        setIsNoneSelected(false);
        setMultipleCalendars({
          calendarIds: pipe(
            team,
            A.filter((w) => !w.ownPageExpired),
            A.map((w) => w.calendar._id),
          ),
          calendarSettings: worker.calendar.settings,
          period: 'day',
          activeOnly: false,
        });
      }
    }
  }, [isAllSelected, authorization, team, selectedCalendars, setIsNoneSelected, setMultipleCalendars]);

  const toggleActiveOnlyCallback = React.useCallback(() => {
    if (!activeOnly) {
      const authWorker = pipe(
        team,
        A.findFirst((w) => w.user._id === authorization.user._id),
        O.getOrElseW(() => undefined),
      );
      const firstWorker = pipe(
        team,
        A.head,
        O.getOrElseW(() => undefined),
      );
      const worker = authWorker ?? firstWorker;

      if (worker) {
        setIsNoneSelected(false);
        setMultipleCalendars({
          calendarIds: pipe(
            team,
            A.filter((w) => !w.ownPageExpired),
            A.map((w) => w.calendar._id),
          ),
          calendarSettings: worker.calendar.settings,
          period: 'day',
          activeOnly: true,
        });
      }
    } else {
      setActiveOnly(false);
    }
  }, [activeOnly, team]);

  const toggleDeletedCallback = React.useCallback(() => {
    toggleIncludeDeleted();
  }, []);
  const toggleHasFinishedCheckoutTransactionsCallback = React.useCallback(toggleHasFinishedCheckoutTransactions, []);

  const toggleOnlyWorkingHoursCallback = React.useCallback(() => {
    toggleOnlyWorkingHours();
  }, []);

  React.useEffect(() => {
    if (isDrawerOpen) {
      setIsNoneSelected(false);
    }
  }, [isDrawerOpen]);

  const activeOnlyPros = React.useMemo(() => {
    if (activeOnly) {
      return Object.values(slices)
        .filter((s) => s?.activeDailyBounds)
        .map((s) => s?.worker._id);
    }

    return [];
  }, [activeOnly, slices]);

  const selectedCalendarsProcessed = React.useMemo(() => {
    if (activeOnly) {
      return selectedCalendars
        .filter((c) => slices[c]?.activeDailyBounds)
        .sort((a, b) => calendarsOrder.indexOf(a) - calendarsOrder.indexOf(b));
    }

    return selectedCalendars.sort((a, b) => calendarsOrder.indexOf(a) - calendarsOrder.indexOf(b));
  }, [activeOnly, selectedCalendars, slices, calendarsOrder]);

  const setMode = (mode: ViewMode['value']): void => {
    const firstWorker: Option<SavedWorker> = team[0];

    if (mode === 'week') {
      setMultipleCalendars({
        calendarIds:
          selectedCalendarsProcessed.length !== 1 && isSome(firstWorker)
            ? [firstWorker.calendar._id]
            : selectedCalendarsProcessed,
        calendarSettings: firstWorker.calendar.settings,
        period: mode,
        activeOnly: false,
      });
    } else if (mode === 'day') {
      setMultipleCalendars({
        calendarIds: selectedCalendarsProcessed,
        calendarSettings: firstWorker.calendar.settings,
        period: 'day',
        activeOnly: false,
      });
    }
  };

  if (!isDrawerOpen) {
    // avoid rendering drawer if not open
    return null;
  }

  return (
    <SafeAreaView style={styles.container}>
      <Row
        justifyContent="space-between"
        style={[
          { width: '100%', paddingTop: 22, paddingBottom: 16 },
          scrollPosition > 0 ? styles.withShadow : undefined,
        ]}
      >
        <Column style={styles.margin}>
          <Text h1 fontFamily="merriweather-bold">
            {t('filters')}
          </Text>
        </Column>

        <Column alignItems="flex-start" style={[{ marginRight: 20 }]}>
          <TouchableOpacity onPress={onClose}>
            <Icon type="close" disabled={true} />
          </TouchableOpacity>
        </Column>
      </Row>
      <ScrollView onScroll={handleScroll} scrollEventThrottle={32} bounces={false}>
        <Column style={styles.margin}>
          <Title>{t('viewMode')}</Title>
          <Spacer size="12" />
          <Switch
            height={sizes[32]}
            textProps={[meroStyles.text.semibold, { fontSize: 13 }]}
            buttons={MODES.map((tab) => ({ ...tab, label: t(tab.label) }))}
            defaultValue={period.type}
            onChange={(v) => setMode(v as 'day' | 'week')}
          />
          <Spacer size="32" />
        </Column>
        {page.permissions.bookings.canReadAllBookings() && team.length > 1 && (
          <>
            <Column style={styles.margin}>
              <H3s>{t('selectPro')}</H3s>
            </Column>
            <Column style={styles.margin}>
              {team.length > 1 && (
                <>
                  <TouchableOpacity onPress={toggleSelectAllCallback}>
                    <SimpleListItem
                      title={t('allPros')}
                      subtitle={page.details.location?.address ?? page.details.name}
                      icon={isAllSelected && !activeOnly ? 'checked' : 'unchecked'}
                      iconColor={colors.DARK_BLUE}
                      iconPosition="left"
                      alignContent="middle"
                    />
                  </TouchableOpacity>
                  <Line />
                  <TouchableOpacity onPress={toggleActiveOnlyCallback}>
                    <SimpleListItem
                      title={t('activePros')}
                      icon={activeOnly ? 'checked' : 'unchecked'}
                      iconColor={colors.DARK_BLUE}
                      iconPosition="left"
                      alignContent="middle"
                    />
                  </TouchableOpacity>
                  <Line />
                </>
              )}

              {team.map((item, index) => (
                <React.Fragment key={item._id}>
                  <WorkerItem
                    worker={item}
                    onPress={workerSelectedCallback}
                    checked={
                      activeOnly
                        ? activeOnlyPros.includes(item._id)
                        : selectedCalendars.includes(item.calendar._id) && !isNoneSelected
                    }
                    disabled={item.ownPageExpired}
                  />
                  {index !== team.length - 1 && <Line />}
                </React.Fragment>
              ))}
            </Column>
            <Spacer size="xl" />
          </>
        )}
        <Column style={styles.margin}>
          <H3s>{t('displayAppointments')}</H3s>
          <Spacer size="24" />
          <TouchableOpacity style={{ flexDirection: 'row' }} onPress={toggleDeletedCallback}>
            <Checkbox color="blue" disabled={false} value={includeDeleted} onValueChange={toggleDeletedCallback} />
            <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12, flex: 1 }}>{t('includeDeleted')}</Body>
          </TouchableOpacity>
          {page.permissions.checkout.hasAny() && (
            <>
              <Spacer size="16" />
              <Line />
              <Spacer size="16" />
              <TouchableOpacity
                style={{ flexDirection: 'row' }}
                onPress={toggleHasFinishedCheckoutTransactionsCallback}
              >
                <Checkbox
                  color="blue"
                  disabled={false}
                  value={!hasFinishedCheckoutTransactions}
                  onValueChange={toggleHasFinishedCheckoutTransactionsCallback}
                />
                <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12, flex: 1 }}>
                  {t('hasFinishedCheckoutTransactions')}
                </Body>
              </TouchableOpacity>
            </>
          )}
        </Column>
        <Spacer size="xl" />
        <Column style={styles.margin}>
          <H3s>{t('displaySchedule')}</H3s>
          <Spacer size="24" />
          <TouchableOpacity style={{ flexDirection: 'row' }} onPress={toggleOnlyWorkingHoursCallback}>
            <Checkbox
              color="blue"
              disabled={false}
              value={!showOnlyWorkingHours}
              onValueChange={toggleOnlyWorkingHoursCallback}
            />
            <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12, flex: 1 }}>{t('completeSchedule')}</Body>
          </TouchableOpacity>
          <Spacer size="16" />
          <Line />
          <Spacer size="16" />
          <TouchableOpacity style={{ flexDirection: 'row' }} onPress={toggleOnlyWorkingHoursCallback}>
            <Checkbox
              color="blue"
              disabled={false}
              value={showOnlyWorkingHours}
              onValueChange={toggleOnlyWorkingHoursCallback}
            />
            <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12, flex: 1 }}>{t('workingHours')}</Body>
          </TouchableOpacity>
        </Column>
        <Spacer size="xl" />
      </ScrollView>
    </SafeAreaView>
  );
};

export default pipe(CalendarFilterScreenComponent, CurrentBusiness, Authorized);
