import { DateString, LocalDate } from '@mero/api-sdk';
import { capitalize } from '@mero/shared-sdk/dist/common';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ro';

import { CalendarDateTime } from '@/components/Calendar/calendarDateTime';

import { LocalDateObject } from '../../../contexts/CalendarContext';
import { getWorkIntervals } from '../../../utils/time';
import { NormalizedEvent } from '../NormalizedEvent';
import { OVERLAP_PADDING } from './styles';
import { DayTime, Mode, WorkerSchedule } from './types';

export const calculateIntervalMinutes = (startHour: number, endHour: number) => endHour * 60 - startHour * 60;

export const DAY_MINUTES = calculateIntervalMinutes(0, 24);

export const getDatesInWeek = (date: LocalDate): LocalDate[] => {
  const monday = date.startOf('week');

  const dates = Array(7)
    .fill(0)
    .map((_, day) => {
      return monday.plus({ days: day });
    });

  return dates;
};

export function getDatesInNextOneDay(date: LocalDate): LocalDate[] {
  const subject = LocalDate.unsafeFromObject(date);

  return Array(1)
    .fill(0)
    .map((_, i) => {
      return subject.plus({ days: i });
    });
}

export const getDayHours = (start: number, end: number): number[] =>
  Array(Math.max(end - start, 0))
    .fill(start)
    .map((v, i) => v + i);

const DIVIDE_HOUR = 4;

export const HOUR_CELLS = Array(DIVIDE_HOUR)
  .fill(1)
  .map((_, index) => {
    return (60 / DIVIDE_HOUR) * index;
  });

type DayCell = DayTime & {
  readonly ts: string;
};
// Precompute all day cells, assuming all days have 24 hours ;)
export const getDayCells = (dayHours: number[]): DayCell[] =>
  dayHours.reduce((acc: DayCell[], hour: number): DayCell[] => {
    return acc.concat(
      ...HOUR_CELLS.map((minute): DayCell => {
        return { hour, minute, ts: `${hour}:${minute}` };
      }),
    );
  }, []);

export const DAY_HOURS_BUFFER = 2;

export function formatHour(hour: number, ampm = false): string {
  if (ampm) {
    if (hour === 0) {
      return '';
    }
    if (hour === 12) {
      return `12 PM`;
    }
    if (hour > 12) {
      return `${hour - 12} PM`;
    }
    return `${hour} AM`;
  }
  return `${hour}:00`;
}

export function getRelativeTopInDay(date: { hour: number; minute: number }): number {
  return (100 * (date.hour * 60 + date.minute)) / DAY_MINUTES;
}

export function modeToNum(mode: Mode): number {
  switch (mode) {
    case 'week':
      return 7;
    case 'day':
      return 1;
    default:
      throw new Error('undefined mode');
  }
}

/**
 * Formats NormalizedEvent entry start/end time to "HH:mm - HH:mm" format
 */
export function formatStartEnd(start: CalendarDateTime, end: CalendarDateTime): string {
  return `${start.toFormat('HH:mm')} - ${end.toFormat('HH:mm')}`;
}

export function isAllDayEvent(event: Pick<NormalizedEvent, 'start' | 'end'>): boolean {
  return event.start.hour === 0 && event.start.minute === 0 && event.end.hour === 0 && event.end.minute === 0;
}

export function getStyleForOverlappingEvent({
  eventCount,
  eventOrder,
  isClose,
  overlapOffset,
}: {
  eventCount: number;
  eventOrder: number;
  isClose: boolean;
  overlapOffset: number;
}) {
  let overlapStyle = {};
  if (eventCount > 1) {
    const offset = overlapOffset;
    const start = eventOrder * offset + (isClose ? 20 : 0) * eventOrder;
    const zIndex = 100 + eventOrder;
    overlapStyle = {
      start: start + OVERLAP_PADDING,
      end: OVERLAP_PADDING + 8,
      zIndex,
    };
  }
  return overlapStyle;
}

export const getWeekDays = (locale = 'ro'): string[] => {
  //@FIXME On Android it will default to first day of the week as Sunday
  return ['Luni', 'Marți', 'Miercuri', 'Joi', 'Vineri', 'Sâmbătă', 'Duminică'];
};

export const isAfterOrTheSame = (firstDay: Date | Dayjs, secondDay: Date | Dayjs = new Date()): boolean => {
  const dayOne = dayjs(firstDay).hour(0).minute(0);
  const dayTwo = dayjs(secondDay).hour(0).minute(0);
  return dayOne.isAfter(dayTwo, 'day') || dayOne.format('YYYY-MM-DD') === dayTwo.format('YYYY-MM-DD');
};

const capitalizeEach = (str: string) =>
  str
    .split(' ')
    .map((w) => capitalize(w))
    .join(' ');

export const formatReadableDate = (date: LocalDateObject, locale = 'ro'): string => {
  const day = CalendarDateTime.fromObject(date, { zone: 'UTC', locale: locale });
  return capitalizeEach(day.toFormat('ccc dd MMM'));
};

export const formatReadableWeek = (date: LocalDateObject, locale = 'ro'): string => {
  const day = CalendarDateTime.fromObject(date, { zone: 'UTC', locale: locale });
  return `${day.startOf('week').toFormat('dd MMM')} - ${day.endOf('week').toFormat('dd MMM')}`;
};

export const applyLeadingZero = (n: number) => (n < 10 ? `0${n}` : `${n}`);

export const getWorkingHours = (activeHours: WorkerSchedule, currentDate: DateString) => {
  // Is it same weekday for same date in any timezone? :)

  const schedule = getWorkIntervals(activeHours, currentDate);

  if (schedule.length === 0) {
    return 'Nu lucrează';
  }

  return schedule
    .map(
      (s) =>
        `${applyLeadingZero(s.from.hour)}:${applyLeadingZero(s.from.minute)} - ${applyLeadingZero(
          s.to.hour,
        )}:${applyLeadingZero(s.to.minute)}`,
    )
    .join(', ');
};
