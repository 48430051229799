import * as React from 'react';
import { View } from 'react-native';

import HourGuideColumn from '@/components/Calendar/BigCalendar/CalendarBody/HourGuideColumn';
import { styles as commonStyles } from '@/components/Calendar/BigCalendar/styles';

type Props = {
  readonly dayHours: number[];
  readonly ampm?: boolean;
  readonly hourHeight: number;
};

const HourGuide: React.FC<Props> = (props: Props) => {
  return (
    <View style={[commonStyles.hourGuide]}>
      {props.dayHours.map((hour) => (
        <HourGuideColumn key={hour} cellHeight={props.hourHeight} hour={hour} ampm={props.ampm} />
      ))}
    </View>
  );
};

export default React.memo(HourGuide);
