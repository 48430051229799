import { DefinedString, Email, MembershipPurchaseId, PhoneNumber } from '@mero/api-sdk';
import { UserAppointment } from '@mero/api-sdk/dist/calendar';
import { CheckoutTransactionId } from '@mero/api-sdk/dist/checkout/checkoutTransactionId';
import {
  ClientHistoryRecordId,
  ClientId,
  ClientNoteHistoryRecord,
  SavedClient,
  clientPreview,
} from '@mero/api-sdk/dist/clients';
import { PageId } from '@mero/api-sdk/dist/pages';
import { useShowError, useToast } from '@mero/components';
import * as Clipboard from 'expo-clipboard';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { pick } from 'lodash';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useCallback } from 'react';
import { Linking, Platform, Share } from 'react-native';

import { useIsFocused } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import { useEscPressWeb } from '../../../hooks/useEscPressWeb';
import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { AppEventsContext } from '../../../contexts/AppEvents';
import { BookingClientSelectContext } from '../../../contexts/BookingClientSelectContext';
import { CalendarContext } from '../../../contexts/CalendarContext';
import { ClientDeleteContext, withClientDeleteContextProvider } from '../../../contexts/ClientDeleteContext';
import { ClientDetailsContext, ClientDetailsContextProvider } from '../../../contexts/ClientDetailsContext';
import {
  ClientHistoryNoteCreateContext,
  withClientHistoryNoteCreateContextProvider,
} from '../../../contexts/ClientHistoryNoteAddContext';
import {
  ClientHistoryNoteDeleteContext,
  withClientHistoryNoteDeleteContextProvider,
} from '../../../contexts/ClientHistoryNoteDeleteContext';
import {
  ClientHistoryNoteUpdateContext,
  withClientHistoryNoteUpdateContextProvider,
} from '../../../contexts/ClientHistoryNoteUpdateContext';
import {
  ClientUpdateIsBlockedContext,
  withClientUpdateIsBlockedContextProvider,
} from '../../../contexts/ClientUpdateIsBlockedContext';
import {
  ClientUpdateIsWarnedContext,
  withClientUpdateIsWarnedContextProvider,
} from '../../../contexts/ClientUpdateIsWarnedContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, ClientStackParamList } from '../../../types';
import log from '../../../utils/log';
import ClientDetailsFailedScreenView from './ClientDetailsFailedScreenView';
import ClientDetailsLoadedScreenView from './ClientDetailsLoadedScreenView/ClientDetailsLoadedScreenView';
import ClientDetailsLoadedScreenViewWeb from './ClientDetailsLoadedScreenView/ClientDetailsLoadedScreenViewWeb';
import ClientDetailsLoadingScreenView from './ClientDetailsLoadingScreenView';
import ClientDetailsLoadingScreenWebView from './ClientDetailsLoadingScreenWebView';
import ClientDetailsNotFoundScreenView from './ClientDetailsNotFoundScreenView';

type Props = CurrentBusinessProps &
  StackScreenProps<ClientStackParamList & AuthorizedStackParamList, 'DetailsScreen'> & {
    readonly clientId: ClientId;
    readonly pageId: PageId;
  };

const ClientDetailsScreen: React.FC<Props> = ({ clientId, pageId, page, navigation }: Props) => {
  const showError = useShowError();
  const toast = useToast();
  const isFocused = useIsFocused();
  const nowJsDate = new Date();
  // Approximated current time, to avoid to often rerenders
  const aboutNow = React.useMemo(() => DateTime.fromJSDate(nowJsDate), [Math.floor(nowJsDate.getTime() / 60000)]);
  const { isDesktop } = useMediaQueries();

  const [, { pushEvent, subscribe: subscribeAppEvents }] = AppEventsContext.useContext();
  const [
    state,
    {
      reload: reloadClientDetails,
      reloadMemberships,
      reloadNotes,
      reloadAppointments,
      reloadClientProfile,
      loadClientProducts,
    },
  ] = ClientDetailsContext.useContext();
  const [clientDeleteState, { deleteClient, tryReset: tryResetDeleteClient }] = ClientDeleteContext.useContext();
  const [clientUpdateIsBlockedState, { updateClientIsBlocked, tryReset: tryResetUpdateClientIsBlocked }] =
    ClientUpdateIsBlockedContext.useContext();
  const [clientUpdateIsWarnedState, { updateClientIsWarned, tryReset: tryResetUpdateClientIsWarned }] =
    ClientUpdateIsWarnedContext.useContext();

  const [clientNoteCreateState, { createClientNote, tryReset: tryResetClientNoteCreate }] =
    ClientHistoryNoteCreateContext.useContext();
  const [clientNoteDeleteState, { deleteClientNote, tryReset: tryResetClientNoteDelete }] =
    ClientHistoryNoteDeleteContext.useContext();
  const [clientNoteUpdateState, { updateClientNote, tryReset: tryResetClientNoteUpdate }] =
    ClientHistoryNoteUpdateContext.useContext();

  const [calendarState] = CalendarContext.useContext();

  const [, { set: setNewBookingClient }] = BookingClientSelectContext.useContext();

  const clientDeleteInProgress = clientDeleteState.type === 'Deleting';
  const clientUpdateIsBlockedInProgress = clientUpdateIsBlockedState.type === 'Updating';
  const clientUpdateIsWarnedInProgress = clientUpdateIsWarnedState.type === 'Updating';
  const clientNoteDeleteInProgress = clientNoteDeleteState.type === 'Deleted';

  const inProgress =
    clientDeleteInProgress ||
    clientUpdateIsBlockedInProgress ||
    clientUpdateIsWarnedInProgress ||
    clientNoteDeleteInProgress;

  const goBack = useGoBack();
  useEscPressWeb({
    onPress: goBack,
  });

  const reload = React.useCallback(() => {
    if (state.type === 'Loaded') {
      reloadClientDetails(state.clientId, pageId);
    }
  }, [state, reloadClientDetails]);

  // ClientDeleteContext state changes effect
  React.useEffect(() => {
    if (clientDeleteState.type === 'Deleted') {
      tryResetDeleteClient();
      pushEvent({
        type: 'PageClientDeleted',
        pageId: pageId,
        clientId: clientDeleteState.clientId,
      });
      goBack();
    } else if (clientDeleteState.type === 'Failed') {
      showError(clientDeleteState.error);
      tryResetDeleteClient();
    }
  }, [clientDeleteState, pageId, goBack, pushEvent, tryResetDeleteClient, showError]);

  // ClientUpdateIsBlockedContext state changes effect
  React.useEffect(() => {
    if (clientUpdateIsBlockedState.type === 'Updated') {
      tryResetUpdateClientIsBlocked();

      pushEvent({
        type: 'PageClientUpdated',
        pageId: pageId,
        clientId: clientUpdateIsBlockedState.clientId,
      });

      if (clientUpdateIsBlockedState.isBlocked) {
        toast.show({
          type: 'success',
          text: 'Clientul a fost blocat',
        });
      } else {
        toast.show({
          type: 'success',
          text: 'Clientul a fost deblocat',
        });
      }
      reloadClientProfile(clientId);
    } else if (clientUpdateIsBlockedState.type === 'Failed') {
      showError(clientUpdateIsBlockedState.error);
      tryResetUpdateClientIsBlocked();
    }
  }, [
    clientUpdateIsBlockedState,
    pageId,
    reloadClientProfile,
    pushEvent,
    tryResetUpdateClientIsBlocked,
    showError,
    toast,
  ]);

  // ClientUpdateIsWarnedContext state changes effect
  React.useEffect(() => {
    if (clientUpdateIsWarnedState.type === 'Updated') {
      tryResetUpdateClientIsWarned();
      pushEvent({
        type: 'PageClientUpdated',
        pageId: pageId,
        clientId: clientUpdateIsWarnedState.clientId,
      });

      if (clientUpdateIsWarnedState.isWarned) {
        toast.show({
          type: 'success',
          text: 'Clientul a fost marcat ca Avertizat',
        });
      } else {
        toast.show({
          type: 'success',
          text: 'Avertizarea clientului a fost ștearsă',
        });
      }
      reloadClientProfile(clientId);
    } else if (clientUpdateIsWarnedState.type === 'Failed') {
      showError(clientUpdateIsWarnedState.error);
      tryResetUpdateClientIsWarned();
    }
  }, [clientUpdateIsWarnedState, pageId, pushEvent, tryResetUpdateClientIsWarned, showError]);

  // ClientNoteCreateContext state changes effect
  React.useEffect(() => {
    if (clientNoteCreateState.type === 'Created') {
      tryResetClientNoteCreate();
      toast.show({
        type: 'success',
        text: 'Notița a fost adăugată',
      });
      reloadNotes();
    } else if (clientNoteCreateState.type === 'Failed') {
      tryResetClientNoteCreate();
      showError(clientNoteCreateState.error);
    }
  }, [clientNoteCreateState, toast, reload, tryResetClientNoteCreate, showError]);

  // ClientNoteDeleteContext state changes effect
  React.useEffect(() => {
    if (clientNoteDeleteState.type === 'Deleted') {
      tryResetClientNoteDelete();
      toast.show({
        type: 'success',
        text: 'Notița a fost ștearsă',
      });
      reloadNotes();
    } else if (clientNoteDeleteState.type === 'Failed') {
      tryResetClientNoteDelete();
      showError(clientNoteDeleteState.error);
    }
  }, [clientNoteDeleteState, toast, reload, tryResetClientNoteDelete, showError]);

  // ClientNoteDeleteContext state changes effect
  React.useEffect(() => {
    if (clientNoteUpdateState.type === 'Updated') {
      tryResetClientNoteUpdate();
      toast.show({
        type: 'success',
        text: 'Notița a fost modificată',
      });
      reloadNotes();
    } else if (clientNoteUpdateState.type === 'Failed') {
      tryResetClientNoteUpdate();
      showError(clientNoteUpdateState.error);
    }
  }, [clientNoteUpdateState, toast, reload, tryResetClientNoteUpdate, showError]);

  React.useEffect(() => {
    if (isFocused && state.type === 'Loaded') {
      reloadMemberships(pageId);
    }
  }, [isFocused]);

  // List some app events, ex: PageClientUpdated
  React.useEffect(
    () =>
      subscribeAppEvents((event) => {
        switch (event.type) {
          case 'PageClientUpdated': {
            if (event.pageId === pageId && event.clientId === clientId) {
              reloadClientProfile(clientId);
            }

            return;
          }
          case 'AppointmentCreated': {
            reloadAppointments();
            //TODO: reload user stats to update the number of appointments
            return;
          }
          case 'AppointmentUpdated': {
            reload();
            return;
          }
          case 'AppointmentDeleted': {
            reload();
            return;
          }
        }
      }),
    [clientId, pageId, reload],
  );

  // Navigate to client edit page
  const goEdit = React.useCallback(() => {
    navigation.navigate('ClientEditScreen', { pageId: pageId, clientId: clientId });
  }, [pageId, clientId, navigation]);

  // Navigtate to new booking page
  const goNewBooking = React.useCallback(() => {
    if (state.type !== 'Loaded') {
      log.error(`Unable to navigate to new booking from client details screen: client not loaded`);
      return;
    }

    const selectedWorker = page.workers.find((worker) => calendarState.selectedCalendars.includes(worker.calendar._id));

    // Pre-select client for new booking form
    setNewBookingClient(clientPreview.fromClient(state.client));

    const now = DateTime.now().setZone(selectedWorker?.calendar.settings.timezone ?? 'Europe/Bucharest');
    // Time should be either HH:30 or HH:00
    const eventDate = now.minute > 30 ? now.set({ minute: 0 }).plus({ hours: 1 }) : now.set({ minute: 30 });

    navigation.navigate('Booking', {
      screen: 'BookingCreateScreen',
      params: {
        workerId: selectedWorker?._id,
        date: eventDate.toISO(),
      },
    });
  }, [navigation, state, calendarState.selectedCalendars, page.workers, setNewBookingClient]);

  const goNewMembership = React.useCallback(() => {
    if (state.type !== 'Loaded') {
      log.error(`Unable to navigate to new membership from client details screen: client not loaded`);
      return;
    }

    navigation.navigate('CombineCheckout', {
      screen: 'CheckoutStack',
      params: {
        screen: 'AddProceedScreen',
        params: {
          type: 'Membership',
          clientId: clientId,
        },
      },
    });
  }, [state]);

  // Proceed with client deletion
  const deleteClientCallback = React.useCallback(
    (client: SavedClient) => {
      if (clientDeleteState.type === 'Ready') {
        deleteClient(client._id);
        toast.show({
          type: 'success',
          text: 'Clientul a fost șters',
        });
      }
    },
    [clientDeleteState, deleteClient],
  );

  // Proceed with client blocking/unblocking
  const updateClientIsBlockedCallback = React.useCallback(
    (client: SavedClient, isBlocked: boolean) => {
      if (clientUpdateIsBlockedState.type === 'Ready') {
        updateClientIsBlocked({
          clientId: client._id,
          isBlocked: isBlocked,
        });
      }
    },
    [clientUpdateIsBlockedState, updateClientIsBlocked],
  );

  // Proceed with client warning/unwarning
  const updateClientIsWarnedCallback = React.useCallback(
    (client: SavedClient, isWarned: boolean) => {
      if (clientUpdateIsWarnedState.type === 'Ready') {
        updateClientIsWarned({
          clientId: client._id,
          isWarned: isWarned,
        });
      }
    },
    [clientUpdateIsWarnedState, updateClientIsWarned],
  );

  // Trigger phone call intent
  const callPhoneCallback = React.useCallback(
    (phone: PhoneNumber) => {
      Linking.openURL(`tel:${phone}`);
    },
    [Linking],
  );

  // Copy phone number to clipboard
  const copyToClipboardCallback = React.useCallback(
    (text: PhoneNumber | Email) => {
      Clipboard.setStringAsync(text).catch(log.error);
      toast.show({
        type: 'success',
        text: `${text} a fost copiat`,
      });
    },
    [Clipboard, toast],
  );

  // Start SMS compose to given phone number
  const sendSmsCallback = React.useCallback(
    (phone: PhoneNumber) => {
      Linking.openURL(`sms:${phone}`);
    },
    [Linking],
  );

  const handleSendEmail = (email: Email) => {
    const emailUrl = `mailto:${email}`;
    Linking.canOpenURL(emailUrl)
      .then((supported) => {
        if (supported) {
          Linking.openURL(emailUrl);
        } else {
          log.error('Opening email is not supported.');
        }
      })
      .catch((error: any) => {
        log.error('Cannot open email url.', error);
      });
  };

  // Start whatsapp message compose
  const sendWhatsappCallback = React.useCallback(
    (phone: PhoneNumber) => {
      Linking.openURL(`https://api.whatsapp.com/send?phone=${encodeURIComponent(phone)}`);
    },
    [Linking],
  );

  const shareContactCallback = React.useCallback(
    async (text: string) => {
      if (Platform.OS === 'web' && !navigator.share) {
      } else {
        try {
          await Share.share({
            message: text,
          });
        } catch {}
      }
    },
    [Linking],
  );

  // Navigate to appointment details screen
  const openAppointmentDetails = React.useCallback(
    (appointment: UserAppointment) => {
      navigation.navigate('Booking', {
        screen: 'BookingDetailsScreen',
        params: {
          calendarId: appointment.calendarId,
          calendarEntryId: appointment._id,
          occurrenceIndex: appointment.occurrenceIndex?.toString() ?? '0',
        },
      });
    },
    [navigation],
  );

  const handleProductSalePress = React.useCallback(
    (transactionId: CheckoutTransactionId) => {
      navigation.navigate('CombineCheckout', {
        screen: 'CheckoutStack',
        params: {
          screen: 'ProceedDetailsScreen',
          params: { checkoutTransactionId: transactionId, backMode: 'one' },
        },
      });
    },
    [navigation],
  );

  // TODO: revisit this and check why the membership details does not open as a modal over the client profile
  const handleMembershipPress = useCallback(
    (pageId: PageId, clientId: ClientId, membershipId: MembershipPurchaseId) => {
      navigation.navigate('Booking', {
        screen: 'MembershipDetailsScreen',
        params: {
          pageId,
          clientId,
          membershipPurchaseId: membershipId,
        },
      });
      // navigation.push('MembershipDetailsScreen', {
      //   pageId,
      //   clientId,
      //   membershipPurchaseId: membershipId,
      // });
    },
    [],
  );

  // Proceed with history note deletion
  const deleteNoteCallback = React.useCallback(
    (noteId: ClientHistoryRecordId) => {
      if (clientNoteDeleteState.type === 'Ready') {
        deleteClientNote({
          clientId,
          noteId,
        });
      }
    },
    [clientNoteDeleteState, clientId, deleteClientNote],
  );

  // Proceed with history note update
  const updateNoteCallback = React.useCallback(
    (note: ClientNoteHistoryRecord, newText: DefinedString) => {
      if (clientNoteUpdateState.type === 'Ready') {
        updateClientNote({
          clientId: clientId,
          noteId: note._id,
          text: newText,
        });
      }
    },
    [clientNoteUpdateState, clientId],
  );

  // Create new history note
  const addNoteCallback = React.useCallback(
    (client: SavedClient, text: DefinedString) => {
      if (clientNoteCreateState.type === 'Ready') {
        createClientNote({
          clientId: client._id,
          text: text,
        });
      }
    },
    [clientNoteCreateState, createClientNote],
  );

  const handleLoadClientProductsList = () => {
    loadClientProducts(page.details._id, clientId);
  };

  const goClientSalesReport = React.useCallback(() => {
    navigation.navigate('ClientSalesReportScreen', { clientId, pageId });
  }, [navigation, clientId, pageId]);

  switch (state.type) {
    case 'New': {
      return <ClientDetailsLoadingScreenView onBack={goBack} />;
    }
    case 'Loading': {
      return isDesktop ? <ClientDetailsLoadingScreenWebView /> : <ClientDetailsLoadingScreenView onBack={goBack} />;
    }
    case 'Loaded': {
      if (isDesktop) {
        return (
          <ClientDetailsLoadedScreenViewWeb
            now={aboutNow}
            pageId={pageId}
            onClientProductsSalesLoad={handleLoadClientProductsList}
            handleProductSalePress={handleProductSalePress}
            onHistoryReload={() => reloadNotes()}
            clientId={clientId}
            client={state.client}
            reports={state.reports}
            history={state.history}
            totalAppointments={pick(state.appointments, 'pastBookingsCount', 'futureBookingsCount')}
            appointments={state.appointments.data}
            memberships={state.memberships.data}
            productSales={state.productSales?.data || []}
            onBack={goBack}
            onViewSalesReport={goClientSalesReport}
            onNewBooking={goNewBooking}
            onNewMembership={goNewMembership}
            onEdit={goEdit}
            onCopyToClipboard={copyToClipboardCallback}
            onSendWhatsappMessage={sendWhatsappCallback}
            onShare={shareContactCallback}
            onDelete={deleteClientCallback}
            onChangeIsBlocked={updateClientIsBlockedCallback}
            onChangeIsWarned={updateClientIsWarnedCallback}
            onOpenClientAppointment={openAppointmentDetails}
            onDeleteNote={deleteNoteCallback}
            onUpdateNote={updateNoteCallback}
            onAddNote={addNoteCallback}
            onSendEmail={handleSendEmail}
            onMembershipPress={handleMembershipPress}
          />
        );
      }

      return (
        <ClientDetailsLoadedScreenView
          now={aboutNow}
          pageId={pageId}
          clientId={clientId}
          onHistoryReload={() => reloadNotes()}
          client={state.client}
          history={state.history}
          totalAppointments={pick(state.appointments, 'pastBookingsCount', 'futureBookingsCount')}
          appointments={state.appointments.data}
          memberships={state.memberships.data}
          onBack={goBack}
          onViewSalesReport={goClientSalesReport}
          onNewBooking={goNewBooking}
          onNewMembership={goNewMembership}
          onEdit={goEdit}
          onCallPhone={callPhoneCallback}
          onCopyToClipboard={copyToClipboardCallback}
          onSendSms={sendSmsCallback}
          onSendWhatsappMessage={sendWhatsappCallback}
          onShare={shareContactCallback}
          onDelete={deleteClientCallback}
          onChangeIsBlocked={updateClientIsBlockedCallback}
          onChangeIsWarned={updateClientIsWarnedCallback}
          onOpenClientAppointment={openAppointmentDetails}
          onDeleteNote={deleteNoteCallback}
          onUpdateNote={updateNoteCallback}
          onAddNote={addNoteCallback}
        />
      );
    }
    case 'NotFound': {
      return <ClientDetailsNotFoundScreenView onBack={goBack} clientId={clientId} />;
    }
    case 'Failed': {
      return <ClientDetailsFailedScreenView onBack={goBack} error={state.error} />;
    }
  }
};

type InitProps = CurrentBusinessProps &
  StackScreenProps<ClientStackParamList & AuthorizedStackParamList, 'DetailsScreen'>;

const ClientDetailsScreenInit: React.FC<InitProps> = ({ navigation, route, page }: InitProps) => {
  const clientId = pipe(
    route.params?.clientId,
    ClientId.decode,
    E.getOrElseW(() => undefined),
  );

  const pageId = pipe(
    route.params?.pageId,
    PageId.decode,
    E.getOrElseW(() => undefined),
  );

  const goBack = useGoBack();
  useEscPressWeb({
    onPress: goBack,
  });

  if (clientId && pageId) {
    return (
      <ClientDetailsContextProvider clientId={clientId} pageId={pageId}>
        <ClientDetailsScreen route={route} navigation={navigation} clientId={clientId} pageId={pageId} page={page} />
      </ClientDetailsContextProvider>
    );
  } else {
    if (!clientId) {
      log.error(`ClientDetailsScreen: Failed to decode ClientId from "${route.params?.clientId}"`);
    }

    if (!pageId) {
      log.error(`ClientDetailsScreen: Failed to decode PageId from "${route.params?.pageId}"`);
    }

    return <ClientDetailsFailedScreenView error={new Error('Link invalid')} onBack={goBack} />;
  }
};

export default pipe(
  ClientDetailsScreenInit,
  withClientHistoryNoteUpdateContextProvider,
  withClientHistoryNoteCreateContextProvider,
  withClientHistoryNoteDeleteContextProvider,
  withClientUpdateIsWarnedContextProvider,
  withClientUpdateIsBlockedContextProvider,
  withClientDeleteContextProvider,
  CurrentBusiness,
);
