import { Platform, StyleSheet } from 'react-native';

export const MIN_HEIGHT = 1200;
export const HOUR_GUIDE_WIDTH = 50;
export const HOUR_GUIDE_WIDTH_SMALL = 38;
export const OVERLAP_OFFSET = Platform.OS === 'web' ? 20 : 8;
export const OVERLAP_PADDING = Platform.OS === 'web' ? 3 : 0;

export const styles = StyleSheet.create({
  dateCellPrimary: {
    borderColor: '#ADADAD',
    borderBottomWidth: 1,
  },
  dateCellSecondary: {
    borderColor: '#E0E0E0',
    borderBottomWidth: 1,
  },
  guideText: {
    color: '#525F7F',
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'open-sans-semibold',
  },
  hourGuideColumn: {
    flexDirection: 'column',
    width: HOUR_GUIDE_WIDTH,
    position: 'relative',
    top: 0,
    left: 0,
    bottom: 0,
  },
  hourGuideColumnSmall: {
    flexDirection: 'column',
    width: HOUR_GUIDE_WIDTH_SMALL,
    position: 'relative',
    top: 0,
    left: 0,
    bottom: 0,
  },
  hourGuideInnerBox: {
    position: 'absolute',
    top: 45,
    bottom: 0,
    left: 0,
    overflow: 'hidden',
  },
  hourGuide: {
    backgroundColor: '#fff',
    zIndex: 100,
    width: HOUR_GUIDE_WIDTH,
  },
  hourGuildeSmall: {
    backgroundColor: '#fff',
    zIndex: 100,
    width: HOUR_GUIDE_WIDTH_SMALL,
  },
  horizontalScrollView: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  hourGuideOffsetElement: {
    width: HOUR_GUIDE_WIDTH,
  },
  hourGuideOffsetElementSmall: {
    // width: HOUR_GUIDE_WIDTH_SMALL,
  },
  splitViewContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  eventCellBox: {
    position: 'absolute',
    zIndex: 100,
    start: 0,
    end: 8,
    minWidth: '33%',
    paddingBottom: 1,
  },
  eventCell: {
    backgroundColor: '#C9E6CA',
    borderRadius: 4,
    padding: 4,
    paddingLeft: 8,
    paddingRight: 8,
    overflow: 'hidden',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 1,
    elevation: 2,
    borderWidth: 1,
  },
  eventCellDesktop: {
    maxWidth: 287,
  },
  smallCell: {
    paddingVertical: 2,
  },
  eventTitle: {
    fontSize: 12,
    lineHeight: 18,
    left: 0,
  },
  eventClient: {
    fontSize: 12,
    lineHeight: 17,
    left: 0,
  },
  eventComment: {
    fontSize: 12,
    lineHeight: 17,
    color: '#7F7F7F',
    left: 0,
  },
  paddingLeft4: {
    paddingLeft: 4,
  },
  full: {
    flex: 1,
  },
  lineHeight17: {
    lineHeight: 17,
  },
});
