import * as React from 'react';
import { Text, View } from 'react-native';

import { styles as commonStyles } from '@/components/Calendar/BigCalendar/styles';
import { formatHour } from '@/components/Calendar/BigCalendar/utils';

type Props = {
  readonly cellHeight: number;
  readonly width?: number;
  readonly hour: number;
  readonly ampm?: boolean;
};

const HourGuideColumn: React.FC<Props> = (props: Props) => {
  return (
    <View style={{ height: props.cellHeight, width: props.width }}>
      <Text style={commonStyles.guideText}>{formatHour(props.hour, props.ampm)}</Text>
    </View>
  );
};

export default React.memo(HourGuideColumn, () => true);
