import { ClientNoteHistoryRecord } from '@mero/api-sdk/dist/clients';
import { Button, colors, Spacer } from '@mero/components';
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, Pressable, Text } from 'react-native';

import { Ref as FloatMenuRef } from '../../../../../../components/FloatMenu';
import { Column, Row, Title } from '../../../../../../components/shared';
import ClientEmptyListView from '../../../components/ClientEmptyListView';

import ClientNoteCard from '../ClientNoteCard';
import styles from './styles';

interface ClientNotesListProps {
  readonly notes: ClientNoteHistoryRecord[];
  readonly onAddNote: () => void;
  readonly onEditNote: (note: ClientNoteHistoryRecord) => void;
  readonly onDeleteNote: (note: ClientNoteHistoryRecord) => void;
}

const ClientNotesList: React.FC<ClientNotesListProps> = ({ notes, onAddNote, onEditNote, onDeleteNote }) => {
  const { t } = useTranslation('clients');
  const notesFloatMenuRef = React.useRef<FloatMenuRef | null>(null);

  const handleEditPress = useCallback((note: ClientNoteHistoryRecord) => {
    notesFloatMenuRef.current?.onDismissMenu();
    onEditNote(note);
  }, []);

  const handleDeletePress = useCallback((note: ClientNoteHistoryRecord) => {
    notesFloatMenuRef.current?.onDismissMenu();
    onDeleteNote(note);
  }, []);

  const renderItem = React.useCallback(({ item }: { item: ClientNoteHistoryRecord }) => {
    return (
      <ClientNoteCard
        notesFloatMenuRef={notesFloatMenuRef}
        onDelete={handleDeletePress}
        onEdit={handleEditPress}
        note={item}
      />
    );
  }, []);

  const keyExtractor = (note: ClientNoteHistoryRecord): string => note._id;

  const listHeaderComponent = useCallback(() => {
    if (!notes.length) {
      return null;
    }

    return (
      <Column>
        <Row justifyContent="space-between" alignItems="center">
          <Text style={styles.contentTitle}>{t('journal')}</Text>

          <Pressable onPress={onAddNote}>
            <Title style={{ color: colors.DARK_BLUE }}>{t('addNote')}</Title>
          </Pressable>
        </Row>
        <Spacer size={16} />
      </Column>
    );
  }, [notes.length]);

  return (
    <FlatList
      style={styles.container}
      contentContainerStyle={styles.contentContainer}
      ListHeaderComponent={listHeaderComponent}
      data={notes}
      keyboardShouldPersistTaps="handled"
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      removeClippedSubviews
      windowSize={11}
      ListEmptyComponent={
        <ClientEmptyListView title="Nu există notițe" subtitle="Notițele clientului vor fi afișate aici">
          <Spacer size="32" />
          <Button
            text="Adaugă notiță"
            onPress={onAddNote}
            size="medium"
            color={colors.DARK_BLUE}
            backgroundColor={colors.SKY_BLUE}
            containerStyle={styles.addNoteButton}
          />
        </ClientEmptyListView>
      }
    />
  );
};

export default ClientNotesList;
