import {
  DateString,
  DefinedString,
  Email,
  MembershipPurchaseId,
  PageId,
  PhoneNumber,
  PurchasedMembership,
} from '@mero/api-sdk';
import { UserAppointment } from '@mero/api-sdk/dist/calendar';
import { CheckoutTransactionId } from '@mero/api-sdk/dist/checkout/checkoutTransactionId';
import {
  ClientHistoryRecord,
  ClientHistoryRecordId,
  ClientId,
  ClientNoteHistoryRecord,
  ClientNoteHistoryType,
  ClientProfile,
  SavedClient,
} from '@mero/api-sdk/dist/clients';
import { PageClientStats } from '@mero/api-sdk/dist/clients/page-client-stats';
import { ProductSale } from '@mero/api-sdk/dist/pro/productSales/productSale';
import { useShowError, useToast } from '@mero/components';
import { MeroUnits } from '@mero/shared-sdk';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, ScrollView, Text, Animated } from 'react-native';

import ClaimDialog from '../../MenuScreen/screens/MarketingSettingsScreen/ClaimDialog';

import { Ref as FloatMenuRef } from '../../../../components/FloatMenu';
import { MeroHeader, Title, Spacer, Button, Row, Column, ListMenu, H1 } from '../../../../components/shared';
import ClientConfirmBlockModal from '../components/ClientConfirmBlockModal';
import ClientConfirmDeleteModal from '../components/ClientConfirmDeleteModal';
import ClientConfirmNoteDeleteModal from '../components/ClientConfirmNoteDeleteModal';
import ClientNoteAddModal from '../components/ClientNoteAddModal';
import ClientNoteEditModal from '../components/ClientNoteEditModal';
import ClientRemarkModal from '../components/ClientRemarkModal';
import ClientViewCommissionModal from '../components/ClientViewCommissionModal';
import ClientBookingsList from './components/ClientBookingsList';
import ClientBoostDetails from './components/ClientBoostDetails';
import ClientDetailsListItem from './components/ClientDetailsListItem';
import ClientHistoryList from './components/ClientHistoryList';
import ClientMembershipsList from './components/ClientMembershipsList';
import ClientNotesList from './components/ClientNotesList';
import ClientOptionsMenu from './components/ClientOptionsMenu';
import ClientProductsList from './components/ClientProductsList';
import ClientProfileInfo from './components/ClientProfileInfo';
import ClientRemarkCard from './components/ClientRemarkCard';
import ClientStats from './components/ClientStats';

import * as icons from '../../../../assets/icons';
import { meroApi } from '../../../../contexts/AuthContext';
import { ClientDetailsContext } from '../../../../contexts/ClientDetailsContext';
import { sleep } from '../../../../utils/promise';
import { formattedBirthday } from '../../../../utils/text';
import { DEFAULT_TIMEZONE } from '../../../../utils/time';
import styles from './styles.web';

export enum MenuItemKey {
  BOOKINGS = 'bookings',
  SUBSCRIPTIONS = 'subscriptions',
  REVIEWS = 'reviews',
  GALLERY = 'gallery',
  JOURNAL = 'journal',
  HISTORY = 'history',
  PRODUCTS = 'products',
}

export interface MenuItem {
  icon: keyof typeof icons;
  value: string;
  key: MenuItemKey;
}

interface Props {
  readonly now: DateTime;
  readonly pageId: PageId;
  readonly clientId: ClientId;
  client: ClientProfile;
  readonly history: ClientHistoryRecord[];
  readonly totalAppointments: {
    readonly futureBookingsCount: number;
    readonly pastBookingsCount: number;
  };
  readonly reports: PageClientStats<'RON'>;
  readonly appointments: UserAppointment[];
  readonly productSales: ProductSale[];
  readonly memberships: PurchasedMembership<MeroUnits.Any>[];
  readonly onBack: () => void;
  readonly onViewSalesReport?: () => void;
  readonly onNewMembership: () => void;
  readonly onNewBooking?: () => void;
  readonly onEdit: () => void;
  readonly onCopyToClipboard: (text: PhoneNumber | Email) => void;
  readonly onSendWhatsappMessage: (phone: PhoneNumber) => void;
  readonly onShare: (text: string) => void;
  readonly onSendEmail: (email: Email) => void;
  readonly onDelete: (client: SavedClient) => void;
  readonly onHistoryReload: () => void;
  readonly onClientProductsSalesLoad: () => void;
  readonly onChangeIsBlocked: (client: SavedClient, isBlocked: boolean) => void;
  readonly onChangeIsWarned: (client: SavedClient, isWarned: boolean) => void;
  readonly onOpenClientAppointment: (appointment: UserAppointment) => void;
  readonly onDeleteNote: (noteId: ClientHistoryRecordId) => void;
  readonly onUpdateNote: (note: ClientNoteHistoryRecord, newText: DefinedString) => void;
  readonly onAddNote: (client: SavedClient, text: DefinedString) => void;
  readonly onMembershipPress: (pageId: PageId, clientId: ClientId, membershipId: MembershipPurchaseId) => void;
  readonly handleProductSalePress: (transactionId: CheckoutTransactionId) => void;
}

const ClientDetailsLoadedScreenViewWeb: React.FC<Props> = ({
  now,
  client,
  clientId,
  pageId,
  history: fullHistory,
  totalAppointments,
  appointments,
  memberships,
  onBack,
  onNewBooking,
  onNewMembership,
  onEdit,
  onCopyToClipboard,
  onSendWhatsappMessage,
  onDelete,
  onChangeIsBlocked,
  onChangeIsWarned,
  onOpenClientAppointment,
  onDeleteNote,
  onUpdateNote,
  onMembershipPress,
  onSendEmail,
  onAddNote,
  onHistoryReload,
  onClientProductsSalesLoad,
  handleProductSalePress,
  productSales,
  reports,
}: Props) => {
  const { firstname, lastname } = client.user;
  const { t } = useTranslation('clients');
  const showError = useShowError();
  const toast = useToast();
  const [activeMenuItem, setActiveMenuItem] = useState<MenuItemKey>(MenuItemKey.BOOKINGS);
  const [, { loadMoreAppointments, loadMoreMemberships, reloadClientProfile, loadMoreProductSales }] =
    ClientDetailsContext.useContext();
  const floatMenuStartRef = React.useRef<FloatMenuRef | null>(null);
  const remarkFloatMenuRef = React.useRef<FloatMenuRef | null>(null);
  const fullName = React.useMemo(
    () => `${firstname ?? ''} ${lastname ?? ''}`.trim() || 'Fără nume',
    [firstname, lastname],
  );

  const [showViewCommissionModal, setShowViewCommissionModal] = React.useState(false);
  const [showClientDeleteConfirmModal, setShowClientDeleteConfirmModal] = React.useState(false);
  const [showClientBlockConfirmModal, setShowClientBlockConfirmModal] = React.useState(false);
  const [showClientNoteDeleteConfirmModal, setShowClientNoteDeleteConfirmModal] = React.useState<
    ClientHistoryRecordId | undefined
  >(undefined);
  const [showClientNoteEditModal, setShowClientNoteEditModal] = React.useState<ClientNoteHistoryRecord | undefined>(
    undefined,
  );

  const [showClientNoteAddModal, setShowClientNoteAddModal] = React.useState(false);
  const [showClaimDialog, setShowClaimDialog] = React.useState(false);
  const [showClientRemarkModal, setShowClientRemarkModal] = React.useState(false);

  const isBoost = client.boostStatus.isBoost;
  const hideBoostDetails = client.boostStatus.isBoost && client.boostStatus.hideBoostDetails;
  const isClaimPending = isBoost && client.boostStatus.isClaimPending;
  const canClaim = isBoost && client.boostStatus.canClaim;
  const canAcceptCommission = isBoost && client.boostStatus.canAcceptCommission;

  const clientPhone = parsePhoneNumber(client.user.phone);

  const { notes, history } = React.useMemo(
    () =>
      fullHistory.reduce(
        (acc: { notes: ClientNoteHistoryRecord[]; history: ClientHistoryRecord[] }, item: ClientHistoryRecord) => {
          if (item.type === ClientNoteHistoryType.value) {
            acc.notes.push(item);
          } else {
            acc.history.push(item);
          }
          return acc;
        },
        {
          notes: [],
          history: [],
        },
      ),
    [fullHistory],
  );

  const toggleShowCommissionModal = () => {
    setShowViewCommissionModal(!showViewCommissionModal);
  };

  const toggleClaimDialog = () => {
    setShowClaimDialog(!showClaimDialog);
  };

  const PROFILE_MENU_ITEMS: MenuItem[] = useMemo(() => {
    return [
      {
        key: MenuItemKey.BOOKINGS,
        value: t('bookings', { bookingsCount: appointments.length }),
        icon: 'Calendar',
      } as MenuItem,
      {
        key: MenuItemKey.SUBSCRIPTIONS,
        value: t('subscriptions', { subscriptionsCount: memberships.length }),
        icon: 'Identity',
      } as MenuItem,
      {
        key: MenuItemKey.PRODUCTS,
        // value: t('products_list', { productsCount: productSales ? productSales.length : 0 }),
        value: t('products'),
        icon: 'Products',
      } as MenuItem,
      { key: MenuItemKey.JOURNAL, value: t('journal'), icon: 'Journal' } as MenuItem,
      { key: MenuItemKey.HISTORY, value: t('history'), icon: 'History' } as MenuItem,
    ];
  }, [appointments.length, memberships.length, productSales.length]);

  const onMenuChange = (menuItemKey: MenuItemKey) => {
    switch (menuItemKey) {
      case MenuItemKey.JOURNAL:
      case MenuItemKey.HISTORY:
        onHistoryReload();
        break;
      case MenuItemKey.PRODUCTS:
        onClientProductsSalesLoad();
        break;
      default:
        break;
    }
    setActiveMenuItem(menuItemKey);
  };

  const getContent = (tab: MenuItemKey) => {
    switch (tab) {
      case MenuItemKey.BOOKINGS: {
        return (
          <ClientBookingsList
            HeaderElement={
              appointments.length > 0 ? <Text style={styles.contentTitle}>{t('bookingsTitle')}</Text> : undefined
            }
            onEndReached={() => loadMoreAppointments(clientId)}
            totals={totalAppointments}
            appointments={appointments}
            boostAppointmentId={isBoost ? client.boostStatus.boostAppointmentId : undefined}
            now={now}
            onItemPress={onOpenClientAppointment}
          />
        );
      }
      case MenuItemKey.JOURNAL: {
        return (
          <ClientNotesList
            notes={notes}
            onAddNote={() => setShowClientNoteAddModal(true)}
            onEditNote={(note) => setShowClientNoteEditModal(note)}
            onDeleteNote={(note) => setShowClientNoteDeleteConfirmModal(note._id)}
          />
        );
      }
      case MenuItemKey.HISTORY: {
        return (
          <ClientHistoryList
            HeaderElement={
              <>
                <Text style={styles.contentTitle}>{t('history')}</Text>
                <Spacer size={16} />
              </>
            }
            history={history}
            now={now.toJSDate()}
          />
        );
      }
      case MenuItemKey.PRODUCTS: {
        return (
          <ClientProductsList
            onEndReached={() => loadMoreProductSales(pageId, clientId)}
            productSales={productSales}
            handleProductPress={handleProductSalePress}
          />
        );
      }
      case MenuItemKey.SUBSCRIPTIONS: {
        return (
          <ClientMembershipsList
            pageId={pageId}
            onMembershipPress={onMembershipPress}
            onEndReached={() => loadMoreMemberships(clientId, pageId)}
            onNewMembership={onNewMembership}
            memberships={memberships}
            clientId={clientId}
          />
        );
      }

      case MenuItemKey.GALLERY: {
        return <Title>Gallery</Title>;
      }

      case MenuItemKey.REVIEWS: {
        return <Title>Reviews</Title>;
      }
    }
  };

  const handleCloseClientOptions = () => {
    floatMenuStartRef.current?.onDismissMenu();
  };

  const handleBlockClient = (isBlocked: boolean) => {
    handleCloseClientOptions();
    if (isBlocked) {
      setShowClientBlockConfirmModal(true);
    } else {
      onChangeIsBlocked(client, false);
    }
  };

  const handleWarnClient = (isWarned: boolean) => {
    handleCloseClientOptions();
    onChangeIsWarned(client, isWarned);
  };

  const handleDeleteClient = () => {
    handleCloseClientOptions();
    setShowClientDeleteConfirmModal(true);
  };

  const handleAddClientRemark = () => {
    handleCloseClientOptions();
    setShowClientRemarkModal(true);
  };

  const handleOnEditClient = () => {
    handleCloseClientOptions();
    onEdit();
  };

  const handleBack = async () => {
    startAnimation();
    await sleep(250);
    onBack();
  };

  const translateX = useRef(new Animated.Value(0)).current;

  const startAnimation = () => {
    Animated.parallel([
      Animated.timing(translateX, {
        toValue: 960,
        duration: 250,
        useNativeDriver: true,
      }),
    ]).start();
  };

  const handleDeleteClientRemark = () => {
    meroApi.clients
      .updateClientRemark({
        clientId,
        remark: '',
        showRemarkOnCalendar: client.showRemarkOnCalendar,
      })
      .then(() => {
        toast.show({
          type: 'success',
          text: 'Observație ștearsă',
        });
      })
      .catch((error) => {
        showError(error);
      })
      .finally(() => {
        reloadClientProfile(client._id);
      });
  };

  return (
    <>
      <Animated.View
        style={[
          styles.container,
          {
            transform: [{ translateX }],
          },
        ]}
      >
        <Column style={styles.infoColumn}>
          <MeroHeader canGoBack onBack={handleBack} />
          <ScrollView>
            <View style={styles.contentContainer}>
              <ClientProfileInfo
                profilePicture={client.user.photo?.medium}
                firstName={client.user.firstname || ''}
                lastName={client.user.lastname || ''}
                fullName={fullName}
                phone={client.user.phone}
                isBlocked={client.isBlocked}
                isWarned={client.isWarned}
                isBoost={isBoost}
                hideBoostDetails={hideBoostDetails}
              />
              <Row style={styles.buttonsWrapper} justifyContent="center" alignItems="center" flex={1}>
                {onNewBooking && (
                  <Button
                    containerStyle={styles.addBookingButton}
                    size="medium"
                    text={t('addBooking')}
                    onPress={onNewBooking}
                  />
                )}
                <ClientOptionsMenu
                  floatMenuStartRef={floatMenuStartRef}
                  isBlocked={client.isBlocked}
                  isWarned={client.isWarned}
                  onBlockClient={handleBlockClient}
                  onWarnClient={handleWarnClient}
                  onDelete={!isBoost ? handleDeleteClient : undefined}
                  onAddRemark={!client.remark ? handleAddClientRemark : undefined}
                  onEdit={!hideBoostDetails ? handleOnEditClient : undefined}
                />
              </Row>
              {hideBoostDetails ? (
                <>
                  <ClientBoostDetails
                    isClaimPending={isClaimPending}
                    canClaim={canClaim}
                    onClaimDialog={toggleClaimDialog}
                    onViewCommission={toggleShowCommissionModal}
                    canAcceptCommission={canAcceptCommission}
                  />
                  <Spacer size={16} />
                </>
              ) : null}
              {client.remark && (
                <ClientRemarkCard
                  floatMenuRef={remarkFloatMenuRef}
                  remark={client.remark}
                  onRemarkDelete={() => {
                    remarkFloatMenuRef.current?.onDismissMenu();
                    handleDeleteClientRemark();
                  }}
                  onRemarkEdit={() => {
                    remarkFloatMenuRef.current?.onDismissMenu();
                    setShowClientRemarkModal(true);
                  }}
                />
              )}

              {reports ? (
                <ClientStats
                  completedBookingsCount={reports.completedBookings}
                  cancelledBookingsCount={reports.cancelledBookings}
                  lastCompletedBookingDate={reports.lastCompletedBookingTs}
                  noShowBookingsCount={reports.noShowBookings}
                  clientTotalCharged={reports.totalCheckoutCharge}
                  clientDebt={reports.debt}
                />
              ) : null}
            </View>

            <ClientDetailsListItem
              label={t('email')}
              value={client.user.email as Email | undefined}
              onCopyPress={client.user.email ? () => onCopyToClipboard(client.user.email as Email) : undefined}
              onSendPress={client.user.email ? () => onSendEmail(client.user.email as Email) : undefined}
            />

            {!hideBoostDetails && (
              <ClientDetailsListItem
                label={t('phone')}
                value={clientPhone.number?.international}
                onCopyPress={() => onCopyToClipboard(clientPhone.number?.international as PhoneNumber)}
                onWhatAppPress={() => onSendWhatsappMessage(clientPhone.number?.input as PhoneNumber)}
              />
            )}
            <ClientDetailsListItem
              label={t('birthday')}
              value={
                client.user.birthday
                  ? formattedBirthday(DateString.toDate(client.user.birthday, DEFAULT_TIMEZONE))
                  : undefined
              }
            />
          </ScrollView>
        </Column>
        <Column flex={1} style={styles.menuColumn}>
          <ListMenu activeItem={activeMenuItem} items={PROFILE_MENU_ITEMS} onItemPress={onMenuChange} />
        </Column>
        <Column flex={1} style={styles.contentColumn}>
          {getContent(activeMenuItem)}
        </Column>
      </Animated.View>

      {showViewCommissionModal && (
        <ClientViewCommissionModal
          client={client}
          onDismiss={toggleShowCommissionModal}
          onConfirm={() => {
            reloadClientProfile(client._id);
            toggleShowCommissionModal();
          }}
        />
      )}

      {showClientDeleteConfirmModal ? (
        <ClientConfirmDeleteModal
          deleteInProgress={false}
          onDismiss={() => {
            setShowClientDeleteConfirmModal(false);
          }}
          onConfirm={() => {
            setShowClientDeleteConfirmModal(false);
            onDelete(client);
          }}
        />
      ) : null}

      {showClientBlockConfirmModal ? (
        <ClientConfirmBlockModal
          phone={client.user.phone}
          onDismiss={() => {
            setShowClientBlockConfirmModal(false);
          }}
          onConfirm={() => {
            setShowClientBlockConfirmModal(false);
            // Client should be blocked
            onChangeIsBlocked(client, true);
          }}
        />
      ) : null}

      {showClientNoteDeleteConfirmModal !== undefined ? (
        <ClientConfirmNoteDeleteModal
          noteId={showClientNoteDeleteConfirmModal}
          onDismiss={() => {
            setShowClientNoteDeleteConfirmModal(undefined);
          }}
          onConfirm={() => {
            setShowClientNoteDeleteConfirmModal(undefined);
            onDeleteNote(showClientNoteDeleteConfirmModal);
          }}
        />
      ) : null}

      {showClientNoteEditModal !== undefined ? (
        <ClientNoteEditModal
          note={showClientNoteEditModal}
          onDismiss={() => {
            setShowClientNoteEditModal(undefined);
          }}
          onSave={(note, newText) => {
            setShowClientNoteEditModal(undefined);
            onUpdateNote(note, newText);
          }}
        />
      ) : null}

      {showClientNoteAddModal ? (
        <ClientNoteAddModal
          onDismiss={() => {
            setShowClientNoteAddModal(false);
          }}
          onAdd={(text) => {
            setShowClientNoteAddModal(false);
            onAddNote(client, text);
          }}
        />
      ) : null}

      {showClaimDialog && (
        <ClaimDialog
          onSuccess={() => reloadClientProfile(client._id)}
          onCancel={toggleClaimDialog}
          userId={client.user._id}
        />
      )}
      {showClientRemarkModal && (
        <ClientRemarkModal
          remark={client.remark}
          onDismiss={() => setShowClientRemarkModal(false)}
          onAdd={(remark) => {
            meroApi.clients
              .updateClientRemark({
                clientId: clientId,
                remark,
                showRemarkOnCalendar: client.showRemarkOnCalendar,
              })
              .then(() => {
                toast.show({
                  type: 'success',
                  text: `Observație ${client.remark ? 'modificată' : 'adăugată'} `,
                });
              })
              .catch((error) => {
                showError(error);
              })
              .finally(() => {
                setShowClientRemarkModal(false);
                reloadClientProfile(client._id);
              });
          }}
        />
      )}
    </>
  );
};

export default ClientDetailsLoadedScreenViewWeb;
