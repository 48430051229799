import { Authorized, AuthorizedProps } from '@/contexts/AuthContext';
import { CalendarContext } from '@/contexts/CalendarContext';
import { CurrentBusiness, CurrentBusinessProps } from '@/contexts/CurrentBusiness';
import {
  AuthorizedStackParamList,
  CalendarTabStackParamList,
  HomeDrawerParamsList,
  HomeTabsParamsList,
  RootStackParamList,
} from '@/types';
import { Column, useAppState } from '@mero/components';
import { pipe } from 'fp-ts/function';
import * as React from 'react';
import { StyleSheet } from 'react-native';

import Calendar from '@/components/Calendar';

import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { CompositeNavigationProp, useIsFocused } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

type CalendarScreenNavigationProp = CompositeNavigationProp<
  StackNavigationProp<CalendarTabStackParamList, 'CalendarScreen'>,
  CompositeNavigationProp<
    BottomTabNavigationProp<HomeTabsParamsList, 'CalendarTab'>,
    CompositeNavigationProp<
      DrawerNavigationProp<HomeDrawerParamsList, 'HomeTabs'>,
      CompositeNavigationProp<
        StackNavigationProp<AuthorizedStackParamList, 'Home'>,
        StackNavigationProp<RootStackParamList, 'Authorized'>
      >
    >
  >
>;

type Props = AuthorizedProps &
  CurrentBusinessProps & {
    readonly navigation: CalendarScreenNavigationProp;
  };

const CalendarScreen: React.FC<Props> = (props: Props) => {
  const { navigation, authorization, page } = props;

  const appState = useAppState();
  const isFocused = useIsFocused();
  const [, { reset: resetCalendarContext, reload }] = CalendarContext.useContext();

  // Initialize CalendarContext
  React.useEffect(() => {
    resetCalendarContext({
      userId: authorization.user._id,
      page: page.details,
      team: page.workers,
    });
  }, [authorization.user._id, page.details, page.workers]);

  // Reload when coming back from background or focusing page
  React.useEffect(() => {
    if (appState === 'active' && isFocused) {
      reload();
    }
  }, [appState, isFocused]);

  return (
    <Column flex={1} style={styles.container}>
      <Calendar authorization={authorization} page={page} navigation={navigation} />
    </Column>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#ffffff',
  },
});

export default pipe(CalendarScreen, CurrentBusiness, Authorized);
