import { isSome } from '@mero/api-sdk';
import { DateTimeUnit, DateObjectUnits, DurationObjectUnits, DurationUnit } from 'luxon';
import spacetime, { Spacetime, I18nOptions } from 'spacetime';

/**
 * A DateTime value, (mostly) compatible with luxon.DateTime
 */
export class CalendarDateTime {
  private constructor(private readonly st: Spacetime) {
    //
  }

  get year() {
    return this.st.year();
  }

  get month() {
    /**
     * Spacetime month is 0-11, Luxon returns 1-12
     */
    return this.st.month() + 1;
  }

  get day() {
    return this.st.date();
  }

  get hour() {
    return this.st.hour();
  }

  get minute() {
    return this.st.minute();
  }

  toISO(): string {
    return this.st.iso();
  }

  plus(duration: Pick<DurationObjectUnits, 'days' | 'hours' | 'minutes' | 'milliseconds'>): CalendarDateTime {
    let st = this.st;

    if (isSome(duration.days)) {
      st = st.add(duration.days, 'days');
    }

    if (isSome(duration.hours)) {
      st = st.add(duration.hours, 'hours');
    }

    if (isSome(duration.minutes)) {
      st = st.add(duration.minutes, 'minutes');
    }

    if (isSome(duration.milliseconds)) {
      st = st.add(duration.milliseconds, 'milliseconds');
    }

    return new CalendarDateTime(st);
  }

  minus(duration: Pick<DurationObjectUnits, 'days' | 'hours' | 'minutes' | 'milliseconds'>): CalendarDateTime {
    let st = this.st;

    if (isSome(duration.days)) {
      st = st.subtract(duration.days, 'days');
    }

    if (isSome(duration.hours)) {
      st = st.subtract(duration.hours, 'hours');
    }

    if (isSome(duration.minutes)) {
      st = st.subtract(duration.minutes, 'minutes');
    }

    if (isSome(duration.milliseconds)) {
      st = st.subtract(duration.milliseconds, 'milliseconds');
    }

    return new CalendarDateTime(st);
  }

  startOf(unit: DateTimeUnit): CalendarDateTime {
    return new CalendarDateTime(this.st.startOf(unit));
  }

  endOf(unit: DateTimeUnit): CalendarDateTime {
    return new CalendarDateTime(this.st.endOf(unit));
  }

  toFormat(fmt: KnownFormats, opts?: { locale?: string }): string {
    const formatter = FORMATTERS[fmt];
    if (!formatter) {
      return this.st.format('iso-short');
    }

    return formatter(this.st);
  }

  valueOf(): number {
    return this.st.epoch;
  }

  toMillis(): number {
    return this.st.epoch;
  }

  toJSDate(): Date {
    return this.st.toNativeDate();
  }

  diff(other: CalendarDateTime, unit: DurationUnit): number {
    // Luxon DateTime value sign is inverse
    return other.st.diff(this.st, unit);
  }

  set(values: Pick<DateObjectUnits, 'hour' | 'minute' | 'second' | 'millisecond'>): CalendarDateTime {
    let st = this.st;

    if (isSome(values.hour)) {
      st = st.hour(values.hour);
    }

    if (isSome(values.minute)) {
      st = st.minute(values.minute);
    }

    if (isSome(values.second)) {
      st = st.second(values.second);
    }

    if (isSome(values.millisecond)) {
      st = st.millisecond(values.millisecond);
    }

    return new CalendarDateTime(st);
  }

  static now(zone: string): CalendarDateTime {
    return new CalendarDateTime(spacetime.now(zone, ST_DEFAULTS).i18n(I18N_OPTIONS));
  }

  static fromJSDate(date: Date, opts?: { zone?: string }): CalendarDateTime {
    return new CalendarDateTime(spacetime(date, opts?.zone, ST_DEFAULTS).i18n(I18N_OPTIONS));
  }

  static fromObject(obj: DateObject, opts: { zone: string; locale?: string }): CalendarDateTime {
    return new CalendarDateTime(
      spacetime(
        {
          year: obj.year,
          month: obj.month - 1,
          // it's "date" in spacetime, and "day" in luxon
          date: obj.day,
          hour: obj.hour ?? 0,
          minute: obj.minute ?? 0,
          second: obj.second ?? 0,
          millisecond: obj.millisecond ?? 0,
        },
        opts.zone,
        ST_DEFAULTS,
      ).i18n(I18N_OPTIONS),
    );
  }

  static fromISO(text: string): CalendarDateTime {
    return new CalendarDateTime(spacetime(text, 'Europe/Bucharest', ST_DEFAULTS).i18n(I18N_OPTIONS));
  }
}

type DateObject = {
  readonly year: number;
  readonly month: number;
  /**
   * Month date! (not day of week, compatible with Luxon)
   */
  readonly day: number;
  readonly hour?: number;
  readonly minute?: number;
  readonly second?: number;
  readonly millisecond?: number;
};

/**
 * Date/Time formats currently used in Mero PRO app
 */
type KnownFormats =
  | 'd LLL HH:mm'
  | 'd LLL'
  | 'dd MMM'
  | 'ccc dd MMM'
  | 'ccc dd MMMM yyyy'
  | 'dd MMMM yyyy'
  | 'H:m'
  | 'HH:mm'
  | 'yyyy-MM-dd'
  | 'ccc dd MMM HH:mm';

const FORMATTERS: { [Key in KnownFormats]: (st: Spacetime) => string } = {
  'd LLL HH:mm': (st) =>
    st.format('date') + ' ' + st.format('month-short') + st.format('hour-24-pad') + ':' + st.format('minute-pad'),
  'd LLL': (st) => st.format('date') + ' ' + st.format('month-short'),
  'dd MMM': (st) => st.format('date-pad') + ' ' + st.format('month-short'),
  'ccc dd MMM': (st) => st.format('day-short') + ' ' + st.format('date-pad') + ' ' + st.format('month-short'),
  'ccc dd MMMM yyyy': (st) =>
    st.format('day-short') + ' ' + st.format('date-pad') + ' ' + st.format('month') + ' ' + st.format('year'),
  'dd MMMM yyyy': (st) => st.format('date-pad') + ' ' + st.format('month') + ' ' + st.format('year'),
  'H:m': (st) => st.format('hour-24') + ':' + (st.format('minute') || '0'),
  'HH:mm': (st) => st.format('hour-24-pad') + ':' + st.format('minute-pad'),
  'yyyy-MM-dd': (st) => st.format('iso-short'),
  'ccc dd MMM HH:mm': (st) =>
    st.format('day-short') +
    ' ' +
    st.format('date-pad') +
    ' ' +
    st.format('month-short') +
    ' ' +
    st.format('hour-24-pad') +
    ':' +
    st.format('minute-pad'),
};

const ST_DEFAULTS = {
  weekStart: 1,
};

const I18N_OPTIONS: I18nOptions = {
  days: {
    short: ['Dum', 'Lun', 'Mar', 'Mie', 'Joi', 'Vin', 'Sâm'],
    long: ['Duminică', 'Luni', 'Marți', 'Miercuri', 'Joi', 'Vineri', 'Sâmbătă'],
  },
  months: {
    short: ['Ian.', 'Feb.', 'Mar.', 'Apr.', 'Mai', 'Iun.', 'Iul.', 'Aug.', 'Sep.', 'Oct.', 'Noi.', 'Dec.'],
    long: [
      'Ianuarie',
      'Februarie',
      'Martie',
      'Aprilie',
      'Mai',
      'Iunie',
      'Iulie',
      'August',
      'Septembrie',
      'Octombrie',
      'Noiembrie',
      'Decembrie',
    ],
  },
};
