import { parsePhoneNumber } from 'awesome-phonenumber';
import * as React from 'react';

import { Column, SmallBody, Spacer, Row, H1, Avatar, HSpacer } from '../../../../../../components/shared';

import ClientBookingLabel from '../ClientBookingLabel';

interface ClientProfileInfoProps {
  profilePicture?: string;
  firstName: string;
  lastName: string;
  fullName: string;
  phone: string;
  isBlocked: boolean;
  isWarned: boolean;
  isBoost: boolean;
  hideBoostDetails: boolean;
}

const ClientProfileInfo: React.FC<ClientProfileInfoProps> = ({
  profilePicture,
  firstName,
  lastName,
  fullName,
  phone,
  isWarned,
  isBlocked,
  isBoost,
  hideBoostDetails,
}) => {
  return (
    <>
      <Column alignItems="center">
        <Avatar source={profilePicture} firstname={firstName} lastname={lastName} size={60} />
        <Spacer size={12} />
        <H1 style={{ textAlign: 'center' }}>{fullName}</H1>
        {!hideBoostDetails && <SmallBody>{parsePhoneNumber(phone).number?.national}</SmallBody>}
      </Column>
      <Column alignItems="center">
        {isBlocked || isWarned || isBoost ? (
          <>
            <Spacer size="8" />
            <Row flexWrap="wrap" style={{ rowGap: 8 }}>
              {isBlocked ? (
                <>
                  <ClientBookingLabel type="clientBlocked" />
                  <HSpacer left={8} />
                </>
              ) : null}
              {isWarned ? (
                <>
                  <ClientBookingLabel type="clientWarned" />
                  <HSpacer left={8} />
                </>
              ) : null}
              {isBoost ? (
                <>
                  <ClientBookingLabel type="clientIsBoost" />
                  <HSpacer left={8} />
                </>
              ) : null}
            </Row>
          </>
        ) : null}
      </Column>
    </>
  );
};

export default ClientProfileInfo;
