import * as React from 'react';
import Svg, { Circle, G, Path, SvgProps } from 'react-native-svg';

const FiltersIcon: React.FC<SvgProps> = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G fill="none" data-name="Group 7752">
      <Path d="M0 0h32v32H0z" data-name="Rectangle 2738" />
      <G stroke={props.color ?? '#080de0'} strokeLinecap="round" strokeWidth={1.5} transform="rotate(90 13.49 17.647)">
        <Path
          strokeLinejoin="round"
          strokeMiterlimit={10}
          d="M5.149 13.793v10.538m6.948-1.685v1.685h0m0-18.387v11.547m6.441-4.149v10.989"
        />
        <Path d="M5.149 8.828A2.349 2.349 0 1 1 2.8 11.177a2.349 2.349 0 0 1 2.349-2.349Z" />
        <Circle
          cx={2.349}
          cy={2.349}
          r={2.349}
          strokeLinejoin="round"
          strokeMiterlimit={10}
          data-name="layer1"
          transform="translate(16.189 8.402)"
        />
        <Circle
          cx={2.349}
          cy={2.349}
          r={2.349}
          strokeLinejoin="round"
          strokeMiterlimit={10}
          data-name="layer1"
          transform="translate(9.494 17.754)"
        />
        <Path strokeLinejoin="round" strokeMiterlimit={10} d="M5.149 6.01v2.392M18.538 6.01v2.371" data-name="layer2" />
      </G>
    </G>
  </Svg>
);

export default React.memo(FiltersIcon);
