import * as React from 'react';
import Svg, { Circle, G, Path, SvgProps } from 'react-native-svg';

const PendingApprovalsMobileIcon: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={props.height} height={props.width} {...props}>
      <G data-name="Group 6793">
        <G data-name="Group 6793">
          <Path fill="none" d="M4 4h24v24H4z" data-name="Rectangle 1517" />
          <G
            fill="none"
            stroke={props.color ?? '#080de0'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.5}
            transform="translate(3 3)"
          >
            <Circle cx={11} cy={11} r={11} transform="translate(2 2)" />
            <Circle cx={1.1} cy={1.1} r={1.1} transform="translate(11.9 11.9)" />
            <Path d="m13.719 13.833 4.414 2.838M13 4.933V11.9" data-name="layer1" />
          </G>
        </G>
        <Path fill="none" d="M0 0h32v32H0z" data-name="Rectangle 2629" />
      </G>
    </Svg>
  );
};

export default React.memo(PendingApprovalsMobileIcon);
