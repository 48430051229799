import { colors } from '@mero/components';
import { StyleSheet } from 'react-native';

import { sizes } from '../../../../../../styles';

const styles = StyleSheet.create({
  card: { padding: 16 },
  itemTitle: { color: colors.COMET, fontSize: 10, fontFamily: 'open-sans-semibold' },
  optionsListContainer: {
    marginTop: 0,
    flexDirection: 'column',
    backgroundColor: colors.WHITE,
    borderTopLeftRadius: sizes[12],
    borderTopRightRadius: sizes[12],
    minHeight: sizes[32],
  },
  modalBorderBottom: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  optionsMenuItem: {
    paddingLeft: sizes[24],
    paddingRight: sizes[24],
  },
});

export default styles;
