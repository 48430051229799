import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  headerWrapper: {
    zIndex: 202,
  },
  headerWrapperWithShadow: {
    shadowColor: '#000000',
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.16,
    shadowRadius: 16,
    elevation: 16,
    zIndex: 202,
  },
  calendar: {
    position: 'absolute',
    zIndex: 202,
    elevation: 20,
    left: 0,
    right: 0,
  },
  selectCalendarDesktop: {
    minWidth: 500,
    marginHorizontal: 'auto',
    alignSelf: 'center',
    borderRadius: 8,
    padding: 8,
    backgroundColor: 'white',
    shadowColor: '#000000',
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.16,
    shadowRadius: 16,
    elevation: 16,
  },
  noProsContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 200,
  },
});
