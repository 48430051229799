import { Icon } from '@mero/components';
import * as React from 'react';
import { Pressable, View } from 'react-native';

import FloatMenu, { Ref as FloatMenuRefu } from '../../../../../../components/FloatMenu';
import { Column, Row, Title, Line, SimpleListItem } from '../../../../../../components/shared';

import { colors } from '../../../../../../styles';
import styles from './styles';

interface ClientNotesOptionsMenuProps {
  readonly onEdit: () => void;
  readonly onDelete: () => void;
  readonly notesFloatMenuRef: React.RefObject<FloatMenuRefu>;
}

const ClientNotesOptionsMenu: React.FC<ClientNotesOptionsMenuProps> = ({ onEdit, onDelete, notesFloatMenuRef }) => {
  return (
    <Row>
      <FloatMenu
        touchableActiveOpacity={1}
        ref={notesFloatMenuRef}
        button={
          <Column style={styles.button}>
            <Icon type="options-dots" />
          </Column>
        }
      >
        <View style={styles.optionsListContainer}>
          <>
            <Pressable style={styles.optionsMenuItem} onPress={onEdit}>
              <SimpleListItem>
                <Title>Modifică notiță</Title>
              </SimpleListItem>
            </Pressable>
            <Line />
          </>
          <>
            <Pressable style={styles.optionsMenuItem} onPress={onDelete}>
              <SimpleListItem>
                <Title style={{ color: colors.RADICAL_RED }}>Șterge notiță</Title>
              </SimpleListItem>
            </Pressable>
            <Line />
          </>
        </View>
      </FloatMenu>
    </Row>
  );
};

export default ClientNotesOptionsMenu;
