import * as React from 'react';
import Svg, { Circle, G, Path, SvgProps } from 'react-native-svg';

const NoProIcon: React.FC<SvgProps> = (props: SvgProps) => (
  <Svg width={62} height={54} {...props}>
    <G data-name="Orion_schedule-window (1)" transform="translate(-1 -5)">
      <Circle cx={16} cy={16} r={16} fill="#a6aac6" transform="translate(30 26)" />
      <Circle cx={16} cy={16} r={16} fill="#a6aac6" data-name="layer4" transform="translate(30 26)" />
      <Path fill="#a8acc7" d="M2 6h52v12H2Z" />
      <Path fill="#fff" d="M46 26a15.9 15.9 0 0 1 7.9 2.1h.1V18H2v32h30.1A16 16 0 0 1 46 26Z" />
      <Path fill="#000064" d="M32.1 49.9a16 16 0 0 1 4.8-21.1A16 16 0 0 0 27.2 50h4.9Z" opacity={0.15} />
      <Circle cx={1} cy={1} r={1} fill="#a7abc7" transform="translate(7 11)" />
      <Circle cx={1} cy={1} r={1} fill="#a7abc7" data-name="layer1" transform="translate(14 11)" />
      <Circle cx={1} cy={1} r={1} fill="#a7abc7" data-name="layer1" transform="translate(21 11)" />
      <Path fill="#000064" d="M2 18h4v32H2ZM2 6h4v12H2Z" data-name="opacity" opacity={0.15} />
      <Circle
        cx={16}
        cy={16}
        r={16}
        fill="none"
        stroke="#3b3f5c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        transform="translate(30 26)"
      />
      <Path
        fill="none"
        stroke="#3b3f5c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M46 34v10h8"
        data-name="stroke"
      />
      <Circle
        cx={1}
        cy={1}
        r={1}
        fill="none"
        stroke="#3b3f5c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        data-name="stroke"
        transform="translate(7 11)"
      />
      <Circle
        cx={1}
        cy={1}
        r={1}
        fill="none"
        stroke="#3b3f5c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        data-name="stroke"
        transform="translate(14 11)"
      />
      <Circle
        cx={1}
        cy={1}
        r={1}
        fill="none"
        stroke="#3b3f5c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        data-name="stroke"
        transform="translate(21 11)"
      />
      <Path
        fill="none"
        stroke="#3b3f5c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M2 6h52v12H2Zm52 22V18M2 18v32h30"
        data-name="stroke"
      />
    </G>
  </Svg>
);

export default NoProIcon;
