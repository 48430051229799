import { HasId, LocalDate } from '@mero/api-sdk';
import { CalendarId } from '@mero/api-sdk/dist/calendar';
import { WorkerId } from '@mero/api-sdk/dist/workers';
import * as React from 'react';
import { ViewStyle } from 'react-native';

import { NormalizedEvent } from '../NormalizedEvent';
import { AddEventIntentParams } from '../index';
import CalendarBody from './CalendarBody';
import CalendarBodySplitView from './CalendarBodySplitView';
import { CalendarWorkerPreview } from './CalendarBodySplitView/calendarWorkerPreview';
import { MIN_HEIGHT } from './styles';
import { ActiveHours, Mode } from './types';
import { getDatesInNextOneDay, getDatesInWeek } from './utils';

interface CalendarProps {
  readonly events: Record<CalendarId, Record<string, NormalizedEvent[] | undefined>>;
  readonly height: number;
  readonly selectedDate: LocalDate;
  readonly currentDate: LocalDate;
  readonly selectedWorkers: CalendarWorkerPreview[];
  readonly selectedTimezone: string;
  readonly locale?: string;
  readonly hideNowIndicator?: boolean;
  readonly mode?: Mode;
  readonly scrollOffsetMinutes?: number;
  readonly showTime?: boolean;
  readonly style?: ViewStyle;
  readonly weekStartsOn?: 0 | 1;
  readonly isRTL?: boolean;
  readonly onScroll?: () => void;
  readonly onPressCell?: (date: Date, worker: HasId<WorkerId> | undefined) => void;
  readonly onPressEvent?: (event: NormalizedEvent) => void;
  readonly activeHours: ActiveHours;
  readonly addEventIntent?: AddEventIntentParams;
  readonly dayHours: number[];
  readonly onAddBooking?: () => void;
  readonly onAddBlockedTime?: () => void;
  readonly onAddCheckout?: () => void;
}

const BigCalendar: React.FC<CalendarProps> = ({
  events,
  height,
  selectedDate,
  currentDate,
  selectedWorkers,
  selectedTimezone,
  hideNowIndicator = false,
  mode = 'week',
  scrollOffsetMinutes = 0,
  showTime = true,
  style = {},
  isRTL = false,
  onScroll,
  onPressCell,
  onPressEvent,
  activeHours,
  addEventIntent,
  dayHours,
  onAddBooking,
  onAddBlockedTime,
  onAddCheckout,
}) => {
  type EventsMap = Record<CalendarId, Record<string, NormalizedEvent[] | undefined>>;

  const daytimeEvents: EventsMap = React.useMemo(
    () =>
      selectedWorkers.reduce((acc1: EventsMap, selectedWorker) => {
        acc1[selectedWorker.calendar._id] = events[selectedWorker.calendar._id] ?? {};
        return acc1;
      }, {}),
    [selectedWorkers, events],
  );

  const startOfWeek = selectedDate.startOf('week');

  const dateRange = React.useMemo((): LocalDate[] => {
    switch (mode) {
      case 'week':
        return getDatesInWeek(startOfWeek);
      case 'day':
        return getDatesInNextOneDay(startOfWeek);
      default:
        throw new Error('undefined mode');
    }
  }, [mode, startOfWeek.toString()]);

  const calculateInterval = React.useMemo(() => (dayHours.at(-1) ?? 24) - (dayHours.at(0) ?? 0), [dayHours]);
  const hourHeight = React.useMemo(() => Math.max(height - 30, MIN_HEIGHT) / calculateInterval, [height]);

  return (
    <>
      {mode === 'day' ? (
        <CalendarBodySplitView
          currentDate={currentDate}
          hourHeight={hourHeight}
          dateRange={dateRange}
          style={style}
          isRTL={isRTL}
          activeHours={activeHours}
          selectedDate={selectedDate}
          selectedWorkers={selectedWorkers}
          selectedTimezone={selectedTimezone}
          containerHeight={height}
          normalizedEvents={daytimeEvents}
          hideNowIndicator={hideNowIndicator}
          scrollOffsetMinutes={scrollOffsetMinutes}
          onScroll={onScroll}
          showTime={showTime}
          onPressCell={onPressCell}
          onPressEvent={onPressEvent}
          addEventIntent={addEventIntent}
          dayHours={dayHours}
          onAddBooking={onAddBooking}
          onAddBlockedTime={onAddBlockedTime}
          onAddCheckout={onAddCheckout}
        />
      ) : (
        <CalendarBody
          currentDate={currentDate}
          hourHeight={hourHeight}
          dateRange={dateRange}
          style={style}
          isRTL={isRTL}
          activeHours={activeHours}
          containerHeight={height}
          normalizedEvents={daytimeEvents}
          hideNowIndicator={hideNowIndicator}
          scrollOffsetMinutes={scrollOffsetMinutes}
          onScroll={onScroll}
          showTime={showTime}
          onPressCell={onPressCell}
          onPressEvent={onPressEvent}
          addEventIntent={addEventIntent}
          dayHours={dayHours}
          onAddBooking={onAddBooking}
          onAddBlockedTime={onAddBlockedTime}
          onAddCheckout={onAddCheckout}
        />
      )}
    </>
  );
};

export default BigCalendar;
