import { CalendarContext } from '@/contexts/CalendarContext';
import { distributeCards, positionCards } from '@/utils/card-board';
import { getWorkIntervals } from '@/utils/time';
import { DateString, HasId, LocalDate } from '@mero/api-sdk';
import { CalendarId } from '@mero/api-sdk/dist/calendar';
import { WorkerId } from '@mero/api-sdk/dist/workers';
import * as React from 'react';
import { LayoutChangeEvent, Platform, ScrollView, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';

import { AddEventIntentParams } from '@/components/Calendar';
import { ActiveHours } from '@/components/Calendar/BigCalendar/types';
import { NormalizedEvent } from '@/components/Calendar/NormalizedEvent';
import { CalendarDateTime } from '@/components/Calendar/calendarDateTime';

import CalendarEventView from '../CalendarEvent/CalendarEventView';
import { getRelativeTopInDay } from '../utils';
import HourCell from './HourCell';
import HourGuide from './HourGuide';

const REFRESH_TIME = 60 * 1000; // 1 min

type Props = {
  readonly hourHeight: number;
  readonly containerHeight: number;
  readonly dateRange: LocalDate[];
  readonly currentDate: LocalDate;
  readonly normalizedEvents: Record<CalendarId, Record<string, NormalizedEvent[] | undefined>>;
  readonly scrollOffsetMinutes: number;
  readonly showTime: boolean;
  readonly style: ViewStyle;
  readonly hideNowIndicator?: boolean;
  readonly isRTL: boolean;
  readonly onScroll?: () => void;
  readonly onPressCell?: (date: Date, worker: HasId<WorkerId> | undefined) => void;
  readonly onPressEvent?: (event: NormalizedEvent) => void;
  readonly activeHours: ActiveHours;
  readonly addEventIntent?: AddEventIntentParams;
  readonly dayHours: number[];
  readonly onAddBooking?: () => void;
  readonly onAddBlockedTime?: () => void;
  readonly onAddCheckout?: () => void;
};

const CalendarBody: React.FC<Props> = (props: Props) => {
  const {
    containerHeight,
    hourHeight,
    dateRange,
    currentDate,
    style = {},
    onPressCell,
    normalizedEvents,
    onPressEvent,
    showTime,
    scrollOffsetMinutes,
    hideNowIndicator,
    isRTL,
    activeHours,
    onScroll = () => null,
    addEventIntent,
    dayHours,
    onAddBooking,
    onAddBlockedTime,
    onAddCheckout,
  } = props;

  const [{ selectedTimezone, selectedCalendars }] = CalendarContext.useContext();
  const scrollView = React.useRef<ScrollView>(null);
  const initialNow = React.useMemo(() => CalendarDateTime.now(selectedTimezone), [selectedTimezone]);
  const [now, setNow] = React.useState(initialNow);

  const [startHour, endHour] = React.useMemo(() => [dayHours.at(0) ?? 0, (dayHours.at(-1) ?? 23) + 1], [dayHours]);

  // For some reason - scrollView reference changes once ... scroll to initial position only once
  const [scrolled, setScrolled] = React.useState(false);
  React.useEffect(() => {
    const current = scrollView.current;
    if (current && !scrolled) {
      setScrolled(true);
      // We add delay here to work correct on React Native
      // see: https://stackoverflow.com/questions/33208477/react-native-android-scrollview-scrollto-not-working
      setTimeout(
        () => {
          try {
            current.scrollTo({
              y: scrollOffsetMinutes
                ? (hourHeight * scrollOffsetMinutes) / 60
                : hourHeight * (now.hour - startHour - 1),
              animated: false,
            });
          } catch (e) {
            // Some sht happens sometimes (fails with Cannot read properties of null (reading 'scroll'))
          }
        },
        Platform.OS === 'web' ? 0 : 10,
      );
    }
  }, [scrollView.current, startHour]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setNow(CalendarDateTime.fromJSDate(new Date(), { zone: selectedTimezone }));
    }, REFRESH_TIME);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const _onPressCell = React.useCallback(
    (date: CalendarDateTime) => {
      onPressCell?.(date.toJSDate(), undefined);
    },
    [onPressCell],
  );

  const [boardColumns, setBoardColumns] = React.useState(1);
  const scrollviewLayoutHandler = React.useCallback(
    (event: LayoutChangeEvent) => {
      const boardColumns = Math.max(
        Math.floor(event.nativeEvent.layout.width / dateRange.length / Math.max(selectedCalendars.length, 1) / 230),
        1,
      );
      setBoardColumns(boardColumns);
    },
    [setBoardColumns, selectedCalendars.length],
  );

  const fullCalendarSize = hourHeight * 24;
  const calendarSize = hourHeight * (endHour - startHour);

  return (
    <ScrollView
      style={[
        {
          height: containerHeight - hourHeight * 3,
        },
        style,
      ]}
      ref={scrollView}
      onScroll={onScroll}
      scrollEventThrottle={32}
      showsVerticalScrollIndicator={false}
      onLayout={scrollviewLayoutHandler}
    >
      <View style={isRTL ? [styles.bodyRTL] : [styles.body]}>
        <HourGuide dayHours={dayHours} hourHeight={hourHeight} />
        {dateRange.map((date: LocalDate, index) => {
          const d = CalendarDateTime.fromObject(
            {
              year: date.year,
              month: date.month,
              day: date.day,
            },
            {
              zone: selectedTimezone,
            },
          );
          const day = DateString.fromLocalDate(date);

          const startEpoch =
            startHour === 24
              ? d.plus({ days: 1 }).startOf('day').toMillis()
              : d.set({ hour: startHour, minute: 0, second: 0, millisecond: 0 }).toMillis();
          const endEpoch =
            endHour === 24
              ? d.plus({ days: 1 }).startOf('day').toMillis()
              : d.set({ hour: endHour, minute: 0, second: 0, millisecond: 0 }).toMillis();

          return (
            <View
              key={`${date.year}-${date.month}-${date.day}_${index}`}
              nativeID="calendar-body"
              style={[{ flex: 1 }, ...(index !== dateRange.length - 1 ? [styles.withBorder] : [])]}
            >
              {selectedCalendars.map((selectedCalendar) => {
                const dayEvents = normalizedEvents[selectedCalendar]
                  ? normalizedEvents[selectedCalendar][date.toString()] || []
                  : [];
                const calendarActiveHours = activeHours[selectedCalendar];
                const dayActiveHours = getWorkIntervals(calendarActiveHours, day);
                const isActive = addEventIntent?.worker?.calendar._id === selectedCalendar;
                const selectedTime = addEventIntent?.start.toFormat('H:m') ?? '';

                return (
                  <View
                    style={[styles.dayContainer, { width: `${100 / selectedCalendars.length}%` }]}
                    key={`${day}_${selectedCalendar}`}
                  >
                    {dayHours.map((hour) => (
                      <HourCell
                        activeHours={dayActiveHours}
                        key={hour}
                        cellHeight={hourHeight}
                        date={date}
                        hour={hour}
                        selectedTimezone={selectedTimezone}
                        onPress={_onPressCell}
                        isActive={isActive}
                        selectedTime={selectedTime}
                        onAddBooking={onAddBooking}
                        onAddBlockedTime={onAddBlockedTime}
                        onAddCheckout={onAddCheckout}
                      />
                    ))}

                    {positionCards(
                      distributeCards(
                        dayEvents.map(
                          (event) =>
                            ({
                              start: event.start.toMillis(),
                              end: event.end.toMillis(),
                              event: event,
                            } as const),
                        ),
                        {
                          minCardSize: 15 * 60 * 1000, // 15 minutes
                        },
                      ),
                      {
                        columns: boardColumns,
                        start: startEpoch,
                        end: endEpoch,
                        minCardSize: 15 * 60 * 1000, // 15 minutes
                        shrinkCards: true,
                      },
                    ).map((row) => {
                      const event = row.card.event;

                      return (
                        <TouchableOpacity
                          key={`custom-event-${event.start}${event.title}${event.extra.id}`}
                          delayPressIn={20}
                          onPress={() => {
                            if (onPressEvent) {
                              onPressEvent(event);
                            }
                          }}
                          style={{
                            position: 'absolute',
                            left: `${row.x * 100}%`,
                            top: Math.floor(calendarSize * row.y),
                            width: `${row.width * 95}%`,
                            height: Math.floor(calendarSize * row.height),
                            overflow: 'hidden',
                            paddingBottom: 1,
                          }}
                        >
                          <CalendarEventView
                            event={event}
                            showTime={showTime}
                            now={CalendarDateTime.now(selectedTimezone)}
                          />
                        </TouchableOpacity>
                      );
                    })}

                    {currentDate.equals(date) && !hideNowIndicator && (
                      <View
                        style={[
                          styles.nowIndicator,
                          { top: fullCalendarSize * (getRelativeTopInDay(now.minus({ hours: startHour })) / 100) },
                        ]}
                      >
                        <View style={styles.nowIndicatorDot} />
                        <View style={styles.nowIndicatorLine} />
                      </View>
                    )}
                  </View>
                );
              })}
            </View>
          );
        })}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  body: {
    flexDirection: 'row',
    flex: 1,
  },
  bodyRTL: {
    flexDirection: 'row-reverse',
    flex: 1,
  },
  withBorder: {
    borderRightWidth: 1,
    borderColor: '#ADADAD',
  },
  nowIndicator: {
    flexDirection: 'row',
    position: 'absolute',
    zIndex: 1000,
    width: '100%',
    alignItems: 'center',
  },
  dayContainer: {
    flex: 1,
    overflow: 'hidden',
  },
  weekDay: {
    borderRightWidth: 1,
    borderColor: '#ADADAD',
  },
  nowIndicatorDot: {
    borderRadius: 2,
    width: 4,
    height: 4,
    backgroundColor: '#080DE0',
  },
  nowIndicatorLine: {
    flex: 1,
    backgroundColor: '#080DE0',
    height: 1,
  },
});

export default React.memo(CalendarBody);
