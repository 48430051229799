import { ClientNoteHistoryRecord } from '@mero/api-sdk/dist/clients';
import { Body, Icon, Title } from '@mero/components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { View } from 'react-native';

import { Ref as FloatMenuRefu } from '../../../../../../components/FloatMenu';
import { FormCard, Row } from '../../../../../../components/shared';

import ClientNotesOptionsMenu from '../ClientNotesOptionsMenu';
import styles from './styles';

interface ClientNoteCardProps {
  readonly note: ClientNoteHistoryRecord;
  readonly onEdit: (note: ClientNoteHistoryRecord) => void;
  readonly onDelete: (note: ClientNoteHistoryRecord) => void;
  readonly notesFloatMenuRef: React.RefObject<FloatMenuRefu>;
}

const ClientNoteCard: React.FC<ClientNoteCardProps> = ({ note, onEdit, onDelete, notesFloatMenuRef }) => {
  const date = React.useMemo(() => DateTime.fromJSDate(note.date), [note.date]);
  const dateStr = React.useMemo(() => date.toFormat('d LLL yyyy', { locale: 'ro' }), [date]);

  return (
    <FormCard dropShaddow style={styles.card} borderRadius={6}>
      <Row style={styles.container}>
        <View style={styles.iconContainer}>
          <Icon type="client-history-note" size={32} />
        </View>
        <View style={styles.dateWrapper}>
          <Title>{dateStr}</Title>
        </View>
        <ClientNotesOptionsMenu
          onEdit={() => onEdit(note)}
          onDelete={() => onDelete(note)}
          notesFloatMenuRef={notesFloatMenuRef}
        />
      </Row>
      <Row style={styles.textWrapper}>
        <Body>{note.payload.text}</Body>
      </Row>
    </FormCard>
  );
};

export default ClientNoteCard;
